import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from "@mui/material";
import React, { FC, HTMLInputTypeAttribute, useState } from "react";
import { EditorDialogConfirmResult } from "../../core/common_types";
import { EditableMeasureProps } from "../../routes/configurations/door/builder/components/object-draw/editable_measure";

export const MeasureEditorDialog: FC<{data: EditableMeasureProps, onConfirm: (value: number) => EditorDialogConfirmResult, onCancel: () => void}> = (props) => {
    const [value, setValue] = useState<number>(props.data.value);
    const [error, setError] = useState<string | null>(null);

    const onConfirm = () => {
        let res = props.onConfirm(value);
        if (!res.isValid) setError(res.message ? res.message : "Valore non valido");
    };

    return (
        <Dialog onClose={props.onCancel} open={true}>
            <DialogTitle>{props.data.editTitle}</DialogTitle>
            <DialogContent>
                <TextField 
                    error={error != null}
                    variant="standard" 
                    type={"number"} 
                    value={value} 
                    InputProps={{
                        startAdornment: props.data.prefix && <InputAdornment position="start">{props.data.prefix} = </InputAdornment>, 
                        endAdornment: props.data.um && <InputAdornment position="start">{props.data.um}</InputAdornment>,
                    }}
                    onChange={e => setValue(Number(e.target.value))} 
                    helperText={error ?? null}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onCancel}>Annulla</Button>
                <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}