import React, { FC, useEffect, useState } from "react";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser } from "../../../../core/common_functions";
import { useAccessoriesService } from "../../../../services/accessories_service";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { Accessorio, AccessorioInConfigurazione } from "../../../../models/door_configuration";
import { Alert, Button, Grid, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CancelIcon from "@mui/icons-material/Cancel";
import Loading from "../../../../../components/Loading/loading";
import { useNavigate, useParams } from "react-router-dom";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { AccessorioViewModel } from "../../../../core/common_types";
import { CardAccessorio } from "./components/card_accessorio";

export const OtherAccessoriesPage = () => {
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const navigate = useNavigate();
    const { id } = useParams();
    const dialogs = useDialogs();
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete } = useDoorConfigurationBuilderActions();
    const [accessories, setAccessories] = useState<{ isLoading: boolean, items: AccessorioViewModel[], selected: AccessorioInConfigurazione[] }>({ isLoading: true, items: [], selected: [] });
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const accessoriesService = useAccessoriesService();
    const configurationService = useConfigurationsService();
    
    const loadAccessories = async () => {
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid_configuration";

            let result = await accessoriesService.findGenericAccessoriesByCategory(currentUser, doorConfigurationBuilder.configuration?.categoryId);
            let selectedAccessories = await configurationService.getAccessoriGenerici(currentUser, doorConfigurationBuilder.configuration.configurationId);

            let items: AccessorioViewModel[] = [];
            result.forEach(a => {
                let selected = selectedAccessories.find(s => s.accessoryId == a?.id);
                items.push({data: a, qty: selected ? selected.qty : 1});
            });

            setAccessories({ isLoading: false, items, selected: selectedAccessories });
        } catch (e: any) {
            setAccessories({ ...accessories, isLoading: false });

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const addAccessorio = async (idAccessorio: number, qty: number) => {
        setSubmit(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            await configurationService.addAccessorio(currentUser, doorConfigurationBuilder.configuration?.configurationId, idAccessorio, qty);

            setSubmit(false);

            let selected = accessories.selected;
            selected.push({accessoryId: idAccessorio, qty});
            setAccessories({...accessories, selected});
        } catch (e: any) {
            setSubmit(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const removeAccessorio = async (idAccessorio: number) => {
        setSubmit(true);
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            await configurationService.removeAccessorio(currentUser, doorConfigurationBuilder.configuration?.configurationId, idAccessorio);

            setSubmit(false);

            let selected = accessories.selected;
            selected = selected.filter(a => a.accessoryId != idAccessorio);
            setAccessories({...accessories, selected});
        } catch (e: any) {
            setSubmit(false);

            if (typeof e == "string") {
                dialogs?.showError("Attenzione...", e);
            } else {
                if (e.severity == "error") {
                    dialogs?.showError("Attenzione...", e.message);
                } else if (e.severity == "warning") {
                    dialogs?.showWarning("Attenzione...", e.message);
                }
            }
        }
    }

    const setQuantity = (accessorio: AccessorioViewModel, qty: number) => {
        let items = accessories.items;
        let item = items.find(i => i?.data?.id == accessorio?.data?.id);
        if (item) {
            item.qty = qty;
            setAccessories({...accessories, items});
        }
    }

    const submit = async () => {
        dispatcher(setStepComplete({ stepSlug: "accessori-vari", isComplete: true }));

        navigate(`/door-configurations/${id}/generazione-preventivo`);
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "accessori-vari", isActive: true }));

            loadAccessories();
        }
    }, []);

    return (
        <div className="container h-full">
            <div className="row m-0 h-full">
                <div className="col-xl-8 p-0" style={{ overflowY: "auto", height: "100%" }}>
                    <div className="p-4">
                        <h5 className="text-primary">Accessori vari</h5>

                        {accessories.isLoading && <Loading />}

                        <div className="row accessories-grid my-0">
                            {!accessories.isLoading && accessories.items.filter(a => !accessories.selected.find(sa => sa.accessoryId == a?.data?.id)).map(a => (
                                <div key={a?.data?.id} className={"col-12 p-2 accessories-grid-item"} style={{ borderBottom: "solid 1px #f1f1f1" }}>
                                    {a?.data?.img && <img src={a?.data?.img ?? ""} style={{ width: "4rem", height: "4rem" }} />}
                                    <div className="p-2">
                                        <h6 className="text-secondary">{a?.data?.name}</h6>
                                        <div className="d-flex align-items-center justify-content-between">     
                                            <span className="badge bg-light text-dark">&euro; {a?.data?.price}</span>                                       

                                            <div>
                                                <div className="input-group input-group-sm">
                                                    {a?.data?.qtyMax && a.data?.qtyMax > 1 && (
                                                        <>
                                                            <span className="input-group-text" style={{fontSize: ".75rem"}}>Q.tà</span>
                                                            <select value={a.qty} onChange={(e) => setQuantity(a, Number(e.target.value))} className="form-select form-select-sm" style={{maxWidth: "75px"}}>
                                                                {Array.from({length: a?.data?.qtyMax}, (_, i) => <option key={(i+1)} value={(i+1)}>{(i+1)}</option>)}
                                                            </select>
                                                        </>
                                                    )}
                                                    <button className="btn btn-primary btn-sm" onClick={() => addAccessorio(a?.data?.id as number, a?.qty as number)}>AGGIUNGI</button>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {!accessories.isLoading && accessories.items.length <= 0 && (
                            <Alert severity="warning">Nessun accessorio disponibile</Alert>
                        )}
                    </div>
                </div>

                <div className="col-xl-4 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>
                    <div className="p-4">
                        <h5 className="text-primary">Accessori selezionati</h5>

                        {accessories.selected.map(sa => <CardAccessorio accessorio={accessories.items.find(a => a?.data?.id == sa.accessoryId)} quantity={sa.qty} onRemove={(idAccessorio) => removeAccessorio(idAccessorio)} />)}

                        {!accessories.isLoading && <div className="d-flex flex-row-reverse justify-content-between mt-3">
                            <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                                <span>Avanti</span>
                                <NavigateNextIcon />
                            </Button>

                            <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                                if (doorConfigurationBuilder.configuration?.isMotorizzata) {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/accessori-motorizzazione`);
                                } else {
                                    navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
                                }
                            }}>
                                <NavigateBeforeIcon />
                                <span>Indietro</span>
                            </Button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}