import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  CircularProgress,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import Loading from "../../components/Loading/loading";
import { InputCustom } from "../../components/Input/InputCustom";
import { useFormik } from "formik";
import * as yup from "yup";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import oltreLuce from "../../assets/img/portinaAffiancata/fuori_vano.png";
import inLuce from "../../assets/img/portinaAffiancata/tra_vano.png";

import tirare from "../../assets/img/portinaAffiancata/porta_apertura_esterno_destra.png";
import spingere from "../../assets/img/portinaAffiancata/porta_apertura_interno_destra.png";

import sinistraEsterno from "../../assets/img/portinaAffiancata/porta_apertura_esterno_sinistra.png";
import destraEsterno from "../../assets/img/portinaAffiancata/porta_apertura_esterno_destra.png";

import destraInterno from "../../assets/img/portinaAffiancata/porta_apertura_interno_destra.png";
import sinistraInterno from "../../assets/img/portinaAffiancata/porta_apertura_interno_sinistra.png";
import { importBaseActions } from "../../store/reducers/base";
import { useCallback } from "react";
import { OptionsTypeEnum } from "../../core/OptionsTypeEnum";
import { getProductName } from "../../Utility";

const validationSchema = yup.object({
  altezza: yup.string().required("Altezza obbligatoria"),
  larghezza: yup.string().required("Larghezza obbligatoria"),
  installazione: yup.string().required("Installazione obbligatoria"),
  apertura: yup.string().required("Apertura obbligatoria"),
  verso: yup.string().required("Verso obbligatorio"),
});

const PortinaPedonaleAffiancata = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: "loading",
    steps: [],
    currentStep: null,
    porticina: null,
    affiancata: null,
    radio1: null,
    radio2: null,
    radio3: null,
    ral_custom_ACCP60: "",
    ral_custom_ACCP61: "",
    error: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [errorVincolo, setErrorVincolo] = useState(null);
  //const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  // const [affiancata, setAffiancata] = useState(false);
  const setAffiancata = val => {
    setState({
      ...state, 
      affiancata: val,
      radio1: null,
      radio2: null,
      radio3: null,
    });
  }

  // const [radio1, setRadio1] = useState("in_luce");
  const setTypeInstallation = e => {
    setState({...state, radio1: e.target.value});
  }

  // const [radio2, setRadio2] = useState("spingere");
  const setTypeOpen = e => {
    setState({...state, radio2: e.target.value});
  }

  // const [radio3, setRadio3] = useState("sinistra");
  const setOpeningDirection = e => {
    setState({...state, radio3: e.target.value});
  }

  const load = useCallback(async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);      
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }      
    } catch (e) {
      
    }

    try {
      let porticina = null;

      let res = await axios.post(`${process.env.REACT_APP_API_URL}/get-porticina`, {quote_id: count.quoteId}, axiosConfig);
      if (res.data && res.data.data) {
        porticina = res.data.data;
      }
      
      //formik.setFieldValue("ral_custom_ACCP60", porticina.ralCustomACCP60);
      //formik.setFieldValue("ral_custom_ACCP61", porticina.ralCustomACCP61);

      setState({
        ...state, 
        steps,
        currentStep,
        porticina,
        affiancata: porticina != null,
        radio1: porticina != null ? porticina.type_installation : "",
        radio2: porticina != null ? porticina.type_open : "",
        radio3: porticina != null ? porticina.opening_direction : "",        
        ral_custom_ACCP60: porticina != null ? porticina.ralCustomACCP60 : "",
        ral_custom_ACCP61: porticina != null ? porticina.ralCustomACCP61 : "",
        status: "idle"
      });
    } catch (e) {
      setState({...state, status: "error", error: e});
    }
  });

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  const navigateToPreviuosStep = () => {
    let previousStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let minStepId = Math.min(...stepsIds);

    for (let stepId = state.currentStep.position - 1; stepId >= minStepId; stepId--) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      previousStep = step;
      if (previousStep) break;
    }    
    if (!previousStep) return;

    navigate(`/${previousStep.slug}`, { 
      id: state.idPorta,
    });
  }

  useEffect(() => {
    load();
  }, []);
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/save-accessories`,
    //     {
    //       qty: 1,
    //       id: 287,
    //       quote: count.quoteId,
    //       slug: location.pathname.substring(1),
    //     },
    //     axiosConfig
    //   )
    //   .then((res) => console.log("AAAA", res.data))
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/get-id-accessories`,
    //     {
    //       quote: count.quoteId,
    //       slug: location.pathname.substring(1),
    //       type: "radio",
    //     },
    //     axiosConfig
    //   )
    //   .then((res) => {
    //     if (res.data.data.accessories_id) {
    //       setAffiancata(res.data.data.accessories_id);
    //       dispatch(baseActions.setIsActiveAffiancata(true));
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // return handleChange();
  // }, []);

  // const fetcher3 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         quote_id: count.quoteId,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       setRadio1(res.data.data.type_installation);
  //       setRadio2(res.data.data.type_open);
  //       setRadio3(res.data.data.opening_direction);
  //       return res.data.data;
  //     });
  // const { data: porticina, error: errorPorticina } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/get-porticina`, location.pathname],
  //   fetcher3
  // );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      altezza: 0,//porticina ? porticina.heigth : "",
      larghezza: 0,//porticina ? porticina.width : "",
      ral_custom_ACCP60: "",
      ral_custom_ACCP61: ""
    },
    onSubmit: async (data) => {
      //setLoading(true);
      try {
        // let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, { 
        //   qty: 1,
        //   id: 287,
        //   slug: location.pathname.substring(1),
        //   quote: count.quoteId,
        //   is_active: state.affiancata ? 1 : 0
        // }, axiosConfig );
               
        // if (res.data.success != 1) {
        //   setErrorVincolo(res.data.data.error);
        //   return;
        // }
          
        // dispatch(baseActions.setRadioA(state.affiancata));        

        let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-porticina`, { 
          quote_id: count.quoteId,
          type_installation: state.radio1,
          heigth: count.altezzaPortina,
          width: count.larghezzaPortina,
          type_open: state.radio2,
          verso: state.radio3,
          is_active: 1,
          ralCustomACCP60: data.ral_custom_ACCP60,
          ralCustomACCP61: data.ral_custom_ACCP61,
        }, axiosConfig);
        
        if (res.data.error === 1) {
          setErrorVincolo(res.data.message);
        } else if (res.data.success === 1) {
          dispatch(baseActions.setRadioA(true));
          dispatch(baseActions.setPortinaPedonaleAffiancata(true));
          navigate("/accessori");
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const fetcher2 = (url) =>
    axios
      .post(
        url,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data);
  const { data: accessories, error: errorAccessories } = useSWR(
    [`${process.env.REACT_APP_API_URL}/accessories`, location.pathname],
    fetcher2
  );

  if ("error" == state.status) return <div>failed to load</div>;
  if ("loading" == state.status) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
          </Typography>
        </Grid>

        <div>
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
        
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {state.currentStep && <TabsCustom
            value={state.currentStep.id}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
        <GridPreventivi
          header={null
            // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
            //   <div className="col-span-4 text-center">
            //     <Typography variant={"h4"} fontWeight={700} mt={5}>
            //       Portina Pedonale Affiancata
            //     </Typography>
            //   </div>
            // </div>
          }
          dataSend={{
            quote_id: count.quoteId,
            category_id: count.idPorta,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={open}
          porticina={state.porticina}>
          <div className={"grid grid-cols-4 xl:mt-6 mt-10"}>
            <div className="col-start-2 col-span-2">
              <form
                className={"justify-center w-full"}
                onSubmit={formik.handleSubmit}>
                {/* <div className={"flex flex-col mb-4"}>
                  <Typography
                    color={"primary"}
                    sx={{ lineHeight: 0, marginBottom: 2 }}>
                    Aggiungere porticina pedonale affiancata?
                  </Typography>
                  <Switch
                    checked={state.affiancata}
                    //defaultChecked={count.isActiveAffiancata}
                    onChange={(e) => setAffiancata(e)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div> */}
                  <FormControl sx={{ marginTop: 2 }}>
                    <Typography
                      color={"primary"}
                      sx={{ lineHeight: 2, marginBottom: 2 }}>
                      Tipologia di installazione*
                    </Typography>
                    <RadioGroup
                      name="radio1"
                      value={state.radio1}
                      onChange={e => setTypeInstallation(e)}>
                      <div className={"flex flex-row items-center"}>
                        <div className={"flex flex-col items-center"}>
                          <img
                            className={"w-1/2 mb-2"}
                            src={inLuce}
                            alt={"inLuce"}
                          />
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            value="in_luce"
                            control={<Radio />}
                            label="In luce"
                          />
                        </div>
                        <div className={"flex flex-col  items-center"}>
                          <img
                            className={"w-1/2 mb-2"}
                            src={oltreLuce}
                            alt={"oltreLuce"}
                          />
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            value="oltre_luce"
                            control={<Radio />}
                            label="Oltre luce"
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>

                  <FormControl sx={{ marginTop: 2 }}>
                    <Typography
                      color={"primary"}
                      sx={{ lineHeight: 2, marginBottom: 2 }}>
                      Tipologia di apertura*
                    </Typography>
                    <RadioGroup
                      name="radio2"
                      value={state.radio2}
                      onChange={e => setTypeOpen(e)}>
                      <div className={"flex flex-row items-center"}>
                        <div className={"flex flex-col items-center"}>
                          <img
                            className={"w-1/2 mb-2"}
                            src={spingere}
                            alt={"spingere"}
                          />
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            value="spingere"
                            control={<Radio />}
                            label="Spingere"
                          />
                        </div>
                        {state.radio1 === "in_luce" && (
                          <div className={"flex flex-col  items-center"}>
                            <img
                              className={"w-1/2 mb-2"}
                              src={tirare}
                              alt={"tirare"}
                            />
                            <FormControlLabel
                              sx={{ marginRight: 0 }}
                              value="tirare"
                              control={<Radio />}
                              label="Tirare"
                            />
                          </div>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>

                  <FormControl sx={{ marginTop: 4 }}>
                    <Typography
                      color={"primary"}
                      sx={{ lineHeight: 2, marginBottom: 2 }}>
                      Verso di apertura*
                    </Typography>
                    <RadioGroup
                      name="radio3"
                      value={state.radio3}
                      onChange={e => setOpeningDirection(e)}>
                      <div className={"flex flex-row items-center"}>
                        <div className={"flex flex-col items-center"}>
                          <img
                            className={"w-1/2 mb-2"}
                            src={
                              state.radio2 !== "spingere"
                                ? sinistraEsterno
                                : sinistraInterno
                            }
                            alt={"sinistra"}
                          />
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            value="sinistra"
                            control={<Radio />}
                            label="Sinistra"
                          />
                        </div>
                        <div className={"flex flex-col  items-center"}>
                          <img
                            className={"w-1/2 mb-2"}
                            src={
                              state.radio2 !== "spingere"
                                ? destraEsterno
                                : destraInterno
                            }
                            alt={"destra"}
                          />
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            value="destra"
                            control={<Radio />}
                            label="Destra"
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>

                  <div className={"flex flex-row gap-6 mt-12"}>
                    <div className={"flex flex-col"}>
                      <Typography
                        color={"primary"}
                        sx={{ lineHeight: 0, marginTop: 2 }}>
                        Larghezza (mm)*
                      </Typography>
                      <InputCustom
                        placeholder={"Inserisci larghezza..."}
                        name={"larghezza"}
                        formik={formik.values}
                        error={
                          formik.touched.larghezza &&
                          Boolean(formik.errors.larghezza)
                        }
                        value={
                          count.larghezzaPortina !== ""
                            ? count.larghezzaPortina
                            : formik.values.larghezza
                        }
                        helperText={
                          formik.touched.larghezza && formik.errors.larghezza
                        }
                        onChange={(e) => {
                          dispatch(baseActions.setLarghezzaPortina(e.target.value));
                          formik.setFieldValue("larghezza", e.target.value);
                        }}
                      />
                    </div>
                    <div className={"flex flex-col"}>
                      <Typography
                        color={"primary"}
                        sx={{ lineHeight: 0, marginTop: 2 }}>
                        Altezza (mm)*
                      </Typography>
                      <InputCustom
                        name={"altezza"}
                        placeholder={"Inserisci altezza..."}
                        formik={formik.values}
                        error={
                          formik.touched.altezza && Boolean(formik.errors.altezza)
                        }
                        value={
                          count.altezzaPortina !== ""
                            ? count.altezzaPortina
                            : formik.values.altezza
                        }
                        helperText={
                          formik.touched.altezza && formik.errors.altezza
                        }
                        onChange={(e) => {
                          dispatch(baseActions.setAltezzaPortina(e.target.value));
                          formik.setFieldValue("altezza", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <FormGroup sx={{ mt: 6, mb: 4 }}>
                    {accessories.map((res) => {
                      if (res.sku == "ACCP60") {
                        return (
                          <div className="row mb-3">
                            <div className="col-6">
                              <span className="text-primary">Verniciatura maniglia lato esterno:</span><br/>
                              <strong>NERO</strong>
                            </div>
                            <div className="col-2 text-center">
                              <strong>oppure</strong>
                            </div>
                            <div className="col-4">
                              <span className="text-primary">RAL custom</span><br />
                              <input 
                                type="text" 
                                className="form-control" 
                                name="ral_custom_ACCP60" 
                                value={formik.values.ral_custom_ACCP60}
                                onChange={(e) => {
                                  //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                  formik.setFieldValue("ral_custom_ACCP60", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      
                      if (res.sku == "ACCP61") {
                        return (
                          <div className="row mb-3">
                            <div className="col-6">
                              <span className="text-primary">Verniciatura maniglia lato interno:</span><br/>
                              <strong>NERO</strong>
                            </div>
                            <div className="col-2 text-center">
                              <strong>oppure</strong>
                            </div>
                            <div className="col-4">
                              <span className="text-primary">RAL custom</span><br />
                              <input 
                                type="text" 
                                className="form-control" 
                                name="ral_custom_ACCP61" 
                                value={formik.values.ral_custom_ACCP61}
                                onChange={(e) => {
                                  //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                  formik.setFieldValue("ral_custom_ACCP61", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div key={res.id}>
                          {res.type === "checkbox" && (
                            <CheckboxCustom
                              selected={res.selected}
                              value={res.sku}
                              name={res.sku}
                              label={res.name}
                              axiosConfig={axiosConfig}
                              idAcc={res.id}
                              quote={count.quoteId}
                            />
                          )}
                        </div>
                      );
                    })}
                  </FormGroup>
                {errorVincolo && <Alert severity="error">{errorVincolo}</Alert>}

                <div className={"mt-12 grid grid-cols-2 pb-56 h-10 gap-4"}>
                  <Button
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      py: 1,
                    }}
                    onClick={() => navigateToPreviuosStep()}>
                    INDIETRO
                  </Button>
                  <Button
                    //onClick={() => setLoading(true)}
                    fullWidth
                    sx={{ color: "white", fontWeight: "bold", py: 1 }}
                    variant={"contained"}
                    type={"submit"}>
                    AVANTI
                    {/* {loading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "AVANTI"
                    )} */}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </GridPreventivi>
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default PortinaPedonaleAffiancata;
