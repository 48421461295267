import { useOptionsApi } from "../api/options_api";
import { Option } from "../models/door_configuration";
import { User } from "../models/user";

export const useOptionsService = () => {
    const optionsApi = useOptionsApi();

    return {
        async findByCategory(currentUser: User, categoryId: number): Promise<Option[]> {
            let result = await optionsApi.findByCategory(currentUser, categoryId);
            
            if (!result.success) {
                throw result.message;
            }

            if (!result.data) throw "Configuration is null";

            return result.data;
        }
    }
}