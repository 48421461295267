import React, { FC, useEffect, useState } from "react";
import { Arrow, Group, Label, Line, Tag, Text } from "react-konva";
import { DEFAULT_METRIC_SIZE, PRIMARY_COLOR } from "../../../../../../core/common_constants";
import { Vector2d } from "konva/lib/types";
import { distance, midpoint } from "../../../../../../core/common_functions";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { EditorDialogConfirmResult, LengthMeasurementUnits } from "../../../../../../core/common_types";

export type EditableMeasureProps = {
    ptFrom: Vector2d,
    ptTo: Vector2d,
    prefix: string,
    label: string,
    labelPosition: Vector2d,
    value: number,
    um: LengthMeasurementUnits,
    editTitle: string,
    onConfirm: (value: number) => EditorDialogConfirmResult,
}

export const EditableMeasure: FC<EditableMeasureProps> = (props) => {
    const dialogs = useDialogs();

    return (
        <Group>
            <Arrow
                points={[props.ptFrom.x, props.ptFrom.y, props.ptTo.x, props.ptTo.y]}
                stroke={PRIMARY_COLOR}
                fill={PRIMARY_COLOR}
                pointerAtBeginning
                pointerWidth={4}
                pointerLength={4}
            />
            <Label x={props.labelPosition.x} y={props.labelPosition.y} rotation={0}>
                <Tag fill="white" stroke="black" />
                <Text text={props.label} padding={4} onTap={(e => {
                    dialogs?.editMeasure(props);
                })} onDblClick={(e) => {
                    dialogs?.editMeasure(props);
                }} />
            </Label>      
        </Group>
    );
}