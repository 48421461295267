import React, { FC } from "react";
import { AccessorioViewModel } from "../../../../../core/common_types";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const CardAccessorio: FC<{accessorio: AccessorioViewModel, quantity: number, onRemove: (idAccessorio: number) => void}> = ({accessorio, quantity, onRemove}) => {
    return (
        <div className="card bg-light mb-2">
            <div className="car-body p-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="p-2">
                        <h6 className="text-secondary m-0" style={{ fontSize: ".85rem" }}>
                            {accessorio?.data?.name}
                        </h6>
                        <div>
                            <span className="badge bg-light text-dark">&euro; {accessorio?.data?.price}</span> <span className="badge bg-light text-dark">SKU {accessorio?.data?.sku}</span> <span className="badge bg-light text-dark">Q.TÀ: {quantity}</span>
                        </div>
                    </div>

                    <IconButton onClick={() => onRemove(accessorio?.data?.id as number)}>
                        <CancelIcon color={"primary"} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}