import axios from "axios"
import { CollectionRequestResult, RequestResult, GenericRequestResult } from "../core/common_types"
import { Category } from "../models/category"
import { User } from "../models/user"
import { error, getAxiosConfig } from "../core/common_functions";

export const useCategoriesApi = () => {
    return {
        async findActiveCategories(currentUser: User): Promise<GenericRequestResult<Category[]>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        }
    }
}