import axios from "axios";
import { User } from "../models/user";
import { error, getAxiosConfig } from "../core/common_functions";
import { GenericRequestResult } from "../core/common_types";
import { Color } from "../models/door_configuration";

export const useColorsApi = () => {
    const area = "colors";

    return {
        async findByCategory(currentUser: User, categoryId: number): Promise<GenericRequestResult<Color[]>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/findByCategory?categoryId=${categoryId}`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        }
    }
}