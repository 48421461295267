import "../../../assets/css/style.css";

import React, { useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation, useMatch, matchPath } from "react-router-dom";
import { DimensionsPage } from "./builder/dimensions_page";
import { useStoreSelector } from "../../../../hooks/StoreHooks";
import { LayoutPage } from "../../../../components/Layout/layoutPage";
import TopBar from "../../../../components/TopBar/TopBar";
import { getProductName } from "../../../../Utility";
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { I18n } from "../../../../i18n";
import { AspectPage } from "./builder/aspect_page";
import { Button, } from "@mui/material";
import { ConfigurationStepper } from "../../../ui/components/configuration_stepper";
import DialogsProvider, { useDialogs } from "../../../providers/dialogs_provider";
import { DoorConfigurationLoadPage } from "./builder/door_configuration_load_page";
import { MotorizationAccessoriesPage } from "./builder/motorization_accessories_page";
import { MotorizationPage } from "./builder/motorization_page";
import { OtherAccessoriesPage } from "./builder/other_accessories_page";
import { ConditionsPage } from "./builder/conditions_page";
import { useConfigurationsService } from "../../../services/configurations_service";
import { getCurrentUser } from "../../../core/common_functions";
import Loading from "../../../../components/Loading/loading";
import { BuildPage } from "./builder/build_page";
import { QuotePage } from "./builder/quote_page";

export const DoorConfigurationsRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const currentUser = getCurrentUser(base);
    const headerRef = useRef<HTMLDivElement | null>(null);
    const [isDelete, setDelete] = useState<boolean>(false);
    const [deleteClickCount, setDeleteClickCount] = useState<number>(0);
    const configurationService = useConfigurationsService();
    const dialogs = useDialogs();

    const deleteConfiguration = async () => {
        if (deleteClickCount == 0) {
            setDeleteClickCount(1);
            setTimeout(() => setDeleteClickCount(0), 5000);
        } else if (deleteClickCount == 1) {
            setDelete(true);
            try {
                if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

                await configurationService.delete(currentUser, doorConfigurationBuilder.configuration.configurationId);

                navigate("/configurations");
            } catch (e: any) {
                setDelete(false);

                if (typeof e == "string") {
                    dialogs?.showError("Attenzione...", e);
                } else {
                    if (e.severity == "error") {
                        dialogs?.showError("Attenzione...", e.message);
                    } else if (e.severity == "warning") {
                        dialogs?.showWarning("Attenzione...", e.message);
                    }
                }
            }
        }
    }

    if (isDelete) return <Loading />;

    return (
        <I18n locale={base.locale}>
            <LayoutPage>
                <div ref={headerRef} style={{ flex: "0 0 auto" }}>
                    <TopBar />

                    <div className={"d-flex justify-content-between p-3"}>
                        <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                            <h2 className="h2 fw-bold">
                                Nuova configurazione {getProductName(doorConfigurationBuilder.configuration?.categoryCode)} (#{doorConfigurationBuilder.configuration?.configurationId})
                            </h2>
                        </div>
                        <div>
                            <Button variant={deleteClickCount == 0 ? "outlined" : "contained"} color="error" className="me-3 px-2" onClick={() => deleteConfiguration()}>
                                <RemoveCircleOutlineOutlinedIcon />
                                <span className="ms-2">{deleteClickCount == 0 ? "ELIMINA" : "CONFERMA"}</span>
                            </Button>

                            <Button variant="contained" color="secondary" onClick={() => navigate("/configurations")}>
                                <CloseIcon />
                                <span className="ms-2">CHIUDI</span>
                            </Button>
                        </div>
                    </div>

                    {!matchPath({path: "/door-configurations/:id/preventivo"}, location.pathname) && <ConfigurationStepper area={"door-configurations"} steps={[
                        { title: "Dimensioni", slug: "dimensioni", image: "https://media.deltaoverlap.com/public/img/Icone/Dimensioni.png", isActive: false, isComplete: false, isVisible: true },
                        { title: "Aspetto", slug: "aspetto", image: "https://media.deltaoverlap.com/public/img/Icone/Aspetto.png", isActive: false, isComplete: false, isVisible: true },
                        { title: "Finestratura", slug: "finestratura", image: "https://media.deltaoverlap.com/public/img/Icone/Finestre.png", isActive: false, isComplete: false, isVisible: false },
                        { title: "Ventilazione", slug: "ventilazione", image: "https://media.deltaoverlap.com/public/img/Icone/Ventilazione.png", isActive: false, isComplete: false, isVisible: false },
                        { title: "Portina Pedonale Integrata", slug: "portina-pedonale", image: "https://media.deltaoverlap.com/public/img/Icone/Porta_integrata.png", isActive: false, isComplete: false, isVisible: false },
                        { title: "Portina Pedonale Affiancata", slug: "portina-pedonale-affiancata", image: "https://media.deltaoverlap.com/public/img/Icone/Porta_laterale.png", isActive: false, isComplete: false, isVisible: false },
                        { title: "Motorizzazione", slug: "motorizzazione", image: "https://media.deltaoverlap.com/public/img/Icone/Accessori.png", isActive: false, isComplete: false, isVisible: true },
                        { title: "Accessori motorizzazione", slug: "accessori-motorizzazione", image: "https://media.deltaoverlap.com/public/img/Icone/Accessori.png", isActive: false, isComplete: false, isVisible: true },
                        { title: "Accessori vari", slug: "accessori-vari", image: "https://media.deltaoverlap.com/public/img/Icone/Accessori.png", isActive: false, isComplete: false, isVisible: true },
                        { title: "Condizioni", slug: "condizioni", image: "https://media.deltaoverlap.com/public/img/Icone/Richiesta.png", isActive: false, isComplete: false, isVisible: true }
                    ]} />}
                </div>

                <div style={{ flex: "1 1 auto", minHeight: "0" }}>
                    <DialogsProvider>
                        <Routes>
                            <Route path="/:id" element={<DoorConfigurationLoadPage />} />
                            <Route path="/:id/dimensioni" element={<DimensionsPage />} />
                            <Route path="/:id/aspetto" element={<AspectPage />} />
                            <Route path="/:id/portina-pedonale" element={<span>Work in progress...</span>} />
                            <Route path="/:id/portina-pedonale-affiancata" element={<span>Work in progress...</span>} />
                            <Route path="/:id/motorizzazione" element={<MotorizationPage />} />
                            <Route path="/:id/accessori-motorizzazione" element={<MotorizationAccessoriesPage />} />
                            <Route path="/:id/accessori-vari" element={<OtherAccessoriesPage />} />
                            <Route path="/:id/generazione-preventivo" element={<BuildPage />} />
                            <Route path="/:id/condizioni" element={<ConditionsPage />} />
                            <Route path="/:id/preventivo" element={<QuotePage />} />
                            <Route path="*" element={<p>Not found</p>} />
                        </Routes>
                    </DialogsProvider>
                </div>
            </LayoutPage>
        </I18n>
    );
}