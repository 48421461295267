import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  CircularProgress,
  FormGroup,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import Box from "@mui/material/Box";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import { DialogCustom } from "../../components/Dialog/dialog";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Container from "../../components/DragAndDrop/Container";
import box from "../../assets/img/box.PNG";
import { InputCustom } from "../../components/Input/InputCustom";
import DialogScroll from "../../components/Dialog/DialogScroll";
import { importBaseActions } from "../../store/reducers/base";
import { useCallback } from "react";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { OptionsTypeEnum } from "../../core/OptionsTypeEnum";
import { getProductName } from "../../Utility";

const Ventilazione = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: "loading",
    steps: null,
    accessories: null,
    colors: null,
    currentStep: null,
    error: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [openDrop, setDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [color1, setColor1] = useState("");
  const [checkColor1, setCheckColor1] = useState(false);
  const [nameColor, setNameColor] = useState("");
  const [numberItem, setNumberItem] = useState(null);
  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState();
  const [imgModal, setImgModal] = useState("");
  const [idPos, setIdPos] = useState();
  const [img, setImg] = useState("");
  const [prova, setProva] = useState("");
  const [selected1, setSelected1] = useState();
  const [empty, setEmpty] = useState(false);
  const [idAcc, setIdAcc] = useState(count.radioF);
  const [accModal, setAccModal] = useState();
  const eventBroadcast = useEventBroadcast();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  useEffect(() => {
    setSelected(null);
    setImgModal("");
  }, [count.radioF]);

  const handleCloseDrop = () => {
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/save-accessories`,
    //     {
    //       qty: numberItem,
    //       id: count.radioV,
    //       quote: count.quoteId,
    //       slug: "griglie_modal",
    //       position: count.posizione,
    //       imgModal: imgModal,
    //       selectedV: selected,
    //     },
    //     axiosConfig
    //   )
    //   .then((res) => res.data && setReload((v) => !v))
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    setDrop(false);
  };

  // const fetcher = (url) =>
  //   axios.post(url, { id: count.idPorta }, axiosConfig).then((res) => res.data.data);
  // const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/categories-steps`], fetcher);

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  // const fetcher7 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         category: count.idPorta,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => res.data.data);
  // const { data: colors, error: errorColors } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/colors`],
  //   fetcher7
  // );

  // const fetcher3 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         quote: count.quoteId,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       setSelected(res.data.data.id_position_V);
  //       return res.data.data;
  //     });
  // const { data: position, error: errorPosition } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/position`, location.pathname],
  //   fetcher3
  // );

  // const fetcher4 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         quote: count.quoteId,
  //         slug: "griglie_modal",
  //         type: "radio",
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       if (res.data.data) {
  //         res.data.data.id && dispatch(baseActions.setRadioV(res.data.data.id));
  //       }
  //       return res.data.data.id;
  //     });
  // const { data: getIdAccessories, error: errorGetIdAccessories } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/get-id-accessories`],
  //   fetcher4
  // );

  // const fetcher2 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         id: count.idPorta,
  //         quote: count.quoteId,
  //         step: location.pathname.substring(1),
  //         idAcc: count.radioV !== "" ? count.radioV : 0,
  //         category_id: count.idPorta,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       setAccModal(res.data.data);
  //       return res.data;
  //     });
  // const { data: accessories, error: errorAccessories } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/accessories`, count.radioV],
  //   fetcher2
  // );

  const load = useCallback(async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);      
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }      
    } catch (e) {
      
    }

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/accessories`, { 
        id: count.idPorta,
        quote: count.quoteId,
        step: location.pathname.substring(1),
        idAcc: count.radioV !== "" ? count.radioV : 0,
        category_id: count.idPorta,
      }, axiosConfig);
      
      let accessories = [];
      let pos = [];
      if (res.data.success == 1 && res.data.data) {
        accessories = res.data.data;
        pos = res.data.pos;
        setAccModal(accessories);
      }

      res = await axios.post(`${process.env.REACT_APP_API_URL}/colors`, { 
        category: count.idPorta,
      }, axiosConfig)

      let colors = res.data.data;
     
      setState({
        state: "idle",
        steps, 
        accessories,
        pos,
        colors,
        currentStep,
      });
    } catch (e) {
      setState({
        state: "error",
        error: e
      });
    }
  });  

  const getConfPos = async (idAcc) => {
    if (!idAcc || idAcc <= 0) {
      setState({
        ...state,
        state: "idle",
        pos: []
      });
      return;
    }

    setState({...state, state: "loading_conf_pos"});
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/conf-pos`, {
        quoteId: count.quoteId,
        type: location.pathname.substring(1),
        idAcc: idAcc,
        categoryId: count.idPorta,
      }, axiosConfig);

      if (res.data.success == 1 && res.data.data) {
        setState({
          ...state,
          state: "idle",
          pos: res.data.data
        });
      }
    } catch (e) {
      setState({...state, state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const setGriglia = async (index, selectedPos) => {
    setIdAcc(selectedPos.id);
    setSelected1(index);
    setEmpty(false);
    dispatch(baseActions.setRadioV(selectedPos.id));
    setNumberItem(0);
    setSelected(null);
    setImgModal(null);
    setIdPos(null);
    dispatch(baseActions.setQtaV(0));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...count,
      radioV: selectedPos.id,
      qtaV: 0
    });
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/save-accessories`,
    //     {
    //       qty: 0,
    //       id: 0,
    //       quote: count.quoteId,
    //       slug: location.pathname.substring(1),
    //     },
    //     axiosConfig
    //   )
    //   .then(
    //     (res) => res.data.success === 1 && setOpen(false)
    //   )
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    getConfPos(selectedPos.id);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (state.pos && state.pos.length > 0) {
      setQuantity(state.pos[0].number_item);
    }
  }, [state.pos]);

  const setQuantity = (qty) => {
    setNumberItem(qty);
    setSelected(qty);
    setIdPos(qty);
    dispatch(baseActions.setQtaV(qty));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...count,
      qtaV: qty
    });
  }

  const h = 4;
  //const [item, setItem] = useState(state.posizione);

  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = state.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }    
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, { 
      id: count.idPorta,
    });
  }

  const generaPosizioneGriglie = () => {
    let res = [];
    for (let i = 0; i < h; i++) {
      res.push({
        id: i,
        id_pos: 0,
        img: "",
        applied: i == 3 && Number(count.radioV) > 0,
        type: "griglie",
        qty: numberItem,        
        id_acc: Number(count.radioV) > 0 ? count.radioV : 0
      });
    }
    //dispatch(baseActions.setPosizione(item));
    return res;
  };

  const submit = async () => {
    if (count.qtaV <= 0 && count.radioV > 0) {
      setState({
        ...state,
        error: "error_num_griglie"
      });
      return;
    }

    try {
      let posizione = generaPosizioneGriglie();
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, {
        qty: numberItem,
        id: count.radioV,
        quote: count.quoteId,
        slug: "griglie_modal",
        position: posizione,
        imgModal: imgModal,
        selectedV: Number(count.radioV) > 0,
      }, axiosConfig);
      
      if (res.data.success == 1) {
        dispatch(baseActions.setVentilazione(true));

        navigateToNextStep();
      }
    } catch (e) {
      console.log(e);
    }
  }

  if ("error" == state.status) return <div>failed to load</div>;
  if ("loading" == state.status) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
          </Typography>
        </Grid>

        <div>
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
                
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {state.currentStep && <TabsCustom
            value={state.currentStep.id}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
        <GridPreventivi
          header={null
            // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
            //   <div className="col-span-4 text-center">
            //     <Typography variant={"h4"} fontWeight={700} mt={5}>
            //       Griglia
            //     </Typography>
            //   </div>
            // </div>
          }
          dataSend={{
            quote_id: count.quoteId,
            category_id: count.idPorta,
            slug: location.pathname.substring(1),
            idAcc: count.radioV,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={reload}>
          {!accModal ? (
            <CircularProgress />
          ) : (
            <>
              <div className={"grid grid-cols-8 mt-6"}>
                <div
                  onClick={() => {
                    dispatch(baseActions.setRadioV(0));    
                    dispatch(baseActions.setQtaV(0));                   
                    eventBroadcast.dispatch(Events.configurationChanged, {
                      ...count,
                      radioV: 0,
                      qtaV: 0
                    });
                    setEmpty(true);
                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/save-accessories`,
                        {
                          qty: 0,
                          id: 0,
                          quote: count.quoteId,
                          slug: location.pathname.substring(1),
                        },
                        axiosConfig
                      )
                      .then((res) => res.data.success === 1 && setOpen(false))
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                  className={"col-start-2 col-span-6 flex items-center cursor-pointer"}>
                  <div style={{flex: "0 0 auto"}}>
                    <img
                      src={box}
                      alt={"img"}
                      style={
                        empty || count.radioV === 0
                          ? {
                              width: 80,
                              height: 70,
                              border: "3px solid #dc3d22",
                            }
                          : {
                              width: 80,
                              height: 70,
                              "&:hover": {
                                border: "3px solid #dc3d22",
                              },
                            }
                      }
                    />
                  </div>
                  <div className="p-4" style={{flex: "1 1 auto"}}>
                    <Typography
                      sx={(empty || count.radioV === 0) ? { color: "#dc3d22" } : {}}
                      variant={"h7"}>
                      Nessuna griglia
                    </Typography>
                  </div>
                  <div style={{flex: "0 0 auto"}}>
                    <Radio checked={empty || count.radioV === 0} />
                  </div>
                </div>
                {accModal.map((res, index) => {
                  if (res.type === "radio") {
                    return (
                      <div
                        onClick={() => setGriglia(index, res)}
                        key={res.id + index}
                        className={"col-start-2 col-span-6 flex items-center cursor-pointer"}>
                        <div style={{flex: "0 0 auto"}}>
                          <img
                            src={res.img}
                            alt={"img"}
                            style={
                              (selected1 === index ||
                                count.radioV === res.id) &&
                              !empty
                                ? {
                                    width: 80,
                                    height: 70,
                                    border: "3px solid #dc3d22",
                                  }
                                : {
                                    width: 80,
                                    height: 70,
                                    "&:hover": {
                                      border: "3px solid #dc3d22",
                                    },
                                  }
                            }
                          />
                        </div>
                        <div className="p-4" style={{flex: "1 1 auto"}}>
                          <Typography
                            sx={
                              (selected1 === index || count.radioV === res.id) &&
                              !empty ? {
                                color: "#dc3d22",
                              } :  {}
                            }
                            variant={"h7"}>
                            {res.name}
                          </Typography>
                        </div>
                        <div style={{flex: "0 0 auto"}}>
                          <Radio
                            checked={
                              (selected1 === index ||
                                count.radioV === res.id) &&
                              !empty
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              
                <div className="col-start-2 col-span-6">
                  {(count.radioV !== 0 || idAcc !== 0) && (
                    <>
                      <FormGroup sx={{ mt: 6, display: "none" }}>
                        {state.accessories && state.accessories.map((res) => {
                          return (
                            <div key={res.id}>
                              {res.type === "checkbox" && (
                                <Box
                                  sx={{
                                    display: res.sku.startsWith("ACCV02")
                                      ? "block"
                                      : "flex",
                                    flexDirection: res.sku.startsWith("ACCV02")
                                      ? "column"
                                      : "row",
                                    alignItems: res.sku.startsWith("ACCV02")
                                      ? "flex-start"
                                      : "center",
                                    justifyContent: res.sku.startsWith("ACCV02")
                                      ? "flex-start"
                                      : "center",
                                  }}>
                                  <CheckboxCustom
                                    selected={res.selected}
                                    value={res.sku}
                                    name={res.sku}
                                    label={res.name}
                                    axiosConfig={axiosConfig}
                                    idAcc={res.id}
                                    quote={count.quoteId}
                                  />
                                  {res.sku.startsWith("ACCV02") && (
                                    <>
                                      <Typography
                                        color={"primary"}
                                        sx={{
                                          lineHeight: 0,
                                          marginTop: 2,
                                        }}
                                      >
                                        Colore
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: 2,
                                        }}
                                      >
                                        <InputCustom
                                          name={"larghezza"}
                                          value={prova}
                                          placeholder={"Inserisci il codice"}
                                          onChange={(e) => {
                                            setProva(e.target.value);
                                            axios
                                              .post(
                                                `${process.env.REACT_APP_API_URL}/get-accessories-notstandard`,
                                                {
                                                  color: e.target.value,
                                                  base: "ACCV02",
                                                  quote_id: count.quoteId,
                                                  slug: location.pathname.substring(
                                                    1
                                                  ),
                                                },
                                                axiosConfig
                                              )
                                              .then((res) =>
                                                setImg(res.data.data.img)
                                              )
                                              .catch(function (error) {
                                                console.log(error);
                                              });
                                          }}
                                        />
                                        <img
                                          style={{
                                            width: 90,
                                            height: 70,
                                            marginTop: 12,
                                            padding: 2,
                                            marginLeft: 6,
                                          }}
                                          src={img ? img : box}
                                          alt={"imgLeft"}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {!res.sku.startsWith("ACCV02") && (
                                    <img
                                      style={{
                                        width: 90,
                                        height: 70,
                                        marginTop: 12,
                                        padding: 2,
                                        marginLeft: 6,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setOpen(true);
                                        setCheckColor1(true);
                                      }}
                                      src={box}
                                      alt={"imgLeft"}
                                    />
                                  )}
                                </Box>
                              )}
                            </div>
                          );
                        })}
                      </FormGroup>
                      {Number(count.radioV) > 0 && (
                        <>
                          <Typography color={"primary"} sx={{ marginTop: 6 }}>
                            Selezionare il numero di griglie
                          </Typography>
                          <div className={"mt-3"}>
                            <TextField
                              select
                              fullWidth
                              label={"Quantità"}
                              name={"qty"}
                              placeholder={"Quantità"}
                              value={count.qtaV}
                              onChange={(event) => setQuantity(event.target.value)}>
                              {state.pos.map((res, index) => {
                                return <MenuItem key={index} value={res.number_item}>{res.number_item}</MenuItem>;
                              })}
                            </TextField>                            
                          </div>
                          {/* <div
                            style={{ maxWidth: "100%" }}
                            className={"flex flex-row  flex-wrap"}>
                            {state.pos && state.pos.map((res, index) => {
                              return (
                                <div key={res.id}>
                                  <div
                                    onClick={() => {
                                      setNumberItem(res.number_item);
                                      setSelected(index);
                                      setImgModal(res.img_modal);
                                      setIdPos(res.number_item);
                                      dispatch(baseActions.setQtaV(res.number_item));                   
                                      eventBroadcast.dispatch(Events.configurationChanged, {
                                        ...count,
                                        qtaV: res.number_item
                                      });
                                    }}
                                    className={"cursor-pointer p-2 mt-2 w-20"}>
                                    <img
                                      style={
                                        selected === index
                                          ? {
                                              border: "3px solid #dc3d22",
                                            }
                                          : {
                                              "&:hover": {
                                                border: "3px solid #dc3d22",
                                              },
                                            }
                                      }
                                      alt={"img"}
                                      src={res.thumb}
                                      title={res.number_item}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div> */}
                        </>
                      )}
                      {(/*AT disattivato per il momento*/ false && (imgModal || selected !== null)) && (
                        <div
                          onClick={() => {
                            if (imgModal === "") {
                              setNumberItem(state.accessories.pos[selected].number_item);
                              setImgModal(state.accessories.pos[selected].img_modal);
                              setIdPos(state.accessories.pos[selected].number_item);
                              setDrop(true);
                            } else setDrop(true);
                          }}
                          className={"flex flex-row justify-end items-center mt-4"}>
                          <Typography variant={"h6"}>Posizione Griglie</Typography>
                          <IconButton>
                            <PictureInPictureIcon
                              sx={{ width: 30, height: 30 }}
                              color={"primary"}
                            />
                          </IconButton>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <DialogCustom
                open={openDrop}
                handleClose={handleCloseDrop}
                title={"Posizionamento Griglie"}
                fullScreen={false}
                content={
                  <DndProvider backend={HTML5Backend}>
                    <Container
                      numberItem={numberItem}
                      idAcc={count.radioV}
                      img={imgModal}
                      idPos={idPos}
                    />
                  </DndProvider>
                }
              />
              <DialogScroll
                open={open}
                setOpen={setOpen}
                scroll={scroll}
                setScroll={setScroll}
                data={state.colors}
                setNameColor={setNameColor}
                axios_config={axiosConfig}
                color1={color1}
                endpoint={"save-accessories"}
                setColor1={setColor1}
                quote_id={count.quoteId}
                checkColor1={checkColor1}
              />

              {"error_num_griglie" == state.error && count.radioV > 0 && (
                <div className={"grid grid-cols-8 mt-6 gap-4"}>
                  <div className="col-start-2 col-span-6">
                    <Alert severity="error">Selezionare il numero di griglie da aggiungere</Alert>
                  </div>
                </div>
              )}
              
              <div className={"grid grid-cols-8 mt-6 gap-4"}>
                <div className="col-start-2 col-span-3">
                  <Button
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      py: 1,
                    }}
                    onClick={() => navigate("/finestrature")}>
                    INDIETRO
                  </Button>
                </div>
                
                <div className="col-span-3">
                  <Button
                    fullWidth
                    sx={{ color: "white", fontWeight: "bold", py: 1 }}
                    variant={"contained"}
                    onClick={() => submit()}>
                    AVANTI
                  </Button>
                </div>
              </div>
            </>
          )}
        </GridPreventivi>
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Ventilazione;
