import React, { useEffect, useRef, useState } from "react";
import { I18n } from "../../../i18n";
import { useStoreDispatch, useStoreSelector } from "../../../hooks/StoreHooks";
import { LayoutPage } from "../../../components/Layout/layoutPage";
import TopBar from "../../../components/TopBar/TopBar";
import translate from "../../../i18n/messages/translate";
import { importBaseActions } from "../../../store/reducers/base";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from "react-router-dom";
import { Customer } from "../../models/customer";
import { getAxiosConfig, getCurrentUser } from "../../core/common_functions";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import axios from "axios";
import { Pagination } from "@mui/material";
import { LoadingInternal } from "../../../components/Loading/LoadingInternal";
import { usePaginationStyles } from "../../../core/MuiStyles";
import { useCustomersService } from "../../services/customers_service";

const ITEMS_PER_PAGE = 15;

type SearchPageState = {
    page: number, 
    pageSize: number,
    items: Customer[],
    itemsCount: number,
    pageCount: number,
    isLoading: boolean,
    isSearching: boolean
}

export const SearchPage = () => {
    const customersService = useCustomersService();

    const store = useStoreSelector(store => store.base);
    const baseActions = importBaseActions();
    const dispatch = useStoreDispatch();
    const navigate = useNavigate();
    const currentUser = getCurrentUser(store);
    const paginationClasses = usePaginationStyles();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [filter, setFilter] = useState<{ searchText: string }>({ searchText: "" });

    const [state, setState] = useState<SearchPageState>({
        page: 0, 
        pageSize: ITEMS_PER_PAGE,
        items: [],
        itemsCount: 0,
        pageCount: 0,
        isSearching: false,
        isLoading: true
    });

    const getBackPath = () => {
        let destination = "";
        switch (currentUser.userType) {
            case "SuperAdmin":
            case "Admin":
                destination = "/area-managers";
                break;
            case "AreaManager":
                destination = "/agenti";
                break;

            case "Agente":
                destination = "/clienti";
                break;

            case "Retail":
                destination = "/configurations";
                break;
        }
        return destination;
    }

    const getScontistica = (customer: Customer) => {
        let scontistica = "";
        if (customer.discount1 && customer.discount1 > 0) {
            scontistica += customer.discount1;
        }
        if (customer.discount2 && customer.discount2 > 0) {
            scontistica += " + " + customer.discount2;
        }
        if (customer.discount3 && customer.discount3 > 0) {
            scontistica += " + " + customer.discount3;
        }
        return scontistica ? scontistica : "0";
    }

    const showUserMenu = (customer: Customer) => {
        document.querySelectorAll(`div.dropdown:not(div.dropdown_agent_${customer.id})`).forEach(e => {
            e.classList.remove("show");
            e.querySelector(`ul.dropdown-menu`)?.classList.remove("show");
        });

        let dropdown = document.querySelector(`div.dropdown_agent_${customer.id}`);
        let dropdownMenu = dropdown?.querySelector(`ul.dropdown-menu`);

        dropdown?.classList.toggle("show");
        dropdownMenu?.classList.toggle("show");
    }

    const closeUserMenu = () => {
        document.querySelectorAll(`div.dropdown`).forEach(e => {
            e.classList.remove("show");
            e.querySelector(`ul.dropdown-menu`)?.classList.remove("show");
        });
    }

    const resetPassword = async (customer: Customer) => {
        //setLoading(true);
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/customers/generate-password`, {
            id: customer.id,
        }, getAxiosConfig(currentUser));

        res.data.success == 1 && dispatch(baseActions.setSuccess(true));
        res.data.success === 1 && alert("La password è stata reimpostata con successo. L'utente riceverà un'email con la nuova password.");

        //setLoading(false);
        closeUserMenu();
    }

    const load = async (page: number) => {
        if (state.isLoading) return;

        if (!filter.searchText) {
            setState({...state, items: [], itemsCount: 0, page: 0});
            return;
        }

        setState({...state, isSearching: true });
        try {
            let res = await customersService.search(currentUser, filter.searchText, page, state.pageSize);

            let pageCount = Math.ceil(res.itemsCount / ITEMS_PER_PAGE);
            setState({...state, items: res.items, itemsCount: res.itemsCount, page: page, pageCount: pageCount, isSearching: false });
        }
        catch (e) {
            console.log(e);

            setState({...state, isSearching: false });
        }
    }

    const performSearch = (searchText: string) => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        timerRef.current = setTimeout(() => setFilter({ ...filter, searchText }), 600);
    }

    useEffect(() => {
        setState({ ...state, isLoading: false });
    }, []);

    useEffect(() => {
        load(0);
    }, [filter]);

    return (
        <I18n locale={store.locale}>
            <LayoutPage>
                <TopBar />

                <div className={"d-flex justify-content-between p-3"}>
                    <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
                        <h2 className="h2 fw-bold">
                            {/*translate("Ricerca clienti")*/}
                            Ricerca clienti
                        </h2>
                    </div>
                    <div>
                        <Link to={getBackPath()} className="btn btn-secondary">
                            <ArrowBackIcon />
                        </Link>
                    </div>
                </div>

                <div className="w-full px-3 pb-3">
                    <div className="mb-3">
                        <div className="row bg-light m-0 rounded">
                            <div className="col-xl-4 p-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="digita per iniziare la ricerca"
                                    onInput={(e) => performSearch(e.currentTarget.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {state.isSearching && <span>Attendere...</span>}

                    {!state.isSearching && state.itemsCount > 0 && (
                        <>
                            {state.items.map(customer => (
                                <div key={customer.id} style={{ cursor: "pointer" }} className="card card-hover mb-2" onClick={() => {
                                    dispatch(baseActions.setCustomer({ id: customer.id, lastName: customer.surname, firstName: customer.name, isServiceCustomer: true }));
                                    navigate("/configurations");
                                }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title m-0">{customer.surname} {customer.name}</h5>

                                            <div className={`dropdown dropdown_agent_${customer.id}`}>
                                                <button type="button" className="btn btn-light btn-sm" onClick={(evt) => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    showUserMenu(customer);
                                                }}>
                                                    <MoreVertIcon />
                                                </button>

                                                <ul className="dropdown-menu" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 40px)" }}>                                                    
                                                    <li>
                                                        <a className="dropdown-item" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            resetPassword(customer);
                                                        }}>
                                                            <LockIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                                                            {translate("RESET_PASSWORD")}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <small>{customer.city}</small>                                            
                                        </div>
                                        <div className="d-flex flex-row-reverse">
                                            <span>Scontistica <span className="badge bg-secondary">{getScontistica(customer)} %</span></span>
                                            &nbsp;-&nbsp;
                                            <span>Anticipo <span className="badge bg-secondary">{customer.down_payment_percentage} %</span></span>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="row">
                                <div className="col-xl-6">
                                    Totale: <span className="badge bg-primary">{state.itemsCount}</span>
                                </div>

                                <div className="col-xl-6">
                                    <Pagination
                                        className={paginationClasses.root + " justify-content-end mb-0"}
                                        color="primary"
                                        count={state.pageCount}
                                        page={state.page + 1}
                                        onChange={(event, value) => load(value - 1)}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* <ModalEdit
                    title={"Modifica cliente"}
                    open={openEdit}
                    handleClose={handleCloseEdit}>
                    <ClientiForm handleClose={handleCloseEdit} user={user} />
                </ModalEdit> */}

                {state.isLoading && <LoadingInternal />}
            </LayoutPage>
        </I18n>
    );
}