import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import Loading from "../../components/Loading/loading";
import { importBaseActions } from "../../store/reducers/base";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../hooks/StoreHooks";
import { getCurrentCustomer, getCurrentUser } from "../../v2/core/common_functions";
import { useCategoriesService } from "../../v2/services/categories_service";
import { Category, CategoryCode } from "../../v2/models/category";
import { useDoorConfigurationBuilderActions } from "../../store/reducers/door_configuration_builder";
import { useConfigurationsService } from "../../v2/services/configurations_service";

const Porte = () => {
  const categoriesService = useCategoriesService();
  const configurationsService = useConfigurationsService();
  const { base, doorConfigurationBuilder } = useStoreSelector(state => state);
  const {setConfiguration} = useDoorConfigurationBuilderActions();
  const currentUser = getCurrentUser(base);
  const currentCustomer = getCurrentCustomer(base)
  const dispatch = useStoreDispatch();
  const baseActions = importBaseActions();
  //const history = useHistory();
  const navigate = useNavigate();
  const [categories, setCategories] = useState<{isLoading: boolean, items: Category[]}>({isLoading: true, items: []});
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      let result = await categoriesService.findActiveCategories(currentUser);

      setCategories({items: result, isLoading: false});
    } catch (e: any) {
      setError(e);
    }
  }

  const setCategory = async (category: Category) => {
    try {
      let result = await configurationsService.create(currentUser, category.id, currentCustomer?.id);

      if (category.code != CategoryCode.PortaSezionale) {
        dispatch(baseActions.setIdPorta(category.id));
        dispatch(baseActions.setQuoteId(result));
        navigate("/dimensioni");
      } else {
        navigate(`/door-configurations/${result}`);
      }
    } catch (e: any) {
      setError(e);
    }
  }

  return (
    <LayoutPage>
      <TopBar />

      <div className={"d-flex justify-content-between p-3"}>
        <div className="d-flex flex-column" style={{ lineHeight: "1" }}>
          <h2 className="h2 fw-bold">
            Seleziona il prodotto da configurare
          </h2>
        </div>
        <div>
          <button className="btn btn-secondary me-3" type="button" onClick={() => navigate(-1)}>
            <CloseIcon />
            ANNULLA
          </button>
        </div>
      </div>
      <Grid
        container
        sx={{
          height: "80%",
          // width: "100%",
          // mx: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
        spacing={2}>

        {categories.isLoading && <Loading />}
        {error && <div>Errore nel caricamento...</div>}

        {categories.items.length > 0 && categories.items.map((el: any, index: number) => {
          return (
            <Grid
              key={index}
              item
              onClick={() => setCategory(el)}
              xs={12}
              md={2}
              spacing={4}
              sx={{
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                paddingBottom: 10,
              }}>
              <Card
                sx={{
                  // width: 150,
                  boxShadow: 5,
                  cursor: "pointer",
                  "&:hover": { boxShadow: 20 },
                }}>
                <div className={"flex justify-center w-full mt-6"}>
                  <img
                    className={"object-contain"}
                    src={el.img}
                    alt={"img"}
                  />
                </div>
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    component="div"
                    fontWeight={"bold"}
                    fontSize={18}
                  >
                    {el.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </LayoutPage >
  );
};

export default Porte;
