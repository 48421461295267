import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { useEffect } from "react";
import { getCurrentUser } from "../../../../core/common_functions";
import { useConfigurationsService } from "../../../../services/configurations_service";
import Loading from "../../../../../components/Loading/loading";
import { Alert, Button } from "@mui/material";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";

export const BuildPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatcher = useStoreDispatch();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const currentUser = getCurrentUser(base);
    const configurationService = useConfigurationsService();
    const { setStepActive } = useDoorConfigurationBuilderActions();
    const [build, setBuild] = useState<{ progress: boolean, error: any }>({
        progress: true,
        error: null
    });

    const buildConfiguration = async () => {
        !build.progress && setBuild({ ...build, progress: true });
        try {
            if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

            await configurationService.build(currentUser, Number(id));

            navigate(`/door-configurations/${doorConfigurationBuilder.configuration.configurationId}/condizioni`);
        } catch (e: any) {
            let message = "";
            if (typeof e == "string") {
                message = e;
            } else {
                if (e.severity == "error") {
                    message = e.message;
                } else if (e.severity == "warning") {
                    message = e.message;
                }
            }
            setBuild({ ...build, progress: false, error: message });
        }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "condizioni", isActive: true }));
            buildConfiguration();
        }
    }, []);

    if (build.progress) return <Loading />;

    return (
        <div className="d-flex justify-content-center mt-3">
            {build.error && (
                <div>
                    <h2 className="text-primary">Errore durante la generazione del preventivo...</h2>
                    <Alert severity="warning">
                        {build.error}
                    </Alert>
                    <div className="text-center mt-3">
                        <Button variant="contained" onClick={() => buildConfiguration()}>Ripeti</Button>
                    </div>
                </div>
            )}
        </div>
    );
}