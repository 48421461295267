import React, { FC, useEffect, useState } from "react";
import { Step, StepSlugsEnum } from "../../core/common_types";
import { Box } from "@mui/material";
import { useStoreDispatch, useStoreSelector } from "../../../hooks/StoreHooks";
import { useDoorConfigurationBuilderActions } from "../../../store/reducers/door_configuration_builder";
import { useNavigate } from "react-router-dom";

export const ConfigurationStepper: FC<{area: string, steps: Step[]}> = ({area, steps}) => {
    const {doorConfigurationBuilder} = useStoreSelector(store => store);
    const {setSteps} = useDoorConfigurationBuilderActions();
    const dispatcher = useStoreDispatch();
    const navigate = useNavigate();

    const getAdditionalClassNames = (step: Step): string => {
        let additionalClassNames: string[] = [];

        if (step.isActive) {
            additionalClassNames.push("active");
        } else if (step.isComplete) {
            additionalClassNames.push("completed");
        }

        return additionalClassNames.join(" ");
    }

    const changeStep = (step: Step) => {
        if (!step.isComplete) return null;

        let url = `/${area}/${doorConfigurationBuilder.configuration?.configurationId}/${step.slug}`;

        navigate(url);
    }

    useEffect(() => {
        dispatcher(setSteps(steps));
    }, []);

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"start"} sx={{backgroundColor: "#f1f1f1"}}>
            {!doorConfigurationBuilder.steps && <h6>Attendere...</h6>}
            {doorConfigurationBuilder.steps && doorConfigurationBuilder.steps.filter(s => s.isVisible).map((s, idx) => (
                <div key={"step_"+(idx+1)} className={"configuration-stepper m-3 d-flex flex-column justify-content-center align-items-center " + getAdditionalClassNames(s)} onClick={() => changeStep(s)}>
                    <div className="configuration-stepper-icon">
                        {s.image && <img src={s.image} />}
                    </div>
                    <div className="configuration-stepper-title-container">
                        <span className="configuration-stepper-title">{s.title}</span>
                    </div>
                </div>
            ))}
        </Box>
    );
}