import { useColorsApi } from "../api/colors_api";
import { Color } from "../models/door_configuration";
import { User } from "../models/user";

export const useColorsService = () => {
    const colorsApi = useColorsApi();

    return {
        async findByCategory(currentUser: User, categoryId: number): Promise<Color[]> {
            let result = await colorsApi.findByCategory(currentUser, categoryId);
            
            if (!result.success) {
                throw result.message;
            }

            if (!result.data) throw "Configuration is null";

            return result.data;
        }
    }
}