import { NullableString } from "../core/common_types"

export type Category = {
    id: number,
    name: string,
    code: string,
    img: NullableString
}

export enum CategoryCode {
    PortaSezionale = "PORTA_SEZIONALE",
    PortaOverlap = "PORTA_OVERLAP",
    PortaOverbas = "PORTA_OVERBAS",
    Pergola = "PERGOLA",
    PortaBlindata ="PORTA_BLINDATA"
};