import axios from "axios";
import { User } from "../models/user";
import { error, getAxiosConfig } from "../core/common_functions";
import { NullableNumber, GenericRequestResult, NullableString } from "../core/common_types";
import { Accessorio, AccessorioInConfigurazione, DoorConfig, DoorConfiguration, DoorConfigurationConditions, DoorOption, FormAspettoModel, FormDimensioniModel, QuoteSummary } from "../models/door_configuration";

export const useDoorConfigurationsApi = () => {
    const area = "door-configurations";

    return {
        async create(currentUser: User, categoryId: number, customerId: NullableNumber): Promise<GenericRequestResult<DoorConfiguration>> {
            try {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/${area}`, {
                    categoryId,
                    customerId
                }, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async get(currentUser: User, configurationId: number): Promise<GenericRequestResult<DoorConfiguration>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async delete(currentUser: User, configurationId: number): Promise<GenericRequestResult<any>> {
            try {
                let response = await axios.delete(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async putDimensions(currentUser: User, configurationId: number, model: FormDimensioniModel): Promise<GenericRequestResult<any>> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/dimensions`, model, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async putAspect(currentUser: User, configurationId: number, model: FormAspettoModel): Promise<GenericRequestResult<DoorConfig>> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/aspect`, model, getAxiosConfig(currentUser));

                if (response.status != 200) {
                    throw response.statusText;
                }

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async findDoorsOptions(currentUser: User, configuration: DoorConfiguration): Promise<GenericRequestResult<DoorOption[]>> {
            try {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/${area}/doors/options`, configuration, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async findCompatibleMotorizations(currentUser: User, configurationId: number): Promise<GenericRequestResult<Accessorio[]>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/motorizations`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async getMotorization(currentUser: User, configurationId: number): Promise<GenericRequestResult<NullableNumber>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/motorization`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async putMotorization(currentUser: User, configurationId: number, motorizationId: number): Promise<GenericRequestResult<any>> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/motorization`, {motorizationId}, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async getMotorizationAccessories(currentUser: User, configurationId: number): Promise<GenericRequestResult<AccessorioInConfigurazione[]>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/motorization-accessories`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async getGenericAccessories(currentUser: User, configurationId: number): Promise<GenericRequestResult<AccessorioInConfigurazione[]>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/generic-accessories`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async putAccessory(currentUser: User, configurationId: number, idAccessorio: number, qty: number): Promise<any> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/accessories`, {accessoryId: idAccessorio, quantity: qty}, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async deleteAccessory(currentUser: User, configurationId: number, idAccessorio: number): Promise<any> {
            try {
                let response = await axios.delete(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/accessories?accessoryId=${idAccessorio}`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async getConditions(currentUser: User, configurationId: number): Promise<GenericRequestResult<DoorConfigurationConditions>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/conditions`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async build(currentUser: User, configurationId: number): Promise<any> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/build-quote`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async updateConditions(currentUser: User, configurationId: number, data: DoorConfigurationConditions): Promise<GenericRequestResult<DoorConfigurationConditions>> {
            try {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/validate-conditions`, data, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async setConditions(currentUser: User, configurationId: number, data: DoorConfigurationConditions): Promise<GenericRequestResult<DoorConfigurationConditions>> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/conditions`, data, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async getQuote(currentUser: User, configurationId: number): Promise<GenericRequestResult<QuoteSummary>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/quote`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async resetQuote(currentUser: User, configurationId: number): Promise<GenericRequestResult<any>> {
            try {
                let response = await axios.delete(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/reset-quote`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },

        async savePreview(currentUser: User, configurationId: number, preview: NullableString): Promise<GenericRequestResult<any>> {
            try {
                let response = await axios.put(`${process.env.REACT_APP_API_URL}/${area}/${configurationId}/save-preview`, {
                    preview
                }, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        }
    }
}