import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scene } from "./components/object-draw/scene";
import { Button } from "@mui/material";
import { GarageExternal } from "./components/object-draw/garage_external";
import { MetricsSummary } from "./components/panels/pnl_metrics_summary";
import { FormModelloFinitura } from "./components/forms/frm_modello_finitura";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { getCurrentUser, toDrawUnit } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { useOptionsService } from "../../../../services/options_service";
import { LoadingState } from "../../../../core/common_types";
import { useColorsService } from "../../../../services/colors_service";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { Color, FormAspettoModel, Option } from "../../../../models/door_configuration";
import { PROFONDITA_INVOLUCRO } from "../../../../core/common_constants";

export const AspectPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
    const dispatcher = useStoreDispatch();
    const currentUser = getCurrentUser(base);
    const { setStepActive, setStepComplete, setStepVisible } = useDoorConfigurationBuilderActions();
    const dialogs = useDialogs();
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const configurationService = useConfigurationsService();
    const sceneContainerRef = useRef<HTMLDivElement>(null);

    const submit = async () => {  
      setSubmit(true);
      try {
        if (!doorConfigurationBuilder.configuration) throw "invalid configuration";
        if (!doorConfigurationBuilder.configuration.porta) throw {severity: "warning", message: "Scegli il tipo di porta"};
        if (!doorConfigurationBuilder.configuration.modello) throw {severity: "warning", message: "Scegli il modello"};
        if (!doorConfigurationBuilder.configuration.finitura) throw {severity: "warning", message: "Scegli la finitura"};
        if (doorConfigurationBuilder.configuration.tipoColoreEsterno == "ral_standard" && !doorConfigurationBuilder.configuration.coloreEsterno) throw {severity: "warning", message: "Seleziona il colore esterno"};
        if (doorConfigurationBuilder.configuration.tipoColoreEsterno == "ral_non_standard" && !doorConfigurationBuilder.configuration.coloreEsternoNsRal) throw {severity: "warning", message: "Inserisci il RAL da usare come colore esterno"};
        if (doorConfigurationBuilder.configuration.tipoColoreInterno == "ral_non_standard" && !doorConfigurationBuilder.configuration.coloreInternoNsRal) throw {severity: "warning", message: "Inserisci il RAL da usare come colore interno"};
  
        let data: FormAspettoModel = {
          porta: doorConfigurationBuilder.configuration.porta,
          modello: doorConfigurationBuilder.configuration.modello,
          finitura: doorConfigurationBuilder.configuration.finitura,
          tipoColoreEsterno: doorConfigurationBuilder.configuration.tipoColoreEsterno,
          coloreEsterno: doorConfigurationBuilder.configuration.coloreEsterno,
          coloreEsternoNsRal: doorConfigurationBuilder.configuration.coloreEsternoNsRal,
          coloreEsternoACampione: doorConfigurationBuilder.configuration.coloreEsternoACampione,
          tipoColoreInterno: doorConfigurationBuilder.configuration.tipoColoreInterno,
          coloreInterno: doorConfigurationBuilder.configuration.coloreInterno,
          coloreInternoNsRal: doorConfigurationBuilder.configuration.coloreInternoNsRal,
          coloreInternoACampione: doorConfigurationBuilder.configuration.coloreInternoACampione,
        };
  
        let res = await configurationService.updateAspect(currentUser, doorConfigurationBuilder.configuration.configurationId, data);

        setSubmit(false);
          
        dispatcher(setStepComplete({ stepSlug: "aspetto", isComplete: true }));
        //dispatcher(setStepVisible({ stepSlug: "portina-pedonale", isVisible: res?.integratedDoorSupport ?? false }));
        dispatcher(setStepVisible({ stepSlug: "portina-pedonale", isVisible: false }));
  
        /*if (res?.integratedDoorSupport) {
          navigate(`/door-configurations/${id}/portina-pedonale`);
        } else */if (doorConfigurationBuilder.configuration.isMotorizzata) {
          navigate(`/door-configurations/${id}/motorizzazione`);
        } else  {
          navigate(`/door-configurations/${id}/accessori-vari`);
        }
      } catch (e: any) {
        setSubmit(false);

        if (typeof e == "string") {
          dialogs?.showError("Attenzione...", e);
        } else {
          if (e.severity == "error") {
            dialogs?.showError("Attenzione...", e.message);
          } else if (e.severity == "warning") {
            dialogs?.showWarning("Attenzione...", e.message);
          }
        }
      }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
            navigate("/configurations");
        } else {
            dispatcher(setStepActive({ stepSlug: "aspetto", isActive: true }));
        }
    }, []);

    return (
        <div className="row h-full m-0">
            <div ref={sceneContainerRef} className="col-xl-8 p-0" style={{ position: "relative", height: "100%" }}>
                {doorConfigurationBuilder.configuration && (
                  <Scene 
                    drawRealWidth={toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo) + (4 * PROFONDITA_INVOLUCRO)} 
                    drawRealHeight={toDrawUnit(doorConfigurationBuilder.configuration.altezzaForo) + (2 * PROFONDITA_INVOLUCRO)} 
                    drawXOffset={(toDrawUnit(doorConfigurationBuilder.configuration.larghezzaForo) + (4 * PROFONDITA_INVOLUCRO)) / 2}
                    drawYOffset={(PROFONDITA_INVOLUCRO)} 
                    panEnabled 
                    zoomEnabled>
                    <GarageExternal colors={doorConfigurationBuilder.colors} options={doorConfigurationBuilder.options} />
                  </Scene>
                )}
                <MetricsSummary />
            </div>
            <div className="col-xl-4 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc" }}>                
                <div className="p-4">                        
                    <FormModelloFinitura options={doorConfigurationBuilder.options} colors={doorConfigurationBuilder.colors} />

                    <div className="d-flex flex-row-reverse justify-content-between mt-3">
                        <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                            <span>Avanti</span>
                            <NavigateNextIcon />
                        </Button>

                        <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                            navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/dimensioni`);
                        }}>
                            <NavigateBeforeIcon />
                            <span>Indietro</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}