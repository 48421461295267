import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  FormControl,
  Select,
  Alert,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import img from "../../assets/img/fotoPort.png";
import box from "../../assets/img/box.PNG";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DialogScroll from "../../components/Dialog/DialogScroll";
import { importBaseActions } from "../../store/reducers/base";
import { useCallback } from "react";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { AccessoriesEnum } from './../../core/AccessoriesEnum';
import { getProductName } from "../../Utility";
const platinbronze =
  "https://media.deltaoverlap.com/public/img/maniglie/Platinbronze.png";
const platinsilver =
  "https://media.deltaoverlap.com/public/img/maniglie/Platinsilver.png";

const Maniglierie = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [checkColor1, setCheckColor1] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [sbloccoObb, setSbloccoObb] = useState(false);
  const [stepState, setStepState] = useState({
    state: "loading", 
    steps: [], 
    accessories: null,
    verniciatura: null,
    verniciaturaSku: null,
    customRal: null,
    colors: null,
    imgRendering: null,
    currentStep: null,
  });

  const eventBroadcast = useEventBroadcast();

  const handleChangeVerniciatura = async (idVer) => { 
    debugger;
    setErrorVincolo(null);
    try {
      let { id, sku } = stepState.accessories.find(a => a.id == idVer);

      dispatch(baseActions.setIdVerniciaturaManiglia(id));
      eventBroadcast.dispatch(Events.configurationChanged,{
        ...state,
        idVerniciaturaManiglia: id
      });

      setStepState({...stepState, verniciatura: id, verniciaturaSku: sku});
      //setVerniciatura(event.target.value);
    } catch (e) {
      setStepState({...stepState, state: "error", error: e});
    }
  };

  const handleChangeCheckbox = (event) => {
    setErrorVincolo(null);
    dispatch(baseActions.setRadioM(event.target.value));
    //setSelect(event.target.value);

    if (Number(event.target.value) == 1) {      
      dispatch(baseActions.setRadioM(1));
      let manigliaDef = setManigliaDefault(stepState.accessories);
      handleChangeVerniciatura(manigliaDef.id);
    } else {
      setStepState({...stepState, verniciatura: null, verniciaturaSku: null});

      dispatch(baseActions.setIdVerniciaturaManiglia(event.target.value));
      eventBroadcast.dispatch(Events.configurationChanged,{
        ...state,
        idVerniciaturaManiglia: null
      });

      setErrorVincolo(null);
    }

    
  };

  const saveStepState = async () => {
    if (!state.selectedD) {
      if (!state.radioM || state.radioM <= 0) {
        setErrorVincolo("Selezionare il tipo di maniglia");
        setStepState({...stepState, state: "idle"});
        return;
      }

      if (state.radioM == 1 && (!stepState.verniciatura || stepState.verniciatura <= 1)) {
        setErrorVincolo("Selezionare il tipo di verniciatura");
        setStepState({...stepState, state: "idle"});
        return;
      }

      if (stepState.verniciaturaSku == AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal && !stepState.customRal) {
        setErrorVincolo("Fornire il colore di verniciatura della maniglia");
        setStepState({...stepState, state: "idle"});
        return;
      }
    }

    let sendData = { 
      qty: 1,
      id: state.radioM === 158 || state.radioM === 0 ? state.radioM : stepState.verniciatura,
      quote: state.quoteId,
      slug: location.pathname.substring(1),
      colorVerniciatura: state.radioM === 158 || state.radioM === 0 ? state.radioM : stepState.customRal,
    };

    setStepState({...stepState, state: "save"});
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, sendData, axiosConfig);      

      if (res.data.success == 1) {
        if (res.data.data.error) {
          setErrorVincolo(res.data.data.error);
          setStepState({...stepState, state: "idle"});
        } else if (state.radioM === 0 && state.selectedD === false) {
          setSbloccoObb(true);
          setStepState({...stepState, state: "idle"});
        } else {
          dispatch(baseActions.setManiglierie(true));
          stepState.steps.splice(5, 1);
          stepState.steps &&
          stepState.steps.map(
              (el, index) =>
                `/${el.slug}` === location.pathname &&
                navigate(
                  `/${stepState.steps[index + 1] && stepState.steps[index + 1].slug}`,
                  {
                    id: state.idPorta,
                  }
                )
            );
        }
      }
    } catch (e) {
      setStepState({...stepState, state: "idle"});
      console.log(e);
    }    
  }

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const load = async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId}, axiosConfig);      
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }      
    } catch (e) {
      
    }

    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/accessories`, {
          id: state.idPorta,
          quote: state.quoteId,
          step: location.pathname.substring(1),
        }, axiosConfig);

        let accessories = res.data.data;
                
        let colors = [];
        // try {
        //   res = await axios.post(`${process.env.REACT_APP_API_URL}/color-ral`, { 
        //     category: state.idPorta,
        //     quote_id: state.quoteId,
        //   }, axiosConfig);

        //   colors = res.data.data;
        // } catch (e) {
        //   console.log(e);
        // }
        
        let idVerniciatura = null;
        let skuVerniciatura = null;
        let customRal = null;
        accessories.forEach((a) => {
          if (a.id === a.selected) {
            if (a.sku == AccessoriesEnum.ManigliaPlatinSilver || a.sku == AccessoriesEnum.ManigliaPlatinBronze || a.sku == AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal) {
              dispatch(baseActions.setRadioM(1));
              idVerniciatura = a.id;  
              skuVerniciatura = a.sku; 
              customRal = a.custom_ral;
              return null;
            } else if (a.id === a.selected && a.selected === 158) {
              dispatch(baseActions.setRadioM(158));
              return null;
            }
          }
        });

        res = await axios.post(`${process.env.REACT_APP_API_URL}/get-img-accessories`, {
          id: state.radioM === 1 ? state.radioM : stepState.verniciatura,
        }, axiosConfig);

        let imgRendering = res.data.data;

        setStepState({
          state: "idle",
          steps: steps, 
          accessories: accessories,
          verniciatura: idVerniciatura,
          verniciaturaSku: skuVerniciatura,
          customRal: customRal,
          colors: colors,
          imgRendering: imgRendering,
          currentStep: currentStep,
        });
    } catch (e) {
      setStepState({state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const setCustomRal = (code) => {
    setStepState({...stepState, customRal: code});
  }

  const setManigliaDefault = (accessories) => {
    let manigliaDef = accessories.find((a) => a.sku == AccessoriesEnum.ManigliaPlatinBronze);
    return manigliaDef;
  }

  useEffect(() => {
    load();
  }, []);

  const [value, setValue] = useState();
  const [errorVincolo, setErrorVincolo] = useState(null);
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  if ("loading" == stepState.state) return <Loading />;
  if ("error" == stepState.state) {
    console.log(stepState.error);
    return <div>failed to load: {stepState.error.message}</div>; 
  }

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
          </Typography>
        </Grid>
        <div>
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
        
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {stepState.currentStep && <TabsCustom
            value={stepState.currentStep.id}
            setValue={setValue}
            id={state.idPorta}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
        {stepState.accessories && (
          <GridPreventivi
            header={null
              // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
              //   <div className="col-span-4 text-center">
              //     <Typography variant={"h4"} fontWeight={700} mt={5}>
              //       Maniglierie e sblocco esterno
              //     </Typography>
              //   </div>
              // </div>
            }
            dataSend={{
              quote_id: state.quoteId,
              category_id: state.idPorta,
              slug: location.pathname.substring(1),
            }}
            imgRendering={stepState.imgRendering}
            axiosConfig={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
            dipendenze={stepState.verniciatura + state.radioM}>              
            <div className={"grid grid-cols-4 mt-3"}>
              <div className="col-start-2 col-span-2">
                <Typography color={"primary"} sx={{ lineHeight: 0, marginY: 2 }}>
                  Maniglie
                </Typography>
                <FormControl fullWidth>
                  <Select value={state.radioM} onChange={handleChangeCheckbox}>
                    {state.selectedD === true && (
                      <MenuItem value={0}>Nessuna Maniglia</MenuItem>
                    )}
                    <MenuItem value={1}>Doppia maniglia</MenuItem>
                    <MenuItem value={158}>Sblocco manuale</MenuItem>
                  </Select>
                </FormControl>
                {state.radioM === 1 && (
                  <>
                    <Typography
                      color={"primary"}
                      sx={{ lineHeight: 0, marginTop: 6, marginBottom: 1 }}>
                      Verniciatura maniglia
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyItems: "center",
                        justifyContent: "center",
                      }}>
                      <FormControl fullWidth>
                        <Select
                          onChange={(e) => handleChangeVerniciatura(e.target.value)}
                          value={stepState.verniciatura ?? ""}>
                          <MenuItem value={0} sx={{ display: "none" }}>
                            Inserisci la Verniciatura
                          </MenuItem>
                          {stepState.accessories.map(
                            (res) =>
                              res.type === "radio" &&
                              (res.sku === "ACCM31" ||
                                res.sku === "ACCM32" ||
                                res.sku === "ACCM01") && (
                                <MenuItem key={res.id} value={res.id}>
                                  {res.name}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                      <img
                        style={{
                          width: 90,
                          height: 70,
                          padding: 2,
                          marginLeft: 6,
                          cursor: "pointer",
                        }}
                        src={
                          stepState.verniciatura === 29
                            ? platinbronze
                            : stepState.verniciatura === 31 || stepState.verniciatura === 30
                            ? platinsilver
                            : box
                        }
                        alt={"imgLeft"}
                      />
                    </Box>
                  </>
                )}
                {AccessoriesEnum.DoppiaManigliaVerniciataSceltaRal == stepState.verniciaturaSku && (
                  <>
                    <Typography
                      color={"primary"}
                      sx={{ lineHeight: 0, marginTop: 6 }}>
                      Colore verniciatura maniglia
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <TextField
                        sx={{ mt: 2 }}
                        onChange={(e) => setCustomRal(e.target.value)}
                        fullWidth
                        placeholder={"es. 8016"}
                        margin="dense"
                        type="text"
                        variant="outlined"
                        name={"customRal"}
                        value={stepState.customRal}
                      />
                    </Box>
                  </>
                )}
              </div>
            </div>
            {errorVincolo && (
              <div className={"grid grid-cols-4 gap-2 mt-3"}>
                <div className="col-start-2 col-span-2">
                  <Alert severity="error">{errorVincolo}</Alert>
                </div>
              </div>
            )}
            {sbloccoObb && (
              <Alert severity="error">Inserire un opzione per continuare</Alert>
            )}

            <div className={"grid grid-cols-4 gap-2 mb-[50px] mt-3"}>
              <div className="col-start-2">
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    py: 1,
                  }}
                  onClick={() => {
                    stepState.steps &&
                    stepState.steps.map((el, index) => {
                        `/${el.slug}` === location.pathname &&
                          navigate(
                            `/${stepState.steps[index - 1] && stepState.steps[index - 1].slug}`,
                            {
                              id: state.idPorta,
                            }
                          );
                      });
                  }}>
                  INDIETRO
                </Button>
              </div>
              <div>
                {"save" == stepState.state ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    sx={{ color: "white", fontWeight: "bold", py: 1 }}
                    variant={"contained"}
                    onClick={() => saveStepState()}>
                    AVANTI
                  </Button>
                )}
              </div>
            </div>
          </GridPreventivi>
        )}
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Maniglierie;
