import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import React, { createContext, FC, PropsWithChildren, ReactNode, useContext, useState } from "react";
import { EditableMeasureProps } from "../routes/configurations/door/builder/components/object-draw/editable_measure";
import { MeasureEditorDialog } from "../ui/dialogs/measure_editor_dialog";
import { ColorsDialog, ColorsProps } from "../ui/dialogs/colors_dialog";

type DialogsContextData = {
    editMeasure: (measureEditorDialog: EditableMeasureProps | null) => void,
    selectColor: (colorsData: ColorsProps | null) => void,
    showError: (title: string, message: string) => void,
    showWarning: (title: string, message: string) => void,
    showConfirm: (title: string, message: string) => void
}

const DialogsContext = createContext<DialogsContextData | null>(null);

const DialogsProvider: FC<{children?: ReactNode}> = ({ children }) => {
    const [measureEditorDialog, setMeasureEditorDialog] = useState<EditableMeasureProps | null>(null);
    const [colorsDialog, setColorsDialog] = useState<ColorsProps | null>(null);
    const [errorDialog, setErrorDialog] = useState<{title: string, message: string} | null>(null);
    const [warningDialog, setWarningDialog] = useState<{title: string, message: string} | null>(null);
    const [confirmDialog, setConfirmDialog] = useState<{title: string, message: string} | null>(null);

    const editMeasure = (measureEditorDialog: EditableMeasureProps | null) => {
        setMeasureEditorDialog(measureEditorDialog);
    }

    const selectColor = (colorsData: ColorsProps | null) => {
        setColorsDialog(colorsData);
    }

    const showError = (title: string, message: string) => {
        setErrorDialog({title, message});
    }

    const showWarning = (title: string, message: string) => {
        setWarningDialog({title, message});
    }

    const showConfirm = (title: string, message: string) => {
        setConfirmDialog({title, message});
    }

    return (
        <DialogsContext.Provider value={{editMeasure, selectColor, showError, showWarning, showConfirm}}>
            {children}

            {measureEditorDialog && <MeasureEditorDialog data={measureEditorDialog} onConfirm={(value) => {
                let res = measureEditorDialog.onConfirm(value);
                if (!res.isValid) return res;

                setMeasureEditorDialog(null);

                return res;
            }} onCancel={() => setMeasureEditorDialog(null)} />}

            {colorsDialog && <ColorsDialog data={colorsDialog} onConfirm={(value) => {
                colorsDialog.onConfirm(value);
                
                setColorsDialog(null);
            }} onCancel={() => setColorsDialog(null)} />}

            {errorDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="error" sx={{zIndex: "1500"}} onClose={() => setErrorDialog(null)}>
                        <AlertTitle>{errorDialog.title}</AlertTitle>
                        {errorDialog.message}
                    </Alert>
                </div>
            )}

            {warningDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="warning" sx={{zIndex: "1500"}} onClose={() => setWarningDialog(null)}>
                        <AlertTitle>{warningDialog.title}</AlertTitle>
                        {warningDialog.message}
                    </Alert>
                </div>
            )}

            {confirmDialog && (
                <div className="d-flex justify-content-center py-3" style={{position: "fixed", left: "0", bottom: "0", width: "100%"}}>
                    <Alert variant="filled" severity="success" sx={{zIndex: "1500"}} onClose={() => setConfirmDialog(null)}>
                        <AlertTitle>{confirmDialog.title}</AlertTitle>
                        {confirmDialog.message}
                    </Alert>
                </div>
            )}
        </DialogsContext.Provider>
    );
}

export default DialogsProvider;

export const useDialogs = () => {
    return useContext(DialogsContext);
}