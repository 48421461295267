import React, { FC, useEffect, useState } from "react";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { useNavigate, useParams } from "react-router-dom";
import { useDialogs } from "../../../../providers/dialogs_provider";
import { getCurrentCustomer, getCurrentUser } from "../../../../core/common_functions";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { DoorConfigurationConditions } from "../../../../models/door_configuration";
import { useConfigurationsService } from "../../../../services/configurations_service";
import Loading from "../../../../../components/Loading/loading";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useFormik } from "formik";
import { NullableString } from "../../../../core/common_types";

export const ConditionsPage = () => {
  const { base, doorConfigurationBuilder } = useStoreSelector(store => store);
  const navigate = useNavigate();
  const { id } = useParams();
  const dialogs = useDialogs();
  const dispatcher = useStoreDispatch();
  const currentUser = getCurrentUser(base);
  const currentCustomer = getCurrentCustomer(base);
  const { setStepActive, setStepComplete } = useDoorConfigurationBuilderActions();
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const configurationService = useConfigurationsService();
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  const loadConditions = async () => {
    try {
      if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

      let conditions = await configurationService.getCondizioni(currentUser, doorConfigurationBuilder.configuration.configurationId);

      if (!conditions) throw "invalid conditions";

      formik.setValues(conditions);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  const submit = async () => {
    setSubmit(true);
    try {
      if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

      await configurationService.setCondizioni(currentUser, doorConfigurationBuilder.configuration.configurationId, formik.values);

      dispatcher(setStepComplete({ stepSlug: "condizioni", isComplete: true }));

      navigate(`/door-configurations/${id}/preventivo`);
    } catch (e: any) {
      setSubmit(false);

      if (typeof e == "string") {
        dialogs?.showError("Attenzione...", e);
      } else {
        if (e.severity == "error") {
          dialogs?.showError("Attenzione...", e.message);
        } else if (e.severity == "warning") {
          dialogs?.showWarning("Attenzione...", e.message);
        }
      }
    }
  }

  const formik = useFormik<DoorConfigurationConditions>({
    initialValues: {
      customerId: undefined,
      customClientName: undefined,
      customClientAddress: undefined,
      customClientZipCode: undefined,
      customClientCity: undefined,
      endClientTaxRate: 0,
      endClientDownPaymentValue: 0,
      endClientDownPaymentType: "percentage",
      endClientDiscount1: 0,
      endClientDiscount2: 0,
      endClientNet: 0,
      endClientTaxableAmount: 0,
      endClientTotalTaxes: 0,
      endClientTotalDiscount: 0,
      endClientTotal: 0,
      retailTaxRate: 0,
      retailDownPaymentType: "percentage",
      retailDownPaymentValue: 0,
      retailDiscount1: 0,
      retailDiscount2: 0,
      retailNet: 0,
      retailTaxableAmount: 0,
      retailTotalTaxes: 0,
      retailTotalDiscount: 0,
      retailTotal: 0,
      baseNetPrice: 0,
      totalServices: 0,
      quantity: 0,
      notes: undefined
    },
    onSubmit: async (model) => {
      setRefresh(true);
      try {
        if (!doorConfigurationBuilder.configuration) throw "invalid configuration";

        let conditions = await configurationService.aggiornaCondizioni(currentUser, doorConfigurationBuilder.configuration?.configurationId, model);

        formik.setValues(conditions);

        setRefresh(false);
      } catch (e: any) {
        setRefresh(false);

        if (typeof e == "string") {
          dialogs?.showError("Attenzione...", e);
        } else {
          if (e.severity == "error") {
            dialogs?.showError("Attenzione...", e.message);
          } else if (e.severity == "warning") {
            dialogs?.showWarning("Attenzione...", e.message);
          }
        }
      }
    }
  });

  const applyDiscount = async () => {
    formik.submitForm();
  }

  const buildEditFormServiceCustomer = () => {
    return (
      <>
        <h6 className="mt-4 mb-0"><strong>Dati cliente</strong></h6>
        <div className="row">
          <div className="col-12 mt-3">
            <TextField
              fullWidth
              label="Denominazione cliente*"
              variant="outlined"
              value={formik.values.customClientName}
              onChange={(e) => formik.setFieldValue("customClientName", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-12 mt-3">
            <TextField
              fullWidth
              label="Indirizzo"
              variant="outlined"
              value={formik.values.customClientAddress}
              onChange={(e) => formik.setFieldValue("customClientAddress", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label="C.A.P."
              variant="outlined"
              value={formik.values.customClientZipCode}
              onChange={(e) => formik.setFieldValue("customClientZipCode", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-xl-6 mt-3">
            <TextField
              fullWidth
              label="Città"
              variant="outlined"
              value={formik.values.customClientCity}
              onChange={(e) => formik.setFieldValue("customClientCity", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>
        </div>

        <h6 className="mt-4 mb-0"><strong>Condizioni</strong></h6>
        <div className="row mt-3">
          <div className="col-xl-3">
            <FormControl fullWidth>
              <InputLabel id="retailTaxRate">Aliquota IVA</InputLabel>
              <Select
                labelId="retailTaxRate"
                value={formik.values.retailTaxRate}
                label="Aliquota IVA"
                onChange={(e) => {
                  formik.setFieldValue("retailTaxRate", e.target.value);
                }}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={22}>22</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3">
            <FormControl fullWidth>
              <InputLabel id="retailDownPaymentType">Tipo di acconto</InputLabel>
              <Select
                labelId="retailDownPaymentType"
                value={formik.values.retailDownPaymentType}
                label="Tipo di acconto"
                onChange={(e) => {
                  formik.setFieldValue("retailDownPaymentType", e.target.value);
                }}>
                <MenuItem value={"percentage"}>Percentuale</MenuItem>
                <MenuItem value={"amount"}>Importo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3">
            <TextField
              label="Acconto"
              variant="outlined"
              type="number"
              value={formik.values.retailDownPaymentValue}
              onChange={(e) => formik.setFieldValue("retailDownPaymentValue", e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-3">
            <TextField
              fullWidth
              label="Sconto"
              variant="outlined"
              type="number"
              value={formik.values.retailDiscount1}
              onChange={(e) => formik.setFieldValue("retailDiscount1", e.target.value)}
            />
          </div>

          <div className="col-xl-3">
            <TextField
              fullWidth
              label=""
              variant="outlined"
              type="number"
              value={formik.values.retailDiscount2}
              onChange={(e) => formik.setFieldValue("retailDiscount2", e.target.value)}
            />
          </div>
        </div>

        {/*"RETAIL_DISCOUNT_ERROR" == data.discountError && (
          <div className="row mt-3">
            <div className="col-12">
              <Alert className="mt-2" severity="error" onClose={() => setDiscountError(false)}>Sconto non applicabile</Alert>
            </div>
          </div>
        )*/}
      </>
    );
  }

  const buildEditFormClienteFinale = () => {
    return (
      <>
        <h6 className="mt-4 mb-0"><strong>Dati cliente</strong></h6>
        <div className="row">
          <div className="col-12 mt-3">
            <TextField
              fullWidth
              label="Denominazione cliente*"
              variant="outlined"
              value={formik.values.customClientName}
              onChange={(e) => formik.setFieldValue("customClientName", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-12 mt-3">
            <TextField
              fullWidth
              label="Indirizzo"
              variant="outlined"
              value={formik.values.customClientAddress}
              onChange={(e) => formik.setFieldValue("customClientAddress", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label="C.A.P."
              variant="outlined"
              value={formik.values.customClientZipCode}
              onChange={(e) => formik.setFieldValue("customClientZipCode", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>

          <div className="col-xl-6 mt-3">
            <TextField
              fullWidth
              label="Città"
              variant="outlined"
              value={formik.values.customClientCity}
              onChange={(e) => formik.setFieldValue("customClientCity", e.target.value)}
            // error={erroreQta != null}
            // helperText={erroreQta}
            />
          </div>
        </div>

        <h6 className="mt-4 mb-0"><strong>Condizioni</strong></h6>
        <div className="row">
          <div className="col-xl-3 mt-3">
            <FormControl fullWidth>
              <InputLabel id="endClientTaxRate">Aliquota IVA</InputLabel>
              <Select
                labelId="endClientTaxRate"
                value={formik.values.endClientTaxRate}
                label="Aliquota IVA"
                onChange={(e) => {
                  formik.setFieldValue("endClientTaxRate", e.target.value);
                }}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={22}>22</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3 mt-3">
            <FormControl fullWidth>
              <InputLabel id="endClientDownPaymentType">Tipo di acconto</InputLabel>
              <Select
                labelId="endClientDownPaymentType"
                value={formik.values.endClientDownPaymentType}
                label="Tipo di acconto"
                onChange={(e) => {
                  formik.setFieldValue("endClientDownPaymentType", e.target.value);
                }}>
                <MenuItem value={"percentage"}>Percentuale</MenuItem>
                <MenuItem value={"amount"}>Importo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label="Acconto"
              variant="outlined"
              type="number"
              value={formik.values.endClientDownPaymentValue}
              onChange={(e) => formik.setFieldValue("endClientDownPaymentValue", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label="Sconto"
              variant="outlined"
              type="number"
              value={formik.values.endClientDiscount1}
              onChange={(e) => formik.setFieldValue("endClientDiscount1", e.target.value)}
            />
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label=""
              variant="outlined"
              type="number"
              value={formik.values.endClientDiscount2}
              onChange={(e) => formik.setFieldValue("endClientDiscount2", e.target.value)}
            />
          </div>
        </div>

        {/*"END_CLIENT_DISCOUNT_ERROR" == data.discountError && (
          <div className="row mt-3">
            <div className="col-12">
              <Alert className="mt-2" severity="error" onClose={() => setDiscountError(false)}>Sconto non applicabile</Alert>
            </div>
          </div>
        )*/}
      </>
    );
  }

  const buildEditFormRivendita = () => {
    return (
      <>
        <div className="row">
          <div className="col-xl-3 mt-3">
            <FormControl fullWidth>
              <InputLabel id="retailTaxRate">Aliquota IVA</InputLabel>
              <Select
                labelId="retailTaxRate"
                value={formik.values.retailTaxRate}
                label="Aliquota IVA"
                inputProps={{
                  readOnly: true
                }}
                onChange={(e) => {
                  formik.setFieldValue("retailTaxRate", e.target.value);
                }}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={22}>22</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3 mt-3">
            <FormControl fullWidth>
              <InputLabel id="retailDownPaymentType">Tipo di acconto</InputLabel>
              <Select
                labelId="retailDownPaymentType"
                value={formik.values.retailDownPaymentType}
                label="Tipo di acconto"
                inputProps={{
                  readOnly: true
                }}
                onChange={(e) => {
                  formik.setFieldValue("retailDownPaymentType", e.target.value);
                }}>
                <MenuItem value={"percentage"}>Percentuale</MenuItem>
                <MenuItem value={"amount"}>Importo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              label="Acconto"
              variant="outlined"
              type="number"
              value={formik.values.retailDownPaymentValue}
              inputProps={{
                readOnly: true
              }}
              onChange={(e) => formik.setFieldValue("retailDownPaymentValue", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label="Sconto"
              variant="outlined"
              type="number"
              value={formik.values.retailDiscount1}
              inputProps={{
                readOnly: true
              }}
              onChange={(e) => formik.setFieldValue("retailDiscount1", e.target.value)}
            />
          </div>

          <div className="col-xl-3 mt-3">
            <TextField
              fullWidth
              label=""
              variant="outlined"
              type="number"
              value={formik.values.retailDiscount2}
              inputProps={{
                readOnly: true
              }}
              onChange={(e) => formik.setFieldValue("retailDiscount2", e.target.value)}
            />
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    if (!id || isNaN(Number(id)) || !doorConfigurationBuilder.configuration) {
      navigate("/configurations");
    } else {
      dispatcher(setStepActive({ stepSlug: "condizioni", isActive: true }));

      loadConditions();
    }
  }, []);

  return (
    <>
      <div className="container h-full">
        <div className="row m-0 h-full">
          <div className="col-xl-8 p-0" style={{ overflowY: "auto", height: "100%" }}>
            <div className="p-4">
              <h5 className="text-primary mb-3">
                Condizioni di vendita
                {process.env.NODE_ENV !== "production" && currentUser.userType == "Retail" && <label>- Cliente finale</label>}
                {process.env.NODE_ENV !== "production" && currentUser.userType != "Retail" && <label>- Rivendita</label>}
              </h5>

              {currentUser.userType == "Retail" && (
                <div>
                  {buildEditFormClienteFinale()}
                  {process.env.NODE_ENV !== "production" && <hr />}
                </div>
              )}

              {currentUser.userType != "Retail" && (
                <div>
                  {currentCustomer?.isServiceCustomer && buildEditFormServiceCustomer()}
                  {!currentCustomer?.isServiceCustomer && buildEditFormRivendita()}
                </div>
              )}

              <h6 className="mt-4 mb-0"><strong>Note aggiuntive</strong></h6>
              <TextField
                label=""
                variant="outlined"
                className="mt-2 w-full"
                type="text"
                multiline
                value={formik.values.notes}
                onChange={(e) => formik.setFieldValue("notes", e.target.value)}
              />

              {(currentUser.userType == "Retail" || currentCustomer?.isServiceCustomer) && <div className="row flex-row-reverse mt-3">
                <div className="col-xl-3 text-end">
                  <Button disabled={refresh} variant="outlined" onClick={() => applyDiscount()}>AGGIORNA IMPORTI</Button>
                </div>
              </div>}
            </div>
          </div>

          <div className="col-xl-4 p-0" style={{ overflowY: "auto", height: "100%", borderLeft: "solid 1px #ccc", position: "relative" }}>
            <div className="p-4">
              <h5 className="text-primary">Totali</h5>

              <div className="mt-1 rounded-3 p-3 bg-light " style={{ position: "relative" }}>
                <div className="d-flex justify-content-between">
                  <NumberItem label="Quantità:" value={formik.values.quantity} />
                </div>

                <div className="d-flex justify-content-between">
                  <AmountItem label="Totale merce:" value={formik.values.baseNetPrice} />
                </div>

                <div className="d-flex justify-content-between">
                  <AmountItem label="Totale servizi:" value={formik.values.totalServices} />
                </div>

                {currentUser.userType != "Retail" && (
                  <>
                    <div className="my-1 text-center" style={{ lineHeight: "1" }}>
                      <hr />
                      {process.env.NODE_ENV != "production" && <small>Rivendita</small>}
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Sconto:" value={-(formik.values.retailTotalDiscount ?? 0)} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale merce netto:" value={formik.values.retailNet} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale imponibile:" value={formik.values.retailTaxableAmount} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale iva:" value={formik.values.retailTotalTaxes} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale ordine:" value={formik.values.retailTotal} />
                    </div>
                  </>
                )}

                {currentUser.userType == "Retail" /*&& data.endClientSummary*/ && (
                  <>
                    <div className="my-1 text-center" style={{ lineHeight: "1" }}>
                      <hr />
                      {process.env.NODE_ENV != "production" && <small>Cliente finale</small>}
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Sconto:" value={-(formik.values.endClientTotalDiscount ?? 0)} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale merce netto:" value={formik.values.endClientNet} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale imponibile:" value={formik.values.endClientTaxableAmount} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale iva:" value={formik.values.endClientTotalTaxes} />
                    </div>

                    <div className="d-flex justify-content-between">
                      <AmountItem label="Totale ordine:" value={formik.values.endClientTotal} />
                    </div>
                  </>
                )}
              </div>


              {!loading && <div className="d-flex flex-row-reverse justify-content-between mt-3">
                <Button disabled={isSubmit} variant="contained" className="ps-3 pe-1" onClick={() => submit()}>
                  <span>Genera preventivo</span>
                  <NavigateNextIcon />
                </Button>

                <Button variant="contained" className="ps-1 pe-3" color="secondary" onClick={() => {
                  navigate(`/door-configurations/${doorConfigurationBuilder.configuration?.configurationId}/aspetto`);
                }}>
                  <NavigateBeforeIcon />
                  <span>Indietro</span>
                </Button>
              </div>}
            </div>

            {refresh && <div className="d-flex align-items-center justify-content-center" style={{
              position: "absolute", height: "100%", width: "100%", top: "0", left: "0",
              backgroundColor: "rgba(255,255,255,0.5)"
            }}>
              <CircularProgress />
            </div>}
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </>
  );
}

export const AmountItem: FC<any> = (props) => {
  if (!props) return <span>ERRORE</span>;

  return (
    <>
      <h6 className="h6">{props.label}</h6>
      <h6 className="h6 text-primary">
        {Number(props.value)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")} &euro;
      </h6>
    </>
  );
}

export const NumberItem: FC<any> = (props) => {
  return (
    <>
      <h6 className="h6">{props.label}</h6>
      <h6 className="h6 text-primary">
        {Number(props.value).toFixed(props.decimal || 0)}
      </h6>
    </>
  );
}