import Konva from "konva";
import React, { FC, forwardRef, ReactNode, Ref, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { Stage } from "react-konva";
import { useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { NullableString } from "../../../../../../core/common_types";

export type SceneArgs = {
    getPreview(): NullableString
}

export type SceneProps = {
    drawRealWidth: number, 
    drawRealHeight: number, 
    drawXOffset: number,
    drawYOffset: number, 
    panEnabled?: boolean, 
    zoomEnabled?: boolean, 
    children?: ReactNode
}

export type SceneTypeRef = React.ElementRef<typeof Scene>;

export const Scene = forwardRef((props: SceneProps, ref: Ref<SceneArgs>) => {
    const {drawRealHeight,drawRealWidth,drawXOffset,drawYOffset,children,panEnabled,zoomEnabled} = props;
    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const stageRef = useRef<Konva.Stage>(null);
    const stageContainerRef = useRef<HTMLDivElement>(null);

    const [scene, setScene] = useState<{
        scale: number,
        posX: number,
        posY: number,
        larghezza: number,
        altezza: number
    }>({
        scale: 1,
        posX: 0,
        posY: 0,
        larghezza: 0,
        altezza: 0
    });

    const applyZoom = (direction: number) => {
        if (!stageRef.current) return;

        var oldScale = stageRef.current.scaleX();

        let ptCenterX: number = stageRef.current.width() / 2;
        let ptCenterY: number = stageRef.current.height() / 2;

        var scaleBy = 1.05;

        var mousePointTo = {
            x: (ptCenterX - stageRef.current.x()) / oldScale,
            y: (ptCenterY - stageRef.current.y()) / oldScale,
        };

        var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        var newPos = {
            x: ptCenterX - mousePointTo.x * newScale,
            y: ptCenterY - mousePointTo.y * newScale,
        };

        setScene({ ...scene, scale: newScale, posX: newPos.x, posY: newPos.y });
    }

    const zoomIn = () => {
        applyZoom(1);
    }

    const zoomOut = () => {
        applyZoom(-1);
    }

    const onWheel = (evt: Konva.KonvaEventObject<WheelEvent>) => {
        if (!zoomEnabled) return;

        if (evt.evt.deltaY < 0) {
            zoomIn();
        } else {
            zoomOut();
        }
    }

    const resizeStageContainer = () => {
        if (!stageContainerRef.current || !stageRef.current || !doorConfigurationBuilder.configuration) return;

        let definedWidth = stageContainerRef.current.clientWidth;
        let definedHeight = stageContainerRef.current.clientHeight;
        
        var panel_ratio = definedWidth / definedHeight;
        var image_ratio = drawRealWidth / drawRealHeight;

        let scale =  panel_ratio > image_ratio ? definedHeight / drawRealHeight : definedWidth / drawRealWidth;
        let ptCenterX: number = drawXOffset;
        let ptCenterY: number = drawYOffset;
        
        let ptxScaled: number = ptCenterX * scale;
        let ptyScaled: number = ptCenterY * scale;
        
        setScene({ ...scene, larghezza: definedWidth, altezza: definedHeight, scale: scale, posX: ptxScaled, posY: ptyScaled});

        window.addEventListener("resize", resizeStageContainer);
    }

    // function downloadURI(uri, name) {
    //     var link = document.createElement('a');
    //     link.download = name;
    //     link.href = uri;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     delete link;
    //   }

    useLayoutEffect(() => {
        resizeStageContainer();
    }, []);

    useImperativeHandle(ref, () => ({
        getPreview() {
            let res = stageRef.current?.toDataURL({ pixelRatio: 2 });
            debugger
            return res;
        }
    }));

    return (
        <div ref={stageContainerRef} className="h-full" style={{backgroundImage: "url(/img/bg-draw.png)"}}>
            <Stage
                ref={stageRef}
                draggable={panEnabled}              
                width={scene.larghezza}
                height={scene.altezza}
                scale={{ x: scene.scale, y: scene.scale }}
                x={scene.posX}
                y={scene.posY}                                
                onWheel={(e) => onWheel(e)}>
                {children}
            </Stage>
        </div>
    );
});