import axios from "axios";
import { CollectionRequestResult, NullableNumber, GenericRequestResult } from "../core/common_types"
import { Customer } from "../models/customer"
import { error, getAxiosConfig } from "../core/common_functions";
import { User } from "../models/user";

export const useCustomersApi = () => {
    return {
        async search(currentUser: User, searchText: string, page: number, pageSize: number): Promise<CollectionRequestResult<Customer>> {
            try {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/customers/search?count=${pageSize}&page=${page}`, {
                    searchText
                }, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        },
        async findServiceCustomerId(currentUser: User, parentId: number): Promise<GenericRequestResult<NullableNumber>> {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/find-service-customer-id?parentId=${parentId}`, getAxiosConfig(currentUser));

                return response.data;
            } catch (e: any) {
                throw error(e);
            }
        }
    }
}