import "./assets/css/index.css";
import "./assets/css/App.css";

import React from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import { BrowserRouter, Route, Link, HashRouter, Routes } from "react-router-dom";
import Login from "./Screen/Auth/login";
import { theme } from "./assets/conf/theme";
import { ThemeProvider } from "@mui/material/styles";
import { RecuperaPass } from "./Screen/Auth/RecuperaPass";
import { RecuperaSuccess } from "./Screen/Auth/RecuperaSuccess";
import CartellaAgente from "./Screen/Admin/cartellaAgente";
import Clienti from "./Screen/User/clienti";
import NewPreventivo from "./Screen/User/newPreventivo";
import CartellaPreventivi from "./Screen/User/cartellaPreventivi";
import Porte from "./Screen/User/Porte";
import ClientiArchiviati from "./Screen/User/clientiArchiviati";
import Dimensioni from "./Screen/Preventivo/dimensioni";
import Aspetto from "./Screen/Preventivo/aspetto";
import Accessori from "./Screen/Preventivo/accessori";
import Richiesta from "./Screen/Preventivo/richiesta";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GeneraPDF from "./Screen/Preventivo/generePDF";
import { ResetPassword } from "./Screen/Auth/resetPassword";
import Agenti from "./Screen/Admin/agenti";
import Maniglierie from "./Screen/Preventivo/maniglierie";
import Finestrature from "./Screen/Preventivo/finestrature";
import Ventilazione from "./Screen/Preventivo/ventilazione";
import ElementiFissi from "./Screen/Preventivo/elementiFissi";
import PortinaPedonaleAffiancata from "./Screen/Preventivo/portinaPedonaleAffiancata";
import PortinaPedonaleIntegrata from "./Screen/Preventivo/portinaPedonaleIntegrata";
import store, { persistor } from "./store";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import AreaManagers from "./Screen/Admin/areaManagers";
import { ModificaPassword } from "./Screen/Auth/ModificaPassword";
import { CustomersRoutes } from "./v2/routes/customers/customers_routes";
import { DoorConfigurationsRoutes } from "./v2/routes/configurations/door/configurations_routes";

i18next.init({
  lng: "it",
  resources: {
    it: {
      common: common_it
    }
  }
});

export default function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route index element={<Login />} />
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/recupera-pass" element={<RecuperaPass />} />
                  <Route path="/modifica-password" element={<ModificaPassword />} />
                  <Route path="/recupera-success" element={<RecuperaSuccess />} />
                  <Route path="/area-managers" element={<AreaManagers />} />
                  <Route path="/agenti" element={<Agenti />} />
                  <Route path="/clienti" element={<CartellaAgente />} />
                  {/* <Route path="/cart-agente" element={<CartellaAgente />} /> */}
                  {/* <Route path="/clienti" element={<Clienti />} /> */}
                  <Route path="/clienti-archiviati" element={<ClientiArchiviati />} />
                  <Route path="/configurations" element={<NewPreventivo />} />
                  <Route path="/door-configurations/*" element={<DoorConfigurationsRoutes />} />
                  {/* <Route path="/cart-preventivo" element={<CartellaPreventivi />} /> */}
                  <Route path="/products" element={<Porte />} />
                  <Route path="/dimensioni" element={<Dimensioni />} />
                  <Route path="/aspetto" element={<Aspetto />} />
                  <Route path="/accessori" element={<Accessori />} />
                  <Route path="/maniglierie" element={<Maniglierie />} />
                  <Route path="/finestrature" element={<Finestrature />} />
                  <Route path="/ventilazione" element={<Ventilazione />} />
                  <Route path="/elementiFissi" element={<ElementiFissi />} />
                  <Route path="/portinaPedonale" element={<PortinaPedonaleIntegrata />} />
                  <Route path="/portinaPedonaleAffiancata" element={<PortinaPedonaleAffiancata />} />
                  <Route path="/accMotorizzazione" element={<Accessori />} />
                  <Route path="/richiesta" element={<Richiesta />} />
                  <Route path="/generaPDF" element={<GeneraPDF />} />
                  <Route path="/customers/*" element={<CustomersRoutes />} />
                  {/* <Link to="/reset-password?token" element={ResetPassword} /> */}
                </Routes>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </I18nextProvider>
  );
}
