import { useDoorConfigurationsApi } from "../api/door_configurations_api";
import { InstallationType, NullableNumber, NullableString, SpringType } from "../core/common_types";
import { Accessorio, AccessorioInConfigurazione, DoorConfig, DoorConfiguration, DoorConfigurationConditions, DoorOption, FormAspettoModel, FormDimensioniModel, QuoteSummary } from "../models/door_configuration";
import { User } from "../models/user";

export const useConfigurationsService = () => {
    const doorConfigurationsApi = useDoorConfigurationsApi();

    return {
        async create(currentUser: User, categoryId: number, customerId: NullableNumber): Promise<DoorConfiguration> {
            let result = await doorConfigurationsApi.create(currentUser, categoryId, customerId);

            if (!result.success) {
                throw result.message;
            }

            if (!result.data) throw "Configuration is null";

            return result.data;
        },

        async delete(currentUser: User, configurationId: number): Promise<any> {
            let result = await doorConfigurationsApi.delete(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async loadDoorConfiguration(currentUser: User, configurationId: number): Promise<DoorConfiguration> {
            let result = await doorConfigurationsApi.get(currentUser, configurationId);
            
            if (!result.success) {
                throw result.message;
            }

            if (!result.data) throw "Configuration is null";

            return result.data;
        },

        async updateDimensions(currentUser: User, configurationId: number, model: FormDimensioniModel): Promise<any> {
            let result = await doorConfigurationsApi.putDimensions(currentUser, configurationId, model);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async updateAspect(currentUser: User, configurationId: number, model: FormAspettoModel): Promise<DoorConfig> {
            let result = await doorConfigurationsApi.putAspect(currentUser, configurationId, model);

            if (!result.success) {
                throw result.message;
            }

            return result.data as DoorConfig; 
        },

        async cercaOpzioniPorta(currentUser: User, configuration: DoorConfiguration): Promise<DoorOption[]> {
            let result = await doorConfigurationsApi.findDoorsOptions(currentUser, configuration);

            if (!result.success) {
                throw result.message;
            }

            return result.data as DoorOption[];
        },

        async findCompatibleMotorizations(currentUser: User, configurationId: number): Promise<Accessorio[]> {
            let result = await doorConfigurationsApi.findCompatibleMotorizations(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }
            
            return result.data as Accessorio[];
        },

        async getMotorizzazione(currentUser: User, configurationId: number): Promise<NullableNumber> {
            let result = await doorConfigurationsApi.getMotorization(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async setMotorizzazione(currentUser: User, configurationId: number, idMotorizzazione: number): Promise<any> {
            let result = await doorConfigurationsApi.putMotorization(currentUser, configurationId, idMotorizzazione);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async getAccessoriMotorizzazione(currentUser: User, configurationId: number): Promise<AccessorioInConfigurazione[]> {
            let result = await doorConfigurationsApi.getMotorizationAccessories(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data as AccessorioInConfigurazione[];
        },

        async getAccessoriGenerici(currentUser: User, configurationId: number): Promise<AccessorioInConfigurazione[]> {
            let result = await doorConfigurationsApi.getGenericAccessories(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data as AccessorioInConfigurazione[];
        },

        async addAccessorio(currentUser: User, configurationId: number, idAccessorio: number, qty: number): Promise<any> {
            let result = await doorConfigurationsApi.putAccessory(currentUser, configurationId, idAccessorio, qty);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async removeAccessorio(currentUser: User, configurationId: number, idAccessorio: number): Promise<any> {
            let result = await doorConfigurationsApi.deleteAccessory(currentUser, configurationId, idAccessorio);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async getCondizioni(currentUser: User, configurationId: number): Promise<DoorConfigurationConditions> {
            let result = await doorConfigurationsApi.getConditions(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data as DoorConfigurationConditions;
        },

        async build(currentUser: User, configurationId: number): Promise<any> {
            let result = await doorConfigurationsApi.build(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async aggiornaCondizioni(currentUser: User, configurationId: number, data: DoorConfigurationConditions): Promise<DoorConfigurationConditions> {
            let result = await doorConfigurationsApi.updateConditions(currentUser, configurationId, data);

            if (!result.success) {
                throw result.message;
            }

            return result.data as DoorConfigurationConditions;
        },

        async setCondizioni(currentUser: User, configurationId: number, data: DoorConfigurationConditions): Promise<DoorConfigurationConditions> {
            let result = await doorConfigurationsApi.setConditions(currentUser, configurationId, data);

            if (!result.success) {
                throw result.message;
            }

            return result.data as DoorConfigurationConditions;
        },

        async getQuote(currentUser: User, configurationId: number): Promise<QuoteSummary> {
            let result = await doorConfigurationsApi.getQuote(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data as QuoteSummary;
        },

        async resetQuote(currentUser: User, configurationId: number): Promise<any> {
            let result = await doorConfigurationsApi.resetQuote(currentUser, configurationId);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async savePreview(currentUser: User, configurationId: number, preview: NullableString): Promise<any> {
            let result = await doorConfigurationsApi.savePreview(currentUser, configurationId, preview);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },

        async verificaVincoliDimensioni(configuration: DoorConfiguration | null): Promise<any> {
            if (!configuration) throw {severity: "error", message: "Nessuna configurazione in corso"};
        
            checkGenerali(configuration);
            checkInstallazioneInLuce(configuration);
            checkInstallazioneOltreLuce(configuration);
        }
    }
}

function checkGenerali(configuration: DoorConfiguration) {
    const {
        larghezzaForo,
        altezzaForo
    } = configuration;
}

function checkInstallazioneInLuce(configuration: DoorConfiguration) {

}

function checkInstallazioneOltreLuce(configuration: DoorConfiguration) {
    const {
        tipoMolle,
        tipoInstallazioneMontanteDx, 
        tipoInstallazioneMontanteSx, 
        tipoInstallazioneTraversa, 
        altezzaArchitrave, 
        larghezzaSpallettaDx, 
        larghezzaSpallettaSx,
        isMotorizzata
    } = configuration;

    if (tipoMolle == SpringType.torsione) {
        if (tipoInstallazioneTraversa == InstallationType.oltre_luce && altezzaArchitrave < 160) throw {severity: "warning", message: "Per l'installazione Oltre Luce in altezza, l'altezza dell'architrave deve essere almeno di 160 mm."}
        if (tipoInstallazioneMontanteDx == InstallationType.oltre_luce && larghezzaSpallettaDx < 70) throw {severity: "warning", message: "Per l'installazione Oltre Luce in larghezza, la larghezza della Spalletta Destra deve essere almeno di 70 mm."}
        if (tipoInstallazioneMontanteSx == InstallationType.oltre_luce && larghezzaSpallettaSx < 70) throw {severity: "warning", message: "Per l'installazione Oltre Luce in larghezza, la larghezza della Spalletta Sinistra deve essere almeno di 70 mm."}
    } else if (tipoMolle == SpringType.trazione) {
        if (tipoInstallazioneTraversa == InstallationType.oltre_luce) {
            if (!isMotorizzata && altezzaArchitrave < 30) {
                throw {severity: "warning", message: "Per l'installazione Oltre Luce in altezza, l'altezza dell'architrave deve essere almeno di 30 mm."};
            }

            if (isMotorizzata && altezzaArchitrave < 90) {
                throw {severity: "warning", message: "Per l'installazione motorizzata Oltre Luce in altezza, l'altezza dell'architrave deve essere almeno di 90 mm."};
            }
        }
        if (tipoInstallazioneMontanteDx == InstallationType.oltre_luce && larghezzaSpallettaDx < 70) throw {severity: "warning", message: "Per l'installazione Oltre Luce in larghezza, la larghezza della Spalletta Destra deve essere almeno di 70 mm."}
        if (tipoInstallazioneMontanteSx == InstallationType.oltre_luce && larghezzaSpallettaSx < 70) throw {severity: "warning", message: "Per l'installazione Oltre Luce in larghezza, la larghezza della Spalletta Sinistra deve essere almeno di 70 mm."}        
    }
}