import * as React from "react";
import { LayoutPage } from "../../components/Layout/layoutPage";
import TopBar from "../../components/TopBar/TopBar";
import { Pagination, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { ModalCustom } from "../../components/Modal/modalsCustom";
import AgentiForm from "../../components/Formik/agentiForm";
import { ResetPass } from "../../components/Formik/FormikResetPass";
import CheckIcon from "@mui/icons-material/Check";
import { ModalEdit } from "../../components/Modal/ModalEdit";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CustomizedSnackbars from "../../components/Message/Snackars";
import { I18n } from "../../i18n";
import translate from "../../i18n/messages/translate";
import Loading from "../../components/Loading/loading";
import { importBaseActions } from "../../store/reducers/base";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useDataGridStyles, useDropDownMenuStyles, usePaginationStyles } from "../../core/MuiStyles";
import { ITEMS_PER_PAGE } from "../../Utility";
import { LoadingInternal } from "../../components/Loading/LoadingInternal";
import { useConfigurationsService } from "../../services/ConfigurationsService";
import { useCustomersService } from "../../v2/services/customers_service";

const AreaManagers = () => {
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const [rows, setRows] = useState({ pageSize: ITEMS_PER_PAGE, page: 0, items: [], pageCount: 0, isRefreshing: true });
  const { t } = useTranslation("common");
  const configurationsService = useConfigurationsService();
  const customersService = useCustomersService();

  //const history = useHistory();
  const navigate = useNavigate();
  const paginationClasses = usePaginationStyles();
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);

  // HOOK EDIT BUTTON
  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    load(rows.page);
  }
  const handleOpenEdit = (user) => {
    setUser(user);
    setOpenEdit(true);
    closeUserMenu();
  };

  //HOOK RESET PASSWORD BUTTON
  const [openReset, setOpenReset] = useState(false);
  const handleCloseReset = () => setOpenReset(false);
  // const handleOpenReset = (user) => {
  //   setUser(user);
  //   setOpenReset(true);
  // };

  //HOOK DELETE BUTTON
  const [openDel, setOpenDel] = useState(false);
  const handleCloseDel = () => setOpenDel(false);
  const handleOpenDel = (user) => {
    setUser(user);
    setOpenDel(true);
    closeUserMenu();
  };

  //HOOK ADD AGENTE
  const [openAdd, setOpenAdd] = useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    load(0);
  }
  const handleOpenAdd = () => {
    setOpenAdd(true);
    closeUserMenu();
  }

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  // const fetcher = (url) =>
  //   .then((res) => res.data.data);
  // const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/users`, state.success], fetcher);

  useEffect(() => {
    load(0);
  }, []);

  const load = async (page) => {
    setRows({...rows, isRefreshing: true});
    try {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/users/area-managers?count=${rows.pageSize}&page=${page}`, axiosConfig);
      if (res.data.success !== 1) throw res.data.message;

      let pageCount = Math.ceil(res.data.data.itemsCount / ITEMS_PER_PAGE);
      setRows({ ...rows, items: res.data.data.items, itemsCount: res.data.data.itemsCount, page: page, pageCount: pageCount, isRefreshing: false });
    }
    catch (e) {
      console.log(e);
      setRows({...rows, isRefreshing: false});
    }
  }

  const showUserMenu = (agent) => {
    document.querySelectorAll(`div.dropdown:not(div.dropdown_agent_${agent.id})`).forEach(e => {
      e.classList.remove("show");
      e.querySelector(`ul.dropdown-menu`).classList.remove("show");
    });
    
    let dropdown = document.querySelector(`div.dropdown_agent_${agent.id}`);
    let dropdownMenu = dropdown.querySelector(`ul.dropdown-menu`);

    dropdown.classList.toggle("show");
    dropdownMenu.classList.toggle("show");
  }

  const closeUserMenu = () => {
    document.querySelectorAll(`div.dropdown`).forEach(e => {
      e.classList.remove("show");
      e.querySelector(`ul.dropdown-menu`).classList.remove("show");
    });
  }

  const resetPassword = async (agent) => {
    //setLoading(true);
    let res = await axios.post(`${process.env.REACT_APP_API_URL}/users/generate-password`, {
      id: agent.id,
    }, axiosConfig);
      
    res.data.success == 1 && dispatch(baseActions.setSuccess(true));
    res.data.success === 1 && alert("La password è stata reimpostata con successo. L'utente riceverà un'email con la nuova password.");
      
    //setLoading(false);
    closeUserMenu();
  }

  const createQuickConfiguration = async () => {
    try {
      setRows({...rows, isRefreshing: true});

      let res = await configurationsService.createQuickConfiguration(state.user.token, {});
      dispatch(baseActions.clearStorage());
      dispatch(baseActions.setQuoteId(res.id));
      dispatch(baseActions.setCustomer({id: res.customer.id, lastName: res.customer.surname, firstName: res.customer.name, isServiceCustomer: true}));
      navigate("/products");
    } catch (e) {
      setRows({...rows, isRefreshing: false});
    }
  }

  const showAnonymousConfigurations = async (user) => {
    try {
      let serviceCustomerId = await customersService.findServiceCustomerId(state.user, user.id);
      if (!serviceCustomerId) {
        alert("Non ci sono ancora configurazioni disponibili.");
        return;  
      }
      dispatch(baseActions.setCustomer({id: serviceCustomerId, lastName: "Configurazioni", firstName: "Anonime", isServiceCustomer: true}));
      navigate("/configurations", { state: { parentUser: user } });
    } catch (e) {
      console.log(e);
    }
  }

  if (!rows) return <Loading />;

  return (
    <I18n locale={state.locale}>
      <LayoutPage>
        <div style={{flex: "0 0 auto"}}>
          <TopBar />
          <div className={"d-flex justify-content-between p-3"}>
            <h2 className="h2 fw-bold">
              {translate("areaManagers")}
            </h2>
            <div>
              {state.user.userType == "Admin" && (
                <button type="button" className="btn btn-primary me-2" onClick={() => createQuickConfiguration()}>
                  <AddIcon />
                  <span className="ms-1">NUOVA CONFIGURAZIONE ANONIMA</span>
                </button>
              )}
            </div>
          </div>
        </div>

        <div style={{flex: "1 1 auto", overflowY: "auto"}}>
          <div className="w-full pb-3">
            <table className="table table-hover table-stripped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-center">Agenti</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rows.items.map(agent => (
                  <tr key={agent.id} style={{ cursor: "pointer" }} onClick={() => {
                    dispatch(baseActions.setAreaManager({id: agent.id, lastName: agent.surname, firstName: agent.name}));
                    navigate("/agenti");
                  }}>
                    <td className="align-middle">{agent.full_name}</td>
                    <td className="align-middle text-center"><span className="badge rounded-pill bg-success">{agent.childs}</span></td>
                    <td className="text-end">
                      <div className={`dropdown dropdown_agent_${agent.id}`}>
                        <button type="button" className="btn btn-light btn-sm" onClick={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          showUserMenu(agent);
                        }}>
                          <MoreVertIcon />
                        </button>

                        <ul className="dropdown-menu" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 40px)" }}>
                          <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              showAnonymousConfigurations(agent);
                            }}>
                              <ArchiveIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              CONFIGURAZIONI ANONIME
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              resetPassword(agent);
                            }}>
                              <LockIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("RESET_PASSWORD")}
                            </a>
                          </li>
                          {/* <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpenDel(agent);                            
                            }}>
                              <DeleteIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("ELIMINA")}
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Pagination
              className={paginationClasses.root + " justify-content-end mb-0"}
              color="primary"
              count={rows.pageCount}
              page={rows.page + 1}
              onChange={(event, value) => load(value - 1)}
            />
          </div>
        </div>

        {/* MODAL EDIT AGENTE */}
        <ModalEdit
          title={"Modifica agente"}
          open={openEdit}
          handleClose={handleCloseEdit}>
          <AgentiForm handleClose={handleCloseEdit} user={user} />
        </ModalEdit>
        <CustomizedSnackbars open={open} setOpen={setOpen} />

        {/* MODAL ADD */}
        <ModalEdit
          title={"Nuovo agente"}
          open={openAdd}
          handleClose={handleCloseAdd}>
          <AgentiForm handleClose={handleCloseAdd} />
        </ModalEdit>

        {/*  MODAL RESET PASSWORD*/}
        <ModalCustom
          title={"Reimposta password"}
          open={openReset}
          handleClose={handleCloseReset}>
          <ResetPass user={user} handleClose={handleCloseReset} />
        </ModalCustom>

        {/*  MODAL DELETE */}
        <ModalCustom
          title={"Sicuro di eliminarlo?"}
          open={openDel}
          handleClose={handleCloseDel}>
          <Typography variant="h5" component="div">
            I dati eliminati non potranno essere recuperati.
          </Typography>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Button
                startIcon={<CheckIcon sx={{ fontSize: 28 }} color={"white"} />}
                onClick={() =>
                  axios
                    .delete(`${process.env.REACT_APP_API_URL}/users/${user.id}`, axiosConfig)
                    .then((res) => {
                      if (res.data.data === 1) {
                        dispatch(baseActions.setSuccess(true));
                        handleCloseDel();
                      } else {
                        alert("Ops...riprovare");
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                    })
                }
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                SI
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                color={"secondary"}
                onClick={handleCloseDel}
                startIcon={<CloseIcon sx={{ fontSize: 28 }} color={"white"} />}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                NO
              </Button>
            </Grid>
          </Grid>
        </ModalCustom>

        {rows.isRefreshing && <LoadingInternal />}
      </LayoutPage>
    </I18n>
  );
};

export default AreaManagers;
