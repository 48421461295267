import React, { FC } from "react";
import { Rect } from "react-konva";
import { DEFAULT_LARGHEZZA_GUARNIZIONE, DEFAULT_STROKE_WIDTH } from "../../../../../../core/common_constants";
import { toDrawUnit } from "../../../../../../core/common_functions";

export const Guarnizione: FC<{x: number, y: number, length: number, rotation: number}> = ({x, y, length, rotation}) => {

    return (
        <Rect
            x={x} 
            y={y} 
            width={toDrawUnit(DEFAULT_LARGHEZZA_GUARNIZIONE)} 
            height={length} 
            stroke={"#000"} 
            strokeWidth={DEFAULT_STROKE_WIDTH}
            fill={"#000"}
            rotation={rotation}
        />
    );
}