import React from "react";
import { Vector } from "vecti";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { ALTEZZA_ARCHITRAVE, DEFAULT_PROFONDITA_INVOLUCRO, LARGHEZZA_GARAGE, LARGHEZZA_SPALLETTA_DX, LARGHEZZA_SPALLETTA_SX, SPESSORE_ARCHITRAVE, SPESSORE_SPALLETTA_DX, SPESSORE_SPALLETTA_SX } from "../../../../../../core/common_constants";
import { midpoint, toDrawUnit } from "../../../../../../core/common_functions";
import { EditableMeasure, EditableMeasureProps } from "./editable_measure";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { ALTEZZA_GARAGE } from './../../../../../../core/common_constants';

export const Misure = () => {
    const profondita = toDrawUnit(DEFAULT_PROFONDITA_INVOLUCRO * 2);
    const dispatcher = useStoreDispatch();
    const {
        setAltezzaArchitrave, 
        setSpessoreArchitrave, 
        setAltezzaForo,
        setLarghezzaForo,
        setProfonditaGarage,
        setSpessoreSpallettaSx,
        setLarghezzaSpallettaSx,
        setSpessoreSpallettaDx,
        setLarghezzaSpallettaDx
    } = useDoorConfigurationBuilderActions();
    const {doorConfigurationBuilder} = useStoreSelector(store => store);

    const buildMisuraHt = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptFrom = Vector.of([(LARGHEZZA_GARAGE / 2), 0]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));
        let ptTo = Vector.of([(LARGHEZZA_GARAGE / 2), ALTEZZA_ARCHITRAVE]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));
        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                        
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            label: "HT1 = " + doorConfigurationBuilder.configuration?.altezzaArchitrave + " mm",
            labelPosition: {x: ptMiddle.x + 5, y: ptMiddle.y - 10},
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.altezzaArchitrave : 0,
            prefix: "HT1",
            um: "mm",
            editTitle: "Altezza Architrave",
            onConfirm: (value) => {
                dispatcher(setAltezzaArchitrave(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraSt = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptFrom = Vector.of([(LARGHEZZA_GARAGE / 2), ALTEZZA_ARCHITRAVE]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));

        let vecDirBottomLeft = Vector.of([0, 0]).subtract(Vector.of([-profondita, -profondita])).normalize();
        let ptTo = ptFrom.add(vecDirBottomLeft.multiply(SPESSORE_ARCHITRAVE));

        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x + 15, y: ptMiddle.y - 10},
            label: "ST1 = " + doorConfigurationBuilder.configuration?.spessoreArchitrave + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.spessoreArchitrave : 0,
            prefix: "ST1",
            um: "mm",
            editTitle: "Spessore Architrave",
            onConfirm: (value) => {
                dispatcher(setSpessoreArchitrave(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraH = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptRef = Vector.of([(LARGHEZZA_GARAGE / 2), ALTEZZA_ARCHITRAVE]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));
        
        let vecDirBottomLeft = Vector.of([0, 0]).subtract(Vector.of([-profondita, -profondita])).normalize();
        let ptFrom = ptRef.add(vecDirBottomLeft.multiply(SPESSORE_ARCHITRAVE));

        let ptTo = Vector.of([LARGHEZZA_GARAGE / 2, ALTEZZA_GARAGE]);

        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x + 5, y: ptMiddle.y - 25},
            label: "H = " + doorConfigurationBuilder.configuration?.altezzaForo + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.altezzaForo : 0,
            prefix: "H",
            um: "mm",
            editTitle: "Altezza Vuoto",
            onConfirm: (value) => {
                dispatcher(setAltezzaForo(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraL = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptRef = Vector.of([(LARGHEZZA_GARAGE / 2), ALTEZZA_ARCHITRAVE]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));
        
        let vecDirBottomLeft = Vector.of([0, 0]).subtract(Vector.of([-profondita, -profondita])).normalize();
        let ptFrom = ptRef.add(vecDirBottomLeft.multiply(SPESSORE_ARCHITRAVE));

        let ptTo = Vector.of([LARGHEZZA_GARAGE / 2, ALTEZZA_GARAGE]);

        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
        ptFrom = Vector.of([SPESSORE_SPALLETTA_SX, ptMiddle.y]);
        ptTo = Vector.of([LARGHEZZA_GARAGE - SPESSORE_SPALLETTA_DX, ptMiddle.y]);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x + 5, y: ptMiddle.y + 5},
            label: "L = " + doorConfigurationBuilder.configuration?.larghezzaForo + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.larghezzaForo : 0,
            prefix: "L",
            um: "mm",
            editTitle: "Larghezza Vuoto",
            onConfirm: (value) => {
                dispatcher(setLarghezzaForo(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraPg = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptFrom = Vector.of([(LARGHEZZA_GARAGE / 2), 0]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));
        
        let vecDirSolaio = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptTo = ptFrom.add(vecDirSolaio.multiply(profondita+15));
                
        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x + 15, y: ptMiddle.y - 10},
            label: "PG = " + doorConfigurationBuilder.configuration?.profonditaGarage + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.profonditaGarage : 0,
            prefix: "PG",
            um: "mm",
            editTitle: "Profondità Garage",
            onConfirm: (value) => {
                dispatcher(setProfonditaGarage(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraSs = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptFrom = Vector.of([LARGHEZZA_SPALLETTA_SX, (ALTEZZA_ARCHITRAVE/2) + (ALTEZZA_GARAGE/2)]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));

        let vecDirBottomLeft = Vector.of([0, 0]).subtract(Vector.of([-profondita, -profondita])).normalize();
        let ptTo = ptFrom.add(vecDirBottomLeft.multiply(SPESSORE_SPALLETTA_SX));
        
        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x + 5, y: ptMiddle.y - 20},
            label: "SS1 = " + doorConfigurationBuilder.configuration?.spessoreSpallettaSx + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.spessoreSpallettaSx : 0,
            prefix: "SS1",
            um: "mm",
            editTitle: "Spessore Spalletta Sinistra",
            onConfirm: (value) => {
                dispatcher(setSpessoreSpallettaSx(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraLs = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([-profondita, -profondita]).subtract(Vector.of([0, 0])).normalize();
        let ptFrom = Vector.of([LARGHEZZA_SPALLETTA_SX, (ALTEZZA_ARCHITRAVE/2) + (ALTEZZA_GARAGE/2)]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));

        let ptTo = Vector.of([ptFrom.x - LARGHEZZA_SPALLETTA_SX, ptFrom.y]);

        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x, y: ptMiddle.y - 25},
            label: "LS1 = " + doorConfigurationBuilder.configuration?.larghezzaSpallettaSx + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.larghezzaSpallettaSx : 0,
            prefix: "LS1",
            um: "mm",
            editTitle: "Larghezza Spalletta Sinistra",
            onConfirm: (value) => {
                dispatcher(setLarghezzaSpallettaSx(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraSd = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([LARGHEZZA_GARAGE + profondita, -profondita]).subtract(Vector.of([LARGHEZZA_GARAGE, 0])).normalize();
        let ptFrom = Vector.of([LARGHEZZA_GARAGE - LARGHEZZA_SPALLETTA_SX, (ALTEZZA_ARCHITRAVE/2) + (ALTEZZA_GARAGE/2)]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));

        let vecDirBottomLeft = Vector.of([LARGHEZZA_GARAGE, 0]).subtract(Vector.of([LARGHEZZA_GARAGE + profondita, -profondita])).normalize();
        let ptTo = ptFrom.add(vecDirBottomLeft.multiply(SPESSORE_SPALLETTA_DX));
        
        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x, y: ptMiddle.y + 5},
            label: "SD1 = " + doorConfigurationBuilder.configuration?.spessoreSpallettaDx + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.spessoreSpallettaDx : 0,
            prefix: "SD1",
            um: "mm",
            editTitle: "Spessore Spalletta Destra",
            onConfirm: (value) => {
                dispatcher(setSpessoreSpallettaDx(value));
                return {isValid: true};
            }
        };
    }

    const buildMisuraLd = (): EditableMeasureProps => {
        let vecDirTopLeft = Vector.of([LARGHEZZA_GARAGE + profondita, -profondita]).subtract(Vector.of([LARGHEZZA_GARAGE, 0])).normalize();
        let ptFrom = Vector.of([LARGHEZZA_GARAGE - LARGHEZZA_SPALLETTA_SX, (ALTEZZA_ARCHITRAVE/2) + (ALTEZZA_GARAGE/2)]).add(vecDirTopLeft.multiply(SPESSORE_ARCHITRAVE));

        let ptTo = Vector.of([ptFrom.x + LARGHEZZA_SPALLETTA_DX, ptFrom.y]);

        let ptMiddle = midpoint(ptFrom.x, ptFrom.y, ptTo.x, ptTo.y);
                
        return {
            ptFrom: {x: ptFrom.x, y: ptFrom.y},
            ptTo: {x: ptTo.x, y: ptTo.y},
            labelPosition: {x: ptMiddle.x, y: ptMiddle.y - 25},
            label: "LD1 = " + doorConfigurationBuilder.configuration?.larghezzaSpallettaDx + " mm",
            value: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.larghezzaSpallettaDx : 0,
            prefix: "LD1",
            um: "mm",
            editTitle: "Larghezza Spalletta Destra",
            onConfirm: (value) => {
                dispatcher(setLarghezzaSpallettaDx(value));
                return {isValid: true};
            }
        };
    }

    return (
        <>
            <EditableMeasure {...buildMisuraHt()} />
            <EditableMeasure {...buildMisuraSt()} />
            <EditableMeasure {...buildMisuraH()} />
            <EditableMeasure {...buildMisuraL()} />
            <EditableMeasure {...buildMisuraPg()} />
            <EditableMeasure {...buildMisuraSs()} />
            <EditableMeasure {...buildMisuraLs()} />
            <EditableMeasure {...buildMisuraSd()} />
            <EditableMeasure {...buildMisuraLd()} />
        </>
    );
}