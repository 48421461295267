import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { NullableNumber, NullableString } from "../../../../../../core/common_types";
import { useDoorConfigurationBuilderActions } from "../../../../../../../store/reducers/door_configuration_builder";
import { useStoreDispatch, useStoreSelector } from "../../../../../../../hooks/StoreHooks";
import { numberCompare } from "../../../../../../core/common_functions";
import { Color, ColorSide, ColorType, Option } from "../../../../../../models/door_configuration";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ColorsProps } from "../../../../../../ui/dialogs/colors_dialog";
import { useDialogs } from "../../../../../../providers/dialogs_provider";
import { useFormik } from "formik";

type FormModelloFinituraModel = {
    porta: NullableNumber,
    modello: NullableNumber,
    finitura: NullableNumber,
    tipoColoreEsterno: ColorType,
    coloreEsterno: NullableNumber,
    tipoColoreInterno: ColorType,
    coloreInterno: NullableNumber,
}

export const FormModelloFinitura: FC<{ options: Option[], colors: Color[] }> = ({ options, colors }) => {
    const { doorConfigurationBuilder } = useStoreSelector(store => store);
    const dialogs = useDialogs();
    const dispatcher = useStoreDispatch();
    const {
        setPorta,
        setModello,
        setFinitura,
        setTipoColoreEsterno,
        setColoreEsterno,
        setColoreEsternoNsRal,        
        setTipoColoreInterno,
        setColoreInterno,
        setColoreInternoNsRal
    } = useDoorConfigurationBuilderActions();

    const formik = useFormik<{coloreEsternoNsRal: NullableString, coloreInternoNsRal: NullableString}>({
        initialValues: {
            coloreEsternoNsRal: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreEsternoNsRal : undefined,
            coloreInternoNsRal: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreInternoNsRal : undefined,
        },
        onSubmit: (model) => {
            
        }
    });

    const getOptions = (type: string, parentId?: NullableNumber) => {
        let ret = options
            .filter(o => o && o.type == type && (!parentId || o.parentId == parentId))
            .sort((a, b) => (!a || !b) ? 0 : a.name.localeCompare(b.name));

        return ret;
    }

    const findOption = (optionId: NullableNumber, optionType: string, parentId: NullableNumber) => {
        let option = options.find(o => o?.optionId == optionId && o?.type == optionType && (!parentId || o?.parentId == parentId));
        return option ? option.id : undefined;
    }

    const getColors = (side: ColorSide, finitura: NullableNumber) => {
        if (!finitura) return [];

        let ret = colors
            .filter(c => c && c.parentOptionId == finitura && c.side == side)
            .sort((a, b) => (!a || !b) ? 0 : numberCompare(a.sortOrder, b.sortOrder))

        return ret;
    }

    const getRalName = (colorId: NullableNumber) => {
        if (!colorId) return "";

        let color = colors.find(c => c?.id == colorId);
        return color ? color.name + ` (RAL ${color.code})` : undefined;
    }

    const getRalPreview = (colorId: NullableNumber) => {
        if (!colorId) return "";
        
        let color = colors.find(c => c?.id == colorId);
        return color ? color.img : undefined;
    }

    const [model, setModel] = useState<FormModelloFinituraModel>({
        porta: undefined,//findOption(doorConfigurationBuilder.configuration?.porta, "PORTA"),
        modello: undefined,//findOption(doorConfigurationBuilder.configuration?.modello, "MODELLO"),
        finitura: undefined,//findOption(doorConfigurationBuilder.configuration?.finitura, "FINITURA"),
        tipoColoreEsterno: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoColoreEsterno : "ral_standard",
        coloreEsterno: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreEsterno : null,
        tipoColoreInterno: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.tipoColoreInterno : "ral_standard",
        coloreInterno: doorConfigurationBuilder.configuration ? doorConfigurationBuilder.configuration.coloreInterno : null,
    });

    const renderSelectModello = () => {
        let modelli = getOptions("MODELLO", model.porta);

        if (modelli.length == 1 && !model.modello) {
            dispatcher(setModello(modelli[0]?.optionId));
            setModel({...model, modello: modelli[0]?.id});
            return null;
        }

        if (modelli.length == 1 && model.modello) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="modello">Modello</InputLabel>
                <Select
                    labelId="modello"
                    value={model.modello ?? ""}
                    label="Modello"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            setModel({
                                ...model,
                                modello: option.id,
                                finitura: undefined,
                                tipoColoreEsterno: "ral_standard",
                                coloreEsterno: undefined,
                                tipoColoreInterno: "ral_standard",
                                coloreInterno: undefined
                            });
                            formik.setValues({ coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined });
                            dispatcher(setModello(option.optionId));
                        }
                    }}>
                    {modelli.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const renderSelectFinitura = () => {
        let finiture = getOptions("FINITURA", model.modello);

        if (finiture.length == 1 && !model.finitura) {            
            dispatcher(setFinitura(finiture[0]?.optionId));
            //setModel({ ...model, finitura: finiture[0]?.id });
            setDefaultColors(finiture[0]?.id);
            return null;
        }

        if (finiture.length == 1 && model.finitura) return null;

        return (
            <FormControl fullWidth className="mt-3">
                <InputLabel id="finitura">Finitura</InputLabel>
                <Select
                    labelId="finitura"
                    value={model.finitura ?? ""}
                    label="Finitura"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {
                            setDefaultColors(option.id);
                            // setModel({
                            //     ...model,
                            //     finitura: option.id,
                            //     tipoColoreEsterno: "ral_standard",
                            //     coloreEsterno: undefined,
                            //     tipoColoreInterno: "ral_standard",
                            //     coloreInterno: undefined
                            // });
                            formik.setValues({ coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined });
                            dispatcher(setFinitura(option.optionId));
                        }
                    }}>
                    {finiture.map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    const setDefaultColors = (finishId: NullableNumber)  => {
        let colors = getColors("external", finishId);
        let externalColorId = colors.length == 1 ? colors[0]?.id : null;

        colors = getColors("internal", finishId);
        let internalColorId = colors.length == 1 ? colors[0]?.id : null;
        
        if (externalColorId) {
            dispatcher(setTipoColoreEsterno("ral_standard"));
            dispatcher(setColoreEsterno(externalColorId));
        }
        if (internalColorId) {
            dispatcher(setTipoColoreInterno("ral_standard"));
            dispatcher(setColoreInterno(internalColorId));
        }

        setModel({ ...model, finitura: finishId, tipoColoreEsterno: "ral_standard", coloreEsterno: externalColorId, tipoColoreInterno: "ral_standard", coloreInterno: internalColorId });
    }

    useEffect(() => {
        let porta = findOption(doorConfigurationBuilder.configuration?.porta, "PORTA", null);
        let modello = porta ? findOption(doorConfigurationBuilder.configuration?.modello, "MODELLO", porta) : undefined;
        let finitura = modello ? findOption(doorConfigurationBuilder.configuration?.finitura, "FINITURA", modello) : undefined;

        setModel({...model, porta, modello, finitura});
    }, []);

    return (
        <div>
            <h5 className="text-primary">Modello e finitura</h5>

            <FormControl fullWidth className="mt-2">
                <InputLabel id="porta">Porta</InputLabel>
                <Select
                    labelId="porta"
                    value={model.porta ?? ""}
                    label="Porta"
                    onChange={(e) => {
                        let option = options.find(o => o?.id == Number(e.target.value));
                        if (option) {                            
                            setModel({ 
                                ...model, 
                                porta: option.id, 
                                modello: undefined, 
                                finitura: undefined, 
                                tipoColoreEsterno: "ral_standard", 
                                coloreEsterno: undefined, 
                                tipoColoreInterno: "ral_standard", 
                                coloreInterno: undefined 
                            });
                            formik.setValues({coloreEsternoNsRal: undefined, coloreInternoNsRal: undefined});
                            dispatcher(setPorta(option.optionId));
                        }
                    }}>
                    {getOptions("PORTA").map(o => <MenuItem key={o?.id} value={o?.id}>{o?.name}</MenuItem>)}
                </Select>
            </FormControl>

            {model.porta && renderSelectModello()}

            {model.modello && renderSelectFinitura()}

            {model.finitura && <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_esterno">Tipo colore esterno</InputLabel>
                <Select
                    labelId="tipo_colore_esterno"
                    value={model.tipoColoreEsterno ?? ""}
                    label="Tipo colore esterno"
                    onChange={(e) => {
                        setModel({ 
                            ...model, 
                            tipoColoreEsterno: e.target.value as ColorType,
                            coloreEsterno: null,                                                        
                        });
                        formik.setFieldValue("coloreEsternoNsRal", undefined);
                        dispatcher(setTipoColoreEsterno(e.target.value as ColorType));
                    }}>
                    <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                    <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                    <MenuItem value={"a_campione"}>A Campione</MenuItem>
                </Select>
            </FormControl>}

            {model.finitura && model.tipoColoreEsterno == "ral_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"Colore esterno"}
                name={"coloreEsterno"}
                value={getRalName(model.coloreEsterno)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: model.coloreEsterno && (
                        <InputAdornment position="start">
                            <img src={getRalPreview(model.coloreEsterno) ?? undefined} style={{width: "2rem", height: "1.75rem"}} />
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectColor({colors: getColors("external", model.finitura), selectedColor: model.coloreEsterno, onConfirm: selectedColor => {
                                if (selectedColor) {
                                    setModel({ ...model, coloreEsterno: selectedColor });
                                    dispatcher(setColoreEsterno(selectedColor));
                                } else {
                                    setModel({ ...model, coloreEsterno: null });
                                    dispatcher(setColoreEsterno(null));
                                }
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && model.tipoColoreEsterno == "ral_non_standard" && <TextField
                fullWidth 
                className="mt-3"
                label={"Colore esterno"}
                name={"coloreEsterno"}
                value={formik.values.coloreEsternoNsRal}
                onChange={e => formik.setFieldValue("coloreEsternoNsRal", e.target.value)}
                onBlur={e => dispatcher(setColoreEsternoNsRal(e.target.value))}
            />}

            {model.finitura && <FormControl fullWidth className="mt-3">
                <InputLabel id="tipo_colore_interno">Tipo colore interno</InputLabel>
                <Select
                    labelId="tipo_colore_interno"
                    value={model.tipoColoreInterno ?? ""}
                    label="Tipo colore interno"
                    onChange={(e) => {
                        setModel({ 
                            ...model, 
                            tipoColoreInterno: e.target.value as ColorType,
                            coloreInterno: null
                        });                        
                        formik.setFieldValue("coloreInternoNsRal", undefined);
                        dispatcher(setTipoColoreInterno(e.target.value as ColorType));
                    }}>
                    <MenuItem value={"ral_standard"}>RAL Standard</MenuItem>
                    <MenuItem value={"ral_non_standard"}>RAL NON Standard</MenuItem>
                    <MenuItem value={"a_campione"}>A Campione</MenuItem>
                </Select>
            </FormControl>}

            {model.finitura && model.tipoColoreInterno == "ral_standard" && <TextField
                fullWidth 
                focused={false}
                className="mt-3"
                label={"Colore interno"}
                name={"coloreInterno"}
                value={getRalName(model.coloreInterno)}
                InputProps={{
                    readOnly: true, 
                    startAdornment: model.coloreInterno && (
                        <InputAdornment position="start">
                            <img src={getRalPreview(model.coloreInterno) ?? undefined} style={{width: "2rem", height: "1.75rem"}} />
                        </InputAdornment>
                    ),

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => dialogs?.selectColor({colors: getColors("internal", model.finitura), selectedColor: model.coloreInterno, onConfirm: selectedColor => {
                                if (selectedColor) {
                                    setModel({ ...model, coloreInterno: selectedColor });
                                    dispatcher(setColoreInterno(selectedColor));
                                } else {
                                    setModel({ ...model, coloreInterno: null });
                                    dispatcher(setColoreInterno(null));
                                }
                            }})}>
                                <OpenInNewIcon style={{ color: "black" }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />}

            {model.finitura && model.tipoColoreInterno == "ral_non_standard" && <TextField
                fullWidth 
                className="mt-3"
                label={"Colore interno"}
                name={"coloreInterno"}
                value={formik.values.coloreInternoNsRal}
                onChange={e => formik.setFieldValue("coloreInternoNsRal", e.target.value)}
                onBlur={e => dispatcher(setColoreInternoNsRal(e.target.value))}
            />}
        </div>
    );
}