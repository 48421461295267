import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

export const LayoutPage = ({ children }) => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/configurations" ? (
        <div style={{height: "100vh", overflowX: "hidden"}}>
          {children}
        </div>
      ) : (
        <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
          {children}
        </div>
      )}
    </>
  );
};
