import { Box, Button, Drawer, Grid, SwipeableDrawer } from "@mui/material";
import React, { useRef, useState } from "react";
import { useStoreSelector } from "../../../../../../../hooks/StoreHooks";

const drawerBleeding = 56;

export const MetricsSummary = () => {
    const { doorConfigurationBuilder } = useStoreSelector(store => store);
    const [drawer, setDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawer(open);
        };

    return (
        <div style={{position: "absolute", bottom: "0", width: "100%"}}>
            <Button onClick={toggleDrawer(true)}>Visualizza misure</Button>
        
            <Drawer
                anchor={"bottom"}
                open={drawer}
                onClose={toggleDrawer(false)}>
                <Box className="metrics-panel">
                    <Grid container spacing={0}>
                        <Grid item lg={2} className="item">
                            <label>Larghezza Foro</label>
                            <span>L = {doorConfigurationBuilder.configuration?.larghezzaForo} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Altezza Foro</label>
                            <span>H = {doorConfigurationBuilder.configuration?.altezzaForo} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Larghezza Porta</label>
                            <span>L = {doorConfigurationBuilder.configuration?.larghezzaPorta} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Altezza Porta</label>
                            <span>H = {doorConfigurationBuilder.configuration?.altezzaPorta} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Architrave</label>
                            <span>HT = {doorConfigurationBuilder.configuration?.altezzaArchitrave} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Spalletta Sinistra</label>
                            <span>LS = {doorConfigurationBuilder.configuration?.larghezzaSpallettaSx} mm</span>
                        </Grid>

                        <Grid item lg={2} className="item">
                            <label>Spalletta Destra</label>
                            <span>LD = {doorConfigurationBuilder.configuration?.larghezzaSpallettaSx} mm</span>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </div>
    );
}