import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  CircularProgress,
  IconButton,
  Pagination, Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { ModalEdit } from "../../components/Modal/ModalEdit";
import AgentiForm from "../../components/Formik/agentiForm";
import axios from "axios";
import useSWR from "swr";
import Box from "@mui/material/Box";
import CustomizedSnackbars from "../../components/Message/Snackars";
import translate from "../../i18n/messages/translate";
import { I18n } from "../../i18n";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../components/Paginazione/pagination";
import Loading from "../../components/Loading/loading";
import ClientiForm from "../../components/Formik/clientiForm";
import { ModalCustom } from "../../components/Modal/modalsCustom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { useDataGridStyles, usePaginationStyles } from "../../core/MuiStyles";
import { ITEMS_PER_PAGE } from "../../Utility";
import logo from "../../../src/assets/img/LogoOverlap.png";
import { LoadingInternal } from "../../components/Loading/LoadingInternal";
import { useConfigurationsService } from "../../services/ConfigurationsService";

const CartellaAgente = () => {
  const location = useLocation();
  const configurationsService = useConfigurationsService();
  
  const dispatch = useDispatch();
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const [rows, setRows] = useState({ pageSize: ITEMS_PER_PAGE, page: 0, items: [], pageCount: 0, isRefreshing: true });

  //const history = useHistory();
  const navigate = useNavigate();
  const paginationClasses = usePaginationStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const [user, setUser] = useState();

  const [openEditAgente, setOpenEditAgente] = useState(false);
  const handleCloseEditAgente = () => setOpenEditAgente(false);
  const handleOpenEditAgente = () => setOpenEditAgente(true);

  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    load(rows.page);
  }
  const handleOpenEdit = (user) => {
    setUser(user.id);
    setOpenEdit(true);
    closeUserMenu(user);
  };
  const [openDel, setOpenDel] = useState(false);
  const handleCloseDel = () => setOpenDel(false);
  const handleOpenDel = (user) => {
    setUser(user);
    setOpenDel(true);
    closeUserMenu(user);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    load(0);
  }
  const handleOpenAdd = () => {
    setOpenAdd(true);
    closeUserMenu();
  }

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  useEffect(() => {
    load(0);
  }, []);

  const load = async (page) => {
    setRows({...rows, isRefreshing: true});
    try {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/users/${state.agent ? state.agent.id : state.user.id}/customers?count=${rows.pageSize}&page=${page}`, axiosConfig);
      if (res.data.success !== 1) throw res.data.message;

      let pageCount = Math.ceil(res.data.data.itemsCount / ITEMS_PER_PAGE);
      setRows({ ...rows, items: res.data.data.items, itemsCount: res.data.data.itemsCount, page: page, pageCount: pageCount, isRefreshing: false });
    }
    catch (e) {
      console.log(e);

      setRows({...rows, isRefreshing: false});
    }
  }

  const showUserMenu = (customer) => {
    document.querySelectorAll(`div.dropdown:not(div.dropdown_agent_${customer.id})`).forEach(e => {
      e.classList.remove("show");
      e.querySelector(`ul.dropdown-menu`).classList.remove("show");
    });

    let dropdown = document.querySelector(`div.dropdown_agent_${customer.id}`);
    let dropdownMenu = dropdown.querySelector(`ul.dropdown-menu`);

    dropdown.classList.toggle("show");
    dropdownMenu.classList.toggle("show");
  }

  const closeUserMenu = () => {
    document.querySelectorAll(`div.dropdown`).forEach(e => {
      e.classList.remove("show");
      e.querySelector(`ul.dropdown-menu`).classList.remove("show");
    });
  }

  const resetPassword = async (customer) => {
    //setLoading(true);
    let res = await axios.post(`${process.env.REACT_APP_API_URL}/customers/generate-password`, {
      id: customer.id,
    }, axiosConfig);
      
    res.data.success == 1 && dispatch(baseActions.setSuccess(true));
    res.data.success === 1 && alert("La password è stata reimpostata con successo. L'utente riceverà un'email con la nuova password.");
      
    //setLoading(false);
    closeUserMenu();
  }

  const getScontistica = (customer) => {
    let scontistica = "";
    if (customer.discount1 && customer.discount1 > 0) {
      scontistica += customer.discount1;
    }
    if (customer.discount2 && customer.discount2 > 0) {
      scontistica += " + " + customer.discount2;
    }
    if (customer.discount3 && customer.discount3 > 0) {
      scontistica += " + " + customer.discount3;
    }
    return scontistica;
  }

  const createQuickConfiguration = async () => {
    try {
      setRows({...rows, isRefreshing: true});

      let res = await configurationsService.createQuickConfiguration(state.user.token, {});
      dispatch(baseActions.clearStorage());
      dispatch(baseActions.setQuoteId(res.id));
      dispatch(baseActions.setCustomer({id: res.customer.id, lastName: res.customer.surname, firstName: res.customer.name, isServiceCustomer: true}));
      navigate("/products");
    } catch (e) {
      setRows({...rows, isRefreshing: false});
    }
  }

  if (!rows) return <Loading />;

  return (
    <I18n locale={state.locale}>
      <LayoutPage>
        <div style={{flex: "0 0 auto"}}>
          <TopBar />
          <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{lineHeight: "1"}}>
              <h2 className="h2 fw-bold">
                {translate("Clienti")}              
              </h2>
              {state.agent && <span className="badge bg-dark" style={{fontSize: ".6rem"}}>{state.agent.firstName} {state.agent.lastName}</span>}
              {state.user.userType == "Agente" && <span className="badge bg-dark" style={{fontSize: ".6rem"}}>{state.user.firstName} {state.user.lastName}</span>}
            </div>
            <div>             
              {(state.agent || state.user.userType == "Admin" || state.user.userType == "AreaManager") && <Link to="/agenti" className="btn btn-secondary me-2">
                <ArrowBackIcon />
              </Link>}            
              {state.user.userType == "Agente" && (
                <button type="button" className="btn btn-primary me-2" onClick={() => createQuickConfiguration()}>
                  <AddIcon />
                  <span className="ms-1">NUOVA CONFIGURAZIONE ANONIMA</span>
                </button>
              )}
              {(state.user.userType == "Admin" || state.user.userType == "AreaManager" || state.user.userType == "Agente") && (
                <>                
                  {/* <Link to="/clienti-archiviati" className="btn btn-secondary me-2">
                    <ArchiveIcon />
                    {translate("CLIENTI_ARCHIVIATI")}
                  </Link>
                  <button className="btn btn-primary" type="button" onClick={handleOpenAdd}>
                    <AddIcon /> 
                    NUOVO CLIENTE
                  </button>*/}
                </>
              )}
            </div>
          </div>
        </div>
          
        <div style={{flex: "1 1 auto", overflowY: "auto"}}>
          <div className="w-full pb-3">
            <table className="table table-hover table-stripped">
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Cognome</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Località</th>
                  <th className="text-center">Scontistica</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rows.items.map(customer => (
                  <tr key={customer.id} style={{ cursor: "pointer" }} onClick={() => {
                    // dispatch(baseActions.setName(customer.name));
                    // dispatch(baseActions.setLastname(customer.surname));
                    //dispatch(baseActions.setId(customer.id));
                    dispatch(baseActions.setCustomer({id: customer.id, lastName: customer.surname, firstName: customer.name, isServiceCustomer: false}));
                    navigate("/configurations");
                  }}>
                    <td className="align-middle">{customer.code}</td>
                    <td className="align-middle">{customer.surname}</td>
                    <td className="align-middle">{customer.name}</td>
                    <td className="align-middle">{customer.email}</td>
                    <td className="align-middle">{customer.city}</td>
                    <td className="align-middle text-center"><span className="badge rounded-pill bg-light text-dark">{getScontistica(customer)}</span></td>
                    <td className="text-end">
                      <div className={`dropdown dropdown_agent_${customer.id}`}>
                        <button type="button" className="btn btn-light btn-sm" onClick={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          showUserMenu(customer);
                        }}>
                          <MoreVertIcon />
                        </button>

                        <ul className="dropdown-menu" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 40px)" }}>
                          {/* <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpenEdit(customer);
                            }}>
                              <EditIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("MODIFICA")}
                            </a>
                          </li> */}
                          <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              resetPassword(customer);
                            }}>
                              <LockIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("RESET_PASSWORD")}
                            </a>
                          </li>
                          {/* <li>
                            <a className="dropdown-item" onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              try {
                                let res = await axios.delete(`${process.env.REACT_APP_API_URL}/customers/${state.user.id}`, axiosConfig);
                                load(rows.page);
                              } catch (e) {

                              }
                            }}>
                              <ArchiveIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("ARCHIVIA_CLIENTE")}
                            </a>
                          </li> */}
                          {/* <li>
                            <a className="dropdown-item" onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpenDel(customer);                            
                            }}>
                              <DeleteIcon className="me-2" style={{ fontSize: "1.25rem" }} />
                              {translate("ELIMINA")}
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Pagination
              className={paginationClasses.root + " justify-content-end mb-0"}
              color="primary"
              count={rows.pageCount}
              page={rows.page + 1}
              onChange={(event, value) => load(value - 1)}
            />
          </div>
        </div>

        <ModalEdit
          title={"Nuovo cliente"}
          open={openAdd}
          handleClose={handleCloseAdd}
        >
          <ClientiForm handleClose={handleCloseAdd} />
        </ModalEdit>
        <CustomizedSnackbars open={open} setOpen={setOpen} />
        <ModalEdit
          title={"Modifica cliente"}
          open={openEdit}
          handleClose={handleCloseEdit}
        >
          <ClientiForm handleClose={handleCloseEdit} user={user} />
        </ModalEdit>
        <ModalCustom
          title={"Sicuro di eliminarlo?"}
          open={openDel}
          handleClose={handleCloseDel}
        >
          <Typography variant="h5" component="div">
            I dati eliminati non potranno essere recuperati.
          </Typography>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<CheckIcon sx={{ fontSize: 28 }} color={"white"} />}
                onClick={() =>
                  axios
                    .delete(`${process.env.REACT_APP_API_URL}/customers/${user.id}?idParent=${state.agent ? state.agent.id : state.user.id}`, axiosConfig)
                    .then((res) => {
                      if (res.data.data === 1) {
                        dispatch(baseActions.setSuccess(true));
                        handleCloseDel();
                      } else {
                        alert("Ops...riprovare");
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                    })
                }
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                SI
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                color={"secondary"}
                onClick={handleCloseDel}
                startIcon={<CloseIcon sx={{ fontSize: 28 }} color={"white"} />}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                NO
              </Button>
            </Grid>
          </Grid>
        </ModalCustom>

        {rows.isRefreshing && <LoadingInternal />}
      </LayoutPage>
    </I18n>
  );
};

export default CartellaAgente;


/*
<Button
                    style={{marginRight: "4px"}}
                    onClick={() => {
                      console.log(state.id);
                      axios
                        .delete(`${process.env.REACT_APP_API_URL}/customers/${state.id}`, axiosConfig)
                        .then((res) => res.data.success === 1 && navigate(-1))
                        .catch(function (error) {
                          console.log(error);
                        });
                    }}
                    startIcon={<ArchiveIcon />}
                    color={"secondary"}
                    sx={{ color: "white", fontWeight: "bold" }}
                    variant="contained"
                    size="large">
                    {translate("ARCHIVIA_CLIENTE")}
                  </Button>
*/