import { useCategoriesApi } from "../api/categories_api";
import { Category } from "../models/category";
import { User } from "../models/user"
import { PaginationResult } from './../core/common_types';

export const useCategoriesService = () => {
    const categoriesApi = useCategoriesApi();
    return {
        async findActiveCategories(currentUser: User): Promise<Category[]> {
            let result = await categoriesApi.findActiveCategories(currentUser);

            if (!result.success) {
                throw result.message;
            }

            return result.data ? result.data : [];
        }
    }
}