import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    //token: 0,
    //type: "",
    thumb: "",
    locale: {},
    //name: "",
    //nameLogin: "",
    //lastname: "",
    //lastnameLogin: "",
    id: null,
    areaManager: null,
    agent: null,
    customer: null,
    user: null,    
    quoteId: null,
    idPorta: null,
    idInstallazioneTraversa: null,
    idInstallazioneMontanteDx: null,
    idInstallazioneMontanteSx: null,
    idTipoPorta: null,
    idTipoFinitura: null,
    idVerniciaturaManiglia: null,
    success: false,
    dimensioni: false,
    aspetto: false,
    finestre: false,
    accessori: false,
    maniglierie: false,
    richiesta: false,
    finestrature: false,
    ventilazione: false,
    elementiFissi: false,
    portinaPedonale: false,
    portinaPedonaleAffiancata: false,
    altezza: "",
    larghezza: "",
    architrave: "",
    spallettaDestra: "",
    spallettaSinistra: "",
    SMuroSuperiore: "",
    SMuroSinistro: "",
    SMuroDestro: "",
    PGarage: "",
    radioM: 0,
    selectQtaF: true,
    radioF: 0,
    qtaF: 0,
    radioI: "",
    radioA: "",
    radioV: 0,
    qtaV: 0,
    fullglass: false,
    //bedgeLetter: "",
    altezzaPortina: "",
    larghezzaPortina: "",
    posizione: [],
    idEsterni: 0,
    idModel: 0,
    selectM: false,
    selectedD: false,
    isDebordante: false,
    isPorticina: false,
    imgFinestra: {
        id: 0,
        img: "",
    },
    imgGriglia: {
        id: 0,
        img: "",
    },
    elementiCheckbox: false,
    isActiveIntegrale: false,
    isActiveAffiancata: false,
    colManiglia: {
        name: "",
        img: "",
    },
    H: "",
    L: "",
    nameFinestra: "",
    nameGriglia: "",
    campioneInterno: false,
    campioneEsterno: false,
};

const reducers = {
    clearStorage(state: any) {
        state.quoteId = null;
        state.dimensioni = false;
        state.aspetto = false;
        state.finestre = false;
        state.accessori = false;
        state.richiesta = false;
        state.maniglierie = false;
        state.finestrature = false;
        state.ventilazione = false;
        state.elementiFissi = false;
        state.portinaPedonale = false;
        state.portinaPedonaleAffiancata = false;
        state.idInstallazioneTraversa = null;
        state.idInstallazioneMontanteDx = null;
        state.idInstallazioneMontanteSx = null;
        state.idTipoPorta = null;
        state.idTipoFinitura = null;
        state.idVerniciaturaManiglia = null;
        state.altezza = "";
        state.larghezza = "";
        state.architrave = "";
        state.spallettaDestra = "";
        state.spallettaSinistra = "";
        state.SMuroSuperiore = "";
        state.SMuroDestro = "";
        state.SMuroSinistro = "";
        state.PGarage = "";
        state.selectQtaF = true;
        state.radioF = 0;        
        state.qtaF = 0;
        state.radioA = "";
        state.radioI = "";
        state.radioM = 0;
        state.radioV = 0;
        state.qtaV = 0;
        state.altezzaPortina = "";
        state.larghezzaPortina = "";
        state.posizione = [];
        state.idEsterni = 0;
        state.idModel = 0;
        state.selectM = 1;
        state.selectedD = false;
        state.isDebordante = false;
        state.isPorticina = false;
        state.imgFinestra = {
            id: 0,
            img: "",
        };
        state.imgGriglia = {
            id: 0,
            img: "",
        };
        state.elementiCheckbox = false;
        state.isActiveIntegrale = false;
        state.isActiveAffiancata = false;
        state.colManiglia = {
            name: "",
            img: "",
        };
        state.L = "";
        state.H = "";
        state.nameFinestra = "";
        state.nameGriglia = "";
        state.campioneInterno = false;
        state.campioneEsterno = false;
    },
    setCampioneInterno(state: any, action: PayloadAction<any>) {
        state.campioneInterno = action.payload;
    },
    setCampioneEsterno(state: any, action: PayloadAction<any>) {
        state.campioneEsterno = action.payload;
    },
    setNameFinestra(state: any, action: PayloadAction<any>) {
        state.nameFinestra = action.payload;
    },
    setNameGriglia(state: any, action: PayloadAction<any>) {
        state.nameGriglia = action.payload;
    },
    setH(state: any, action: PayloadAction<any>) {
        state.H = action.payload;
    },
    setL(state: any, action: PayloadAction<any>) {
        state.L = action.payload;
    },
    setColManiglia(state: any, action: PayloadAction<any>) {
        state.colManiglia = { name: action.payload.name, img: action.payload.img };
    },
    setElementiCheckbox(state: any, action: PayloadAction<any>) {
        state.elementiCheckbox = action.payload;
    },
    setIsActiveIntegrale(state: any, action: PayloadAction<any>) {
        state.isActiveIntegrale = action.payload;
    },
    setIsActiveAffiancata(state: any, action: PayloadAction<any>) {
        state.isActiveAffiancata = action.payload;
    },
    setImgGriglia(state: any, action: PayloadAction<any>) {
        state.imgGriglia = { id: action.payload.id, img: action.payload.img };
    },
    setImgFinestra(state: any, action: PayloadAction<any>) {
        state.imgFinestra = { id: action.payload.id, img: action.payload.img };
    },
    setIsDebordante(state: any, action: PayloadAction<any>) {
        state.isDebordante = action.payload;
    },
    setIsPorticina(state: any, action: PayloadAction<any>) {
        state.isPorticina = action.payload;
    },
    setIsPorticinaAffiancata(state: any, action: PayloadAction<any>) {
        state.isPorticinaAffiancata = action.payload;
    },
    setSelectM(state: any, action: PayloadAction<any>) {
        state.selectM = action.payload;
    },
    setSelectedD(state: any, action: PayloadAction<any>) {
        state.selectedD = action.payload;
    },
    setIdModel(state: any, action: PayloadAction<any>) {
        state.idModel = action.payload;
    },
    setIdEsterni(state: any, action: PayloadAction<any>) {        
        state.idEsterni = action.payload;
    },
    setPosizione(state: any, action: PayloadAction<any>) {
        state.posizione = action.payload;
    },
    clearPosizione(state: any, action: PayloadAction<any>) {
        state.posizione = [];
    },
    setSMuroSinistro(state: any, action: PayloadAction<any>) {
        state.SMuroSinistro = action.payload;
    },
    setSMuroDestro(state: any, action: PayloadAction<any>) {
        state.SMuroDestro = action.payload;
    },
    setPGarage(state: any, action: PayloadAction<any>) {
        state.PGarage = action.payload;
    },
    setSMuroSuperiore(state: any, action: PayloadAction<any>) {
        state.SMuroSuperiore = action.payload;
    },
    setAltezzaPortina(state: any, action: PayloadAction<any>) {
        state.altezzaPortina = action.payload;
    },
    setLarghezzaPortina(state: any, action: PayloadAction<any>) {
        state.larghezzaPortina = action.payload;
    },
    // setBedgeLetter(state: any, action: PayloadAction<any>) {
    //     state.bedgeLetter = action.payload;
    // },
    setFullglass(state: any, action: PayloadAction<any>) {
        state.fullglass = action.payload;
    },
    setRadioA(state: any, action: PayloadAction<any>) {
        state.radioA = action.payload;
    },
    setRadioV(state: any, action: PayloadAction<any>) {
        state.radioV = action.payload;
    },
    setQtaV(state: any, action: PayloadAction<any>) {
        state.qtaV = action.payload;
    },
    setRadioI(state: any, action: PayloadAction<any>) {
        state.radioI = action.payload;
    },
    setSelectQtaF(state: any, action: PayloadAction<any>) {
        state.selectQtaF = action.payload;
    },
    setRadioF(state: any, action: PayloadAction<any>) {
        state.radioF = action.payload;
    },
    setQtaF(state: any, action: PayloadAction<any>) {
        state.qtaF = action.payload;
    },
    setRadioM(state: any, action: PayloadAction<any>) {
        state.radioM = action.payload;
    },
    setSpallettaSinistra(state: any, action: PayloadAction<any>) {
        state.spallettaSinistra = action.payload;
    },
    setSpallettaDestra(state: any, action: PayloadAction<any>) {
        state.spallettaDestra = action.payload;
    },
    setArchitrave(state: any, action: PayloadAction<any>) {
        state.architrave = action.payload;
    },
    setLarghezza(state: any, action: PayloadAction<any>) {
        state.larghezza = action.payload;
    },
    setAltezza(state: any, action: PayloadAction<any>) {
        state.altezza = action.payload;
    },
    setManiglierie(state: any, action: PayloadAction<any>) {
        state.maniglierie = action.payload;
    },
    setFinestrature(state: any, action: PayloadAction<any>) {
        state.finestrature = action.payload;
    },
    setVentilazione(state: any, action: PayloadAction<any>) {
        state.ventilazione = action.payload;
    },
    setElementiFissi(state: any, action: PayloadAction<any>) {
        state.elementiFissi = action.payload;
    },
    setPortinaPedonale(state: any, action: PayloadAction<any>) {
        state.portinaPedonale = action.payload;
    },
    setPortinaPedonaleAffiancata(state: any, action: PayloadAction<any>) {
        state.portinaPedonaleAffiancata = action.payload;
    },
    // setToken(state: any, action: PayloadAction<any>) {
    //     state.token = action.payload;
    // },
    // setType(state: any, action: PayloadAction<any>) {
    //     state.type = action.payload;
    // },
    setThumb(state: any, action: PayloadAction<any>) {
        state.thumb = action.payload;
    },
    setLocale(state: any, action: PayloadAction<any>) {
        state.locale = action.payload;
    },
    // setName(state: any, action: PayloadAction<any>) {
    //     state.name = action.payload;
    // },
    // setNameLogin(state: any, action: PayloadAction<any>) {
    //     state.nameLogin = action.payload;
    // },
    // setLastname(state: any, action: PayloadAction<any>) {
    //     state.lastname = action.payload;
    // },
    // setLastnameLogin(state: any, action: PayloadAction<any>) {
    //     state.lastnameLogin = action.payload;
    // },
    setUser(state: any, action: PayloadAction<any>) {
        state.user = action.payload;
    },
    setAreaManager(state: any, action: PayloadAction<any>) {
        state.areaManager = action.payload;
    },
    setAgent(state: any, action: PayloadAction<any>) {
        state.agent = action.payload;
    },
    setCustomer(state: any, action: PayloadAction<any>) {
        state.customer = action.payload;
    },
    setId(state: any, action: PayloadAction<any>) {
        state.id = action.payload;
    },
    setQuoteId(state: any, action: PayloadAction<any>) {
        state.quoteId = action.payload;
    },
    setIdPorta(state: any, action: PayloadAction<any>) {
        state.idPorta = action.payload;
    },
    setIdInstallazioneTraversa(state: any, action: PayloadAction<any>) {
        state.idInstallazioneTraversa = action.payload;
    },
    setIdInstallazioneMontanteDx(state: any, action: PayloadAction<any>) {
        state.idInstallazioneMontanteDx = action.payload;
    },
    setIdInstallazioneMontanteSx(state: any, action: PayloadAction<any>) {
        state.idInstallazioneMontanteSx = action.payload;
    },
    setIdTipoPorta(state: any, action: PayloadAction<any>) {
        state.idTipoPorta = action.payload;
    },
    setIdTipoFinitura(state: any, action: PayloadAction<any>) {
        state.idTipoFinitura = action.payload;
    },
    setIdVerniciaturaManiglia(state: any, action: PayloadAction<any>) {
        state.idVerniciaturaManiglia = action.payload;
    },
    setSuccess(state: any, action: PayloadAction<any>) {
        state.success = action.payload;
    },
    setDimensione(state: any, action: PayloadAction<any>) {
        state.dimensioni = action.payload;
    },
    setAspetto(state: any, action: PayloadAction<any>) {
        state.aspetto = action.payload;
    },
    setFinestra(state: any, action: PayloadAction<any>) {
        state.finestre = action.payload;
    },
    setAccessori(state: any, action: PayloadAction<any>) {
        state.accessori = action.payload;
    },
    setRichiesta(state: any, action: PayloadAction<any>) {
        state.richiesta = action.payload;
    },
    setUserPasswordChanged(state: any, action: PayloadAction<any>) {
        state.user.requirePasswordChange = false;
    },
    logoutUser(state: any, action: PayloadAction<any>) {
        state.user = null;
        state.agent = null;
        state.areaManager = null;
        state.customer = null;
        window.localStorage.clear();
    },
    init(state: any, action: PayloadAction<any>) {        
        state.idInstallazioneTraversa = action.payload.idInstallazioneTraversa;
        state.idInstallazioneMontanteDx = action.payload.idInstallazioneMontanteDx;
        state.idInstallazioneMontanteSx = action.payload.idInstallazioneMontanteSx;
        state.idTipoPorta = action.payload.idTipoPorta;
        state.idModel = action.payload.idModel;
        state.idTipoFinitura = action.payload.idTipoFinitura;
        state.idVerniciaturaManiglia = action.payload.idVerniciaturaManiglia;
        state.idEsterni = action.payload.idEsterni;
    },
    resetSteps(state: any, action: PayloadAction<any>) {
        if (!action.payload) return;
        if (!action.payload.steps) return;

        let currentStep = action.payload.steps.find((s: any) => s.slug == action.payload.currentLocation);
        let currentStepPos = currentStep ? currentStep.position : -1;

        action.payload.steps.forEach((i: any) => {
            if (i.position <= currentStepPos) return;
            if (i.slug in state) state[i.slug] = false;
        });
    }
};

const slice = createSlice({
    name: "base",
    initialState,
    reducers,
    extraReducers(builder) {
        //baseFeatures.addExtraReducers(builder);
    }
});

const { actions, reducer } = slice;
export function importBaseActions()
{
    return actions;
}
export default reducer;


/*
dispatch(baseActions.setBedgeLetter(name + lastname));
dispatch(baseActions.setToken(res.data.data.token));
dispatch(baseActions.setType(res.data.data.user.user_type));
dispatch(baseActions.setNameLogin(res.data.data.user.name));
dispatch(baseActions.setLastnameLogin(res.data.data.user.surname));
dispatch(baseActions.setUser(res.data.data.user));
dispatch(baseActions.setLocale(LOCALES.ITALIAN));
*/