import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormGroup,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import Container from "../../components/DragAndDrop/Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DialogCustom } from "../../components/Dialog/dialog";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import Box from "@mui/material/Box";
import _ from "lodash";
import box from "../../assets/img/box.PNG";
import { InputCustom } from "../../components/Input/InputCustom";
import DialogScroll from "../../components/Dialog/DialogScroll";
import FormControlLabel from "@mui/material/FormControlLabel";
import { importBaseActions } from "../../store/reducers/base";
import { Events, useEventBroadcast } from "../../core/EventBroadcast";
import { getProductName } from "../../Utility";

const Finestrature = () => {
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [openDrop, setDrop] = useState(false);
  const [imgModal, setImgModal] = useState("");
  const [selected, setSelected] = useState();
  const [idPos, setIdPos] = useState();
  const [img, setImg] = useState("");
  const [prova, setProva] = useState("");
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [color1, setColor1] = useState("");
  //const [checkColor1, setCheckColor1] = useState(false);
  const [nameColor, setNameColor] = useState("");
  const [numberItem, setNumberItem] = useState(null);
  const [reload, setReload] = useState(false);
  const [elementiCheckbox, setElementiCheckbox] = useState(state.elementiCheckbox);
  const [accModal, setAccModal] = useState();
  const [selected1, setSelected1] = useState();
  const [empty, setEmpty] = useState(false);
  const [idAcc, setIdAcc] = useState(state.radioF);
  const [next, setNext] = useState(false);
  const eventBroadcast = useEventBroadcast();
  //const [nessunaF, setNessunaF] = useState(false);

  const [stepState, setStepState] = useState({
    state: "loading", 
    currentStep: null,
    steps: [], 
    accessories: null,
    error: null
  });

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };
  const handleCloseDrop = () => {
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/save-accessories`,
    //     {
    //       qty: numberItem,
    //       id: state.radioF,
    //       quote: state.quoteId,
    //       slug: location.pathname.substring(1) + "_modal",
    //       position: state.posizione,
    //       imgModal: imgModal,
    //       selectedF: selected,
    //     },
    //     axiosConfig
    //   )
    //   .then((res) => res.data && setReload((v) => !v))
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    setDrop(false);
    setReload((v) => !v);
  };

  // const fetcher = (url) =>
  //   axios
  //     .post(url, { id: idPorta, quote_id: state.quoteId }, axiosConfig)
  //     .then((res) => res.data.data);
  // const { data, error } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/categories-steps`, state.fullglass],
  //   fetcher
  // );

  const load = async () => {
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId }, axiosConfig);      
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
      }      
    } catch (e) {
      
    }

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/get-id-accessories`, {
        quote: state.quoteId,
        slug: "finestrature_modal",
        type: "radio",
      }, axiosConfig);
      
      if (res.data.success == 1) {
        if (res.data.data && res.data.data.id) {
          dispatch(baseActions.setRadioF(res.data.data.id));
        } else {
          setEmpty(true);
        }
      }
      
      res = await axios.post(`${process.env.REACT_APP_API_URL}/accessories`, {
        id: state.idPorta,
        quote: state.quoteId,
        step: location.pathname.substring(1),
        idAcc: res.data.data && res.data.data.id ? res.data.data.id : "",
        category_id: state.idPorta,
      }, axiosConfig);

      let accessories = [];
      let pos = [];
      if (res.data.success == 1 && res.data) {
        accessories = res.data.data;
        pos = res.data.pos;

        console.log(accessories);
      }  

      setStepState({
        state: "idle",
        steps: steps, 
        accessories: accessories,
        pos: pos,
        currentStep: currentStep,
      });
    } catch (e) {
      setStepState({state: "error", error: e});
    }
  }

  const getConfPos = async (idAcc) => {
    if (!idAcc || idAcc <= 0) {
      setStepState({
        ...stepState,
        state: "idle",
        pos: []
      });
      return;
    }

    setStepState({...stepState, state: "loading_conf_pos"});
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/conf-pos`, {
        quoteId: state.quoteId,
        type: location.pathname.substring(1),
        idAcc: idAcc,
        categoryId: state.idPorta,
      }, axiosConfig);

      if (res.data.success == 1 && res.data.data) {
        setStepState({
          ...stepState,
          state: "idle",
          pos: res.data.data
        });
      }
    } catch (e) {
      setStepState({...stepState, state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  useEffect(() => {
    load();

    return handleChange();
  }, []);

  useEffect(() => {
    if (stepState.pos && stepState.pos.length > 0) {
      setQuantity(stepState.pos[0].number_item);
    }
  }, [stepState.pos]);

  // const fetcher3 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         quote: state.quoteId,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       res.data.data.position !== null &&
  //         dispatch(baseActions.setPosizione(res.data.data.position));
  //       setSelected(res.data.data.id_position_F);
  //       return res.data.data;
  //     });
  // const { data: position, error: errorPosition } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/position`, location.pathname],
  //   fetcher3
  // );

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  // const fetcher7 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         category: state.idPorta,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => res.data.data);
  // const { data: colors, error: errorColors } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/colors`],
  //   fetcher7
  // );

  const h = 4;
  //const [item, setItem] = useState(state.posizione);

  const generaPosizioneFinestre = () => {
    let res = [];
    for (let i = 0; i < h; i++) {
      res.push({
        id: i,
        id_pos: 0,
        img: "",
        applied: i == 0 && Number(state.radioF) > 0,
        type: "finestrature",
        qty: numberItem,        
        id_acc: Number(state.radioF) > 0 ? state.radioF : 0
      });
    }
    //dispatch(baseActions.setPosizione(item));
    return res;
  };

  // const fetcher4 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         quote: state.quoteId,
  //         slug: "finestrature_modal",
  //         type: "radio",
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       if (res.data.data) {
  //         res.data.data.id && dispatch(baseActions.setRadioF(res.data.data.id));
  //         res.data.data.id === 0 && setEmpty(true);
  //       }
  //       return res.data.data;
  //     });
  // const { data: getIdAccessories, error: errorGetIdAccessories } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/get-id-accessories`, location.pathname],
  //   fetcher4
  // );

  // const fetcher2 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         id: idPorta,
  //         quote: state.quoteId,
  //         step: location.pathname.substring(1),
  //         idAcc: state.radioF !== "" ? state.radioF : 0,
  //         category_id: state.idPorta,
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => {
  //       setAccModal(res.data.data);
  //       return res.data;
  //     });
  // const { data: accessories, error: errorAccessories } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/accessories`, state.radioF],
  //   fetcher2
  // );

  // const fetcher5 = (url) =>
  //   axios
  //     .post(
  //       url,
  //       {
  //         id: idPorta,
  //         quote: state.quoteId,
  //         step: "elementiFissi",
  //       },
  //       axiosConfig
  //     )
  //     .then((res) => res.data.data);
  // const { data: elementiFissi, error: errorElementiFissi } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/accessories`, location.pathname],
  //   fetcher5
  // );

  const handleChangeEL = (e) => {
    //setElementiCheckbox(e.target.checked);
    dispatch(baseActions.setElementiCheckbox(e.target.checked));
        
    // elementiFissi &&
    //   elementiFissi.map((res) => {
    //     axios
    //       .post(
    //         `${process.env.REACT_APP_API_URL}/save-accessories`,
    //         {
    //           qty: e.target.checked === true ? 1 : 0,
    //           id: res.id,
    //           quote: state.quoteId,
    //           slug: "elementiFissi",
    //         },
    //         axiosConfig
    //       )
    //       .then((res) => res.data)
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   });
  };

  const setFinestratura = (finestratura) => {    
    //setIdAcc(res.id);
    //setSelected1(index);
    let id = finestratura ? finestratura.id : 0;

    let qtaF = 0;
    let selectQtaF = true;
    if (finestratura && (finestratura.sku == "ACCF09" || finestratura.sku == "ACCF47" || finestratura.sku == "ACCF76")) {
      qtaF = 1;
      selectQtaF = false;
      setNumberItem(qtaF);
    } else {
      setNumberItem(0);
      setSelected(null);
      setImgModal(null);
      setIdPos(null);
      dispatch(baseActions.setQtaF(0));

      getConfPos(id);
    }

    setEmpty(id == 0);
    dispatch(baseActions.setSelectQtaF(selectQtaF));
    dispatch(baseActions.setRadioF(id));
    dispatch(baseActions.setQtaF(qtaF));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...state,
      radioF: id,
      qtaF: qtaF
    });

    setStepState({
      ...stepState,
      state: "idle",
      error: null
    });
  }

  const saveStepState = async () => {
    if (state.qtaF <= 0 && state.radioF > 0) {
      setStepState({
        ...stepState,
        state: "error_num_finestre"
      });
      return;
    }

    setStepState({
      ...stepState,
      state: "save"
    });
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, { 
        qty: 0,
        id: 0,
        quote: state.quoteId,
        slug: location.pathname.substring(1),        
      }, axiosConfig);

      if (res.data.success == 1) {
        let posizione = generaPosizioneFinestre();
        
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-accessories`, {
          qty: numberItem,
          id: state.radioF,
          quote: state.quoteId,
          slug: location.pathname.substring(1) + "_modal",
          position: posizione,//state.posizione,
          imgModal: imgModal,
          selectedF: Number(state.radioF) > 0,
        }, axiosConfig);

        if (res.data.success == 1) {
          dispatch(baseActions.setFinestrature(true));
          stepState.steps.splice(5, 1);
          stepState.steps.map((el, index) => {
            `/${el.slug}` === location.pathname &&
              navigate(
                `/${stepState.steps[index + 1] && stepState.steps[index + 1].slug}`,
                {
                  id: state.idPorta,
                }
              );
          });
        } else {
          setStepState({
            ...stepState,
            state: "error_vincolo",
            error: res.data.message
          });
        }
      }
    } catch (e) {
      console.log(e);

      setStepState({
        ...stepState,
        state: "idle"
      });
    }
  }

  const setQuantity = (qty) => {
    setNumberItem(qty);
    setSelected(qty);
    //setImgModal(res.img_modal);
    setIdPos(qty);
    dispatch(baseActions.setQtaF(qty));
    eventBroadcast.dispatch(Events.configurationChanged, {
      ...state,
      qtaF: qty
    });
  }

  console.log(state.radioF, next);

  if ("error" == stepState.state) {
    return <div>failed to load</div>;
  }

  if ("loading" == stepState.state) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
          </Typography>
        </Grid>
        <div>
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
        
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurations");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {stepState.currentStep && <TabsCustom
            value={stepState.currentStep.id}
            setValue={setValue}
            id={state.idPorta}
            count={state}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
        <GridPreventivi
          header={null
            // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
            //   <div className="col-span-4 text-center">
            //     <Typography variant={"h4"} fontWeight={700} mt={5}>
            //       Finestrature
            //     </Typography>
            //   </div>
            // </div>
          }
          dataSend={{
            quote_id: state.quoteId,
            category_id: state.idPorta,
            slug: location.pathname.substring(1),
            idAcc: state.radioF,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={reload}>
          {"loading" == stepState.state ? (
            <CircularProgress />
          ) : (
            <>
              <div className={"grid grid-cols-8 mt-6"}>
                <div
                  onClick={() => setFinestratura(null)}
                  className={"col-start-2 col-span-6 flex items-center cursor-pointer"}>
                  <div style={{flex: "0 0 auto"}}>
                    <img
                      src={box}
                      alt={"img"}
                      style={
                        empty || state.radioF === 0
                          ? {
                              width: 80,
                              height: 70,
                              border: "3px solid #dc3d22",
                            }
                          : {
                              width: 80,
                              height: 70,
                              "&:hover": {
                                border: "3px solid #dc3d22",
                              }
                            }
                      }
                    />
                  </div>
                  <div className="p-4" style={{flex: "1 1 auto"}}>
                    <Typography
                      sx={(empty || state.radioF === 0) ? { color: "#dc3d22" } : {}}
                      variant={"h7"}>
                      Nessuna finestra
                    </Typography>
                  </div>
                  <div style={{flex: "0 0 auto"}}>
                    <Radio checked={empty || state.radioF === 0} />
                  </div>
                </div>
                {stepState.accessories.map((res, index) => {
                  if (res.type === "radio") {
                    return (
                      <div
                        onClick={() => setFinestratura(res)}
                        key={res.id + index}
                        className={
                          "col-start-2 col-span-6 flex items-center cursor-pointer"
                        }>
                        <div style={{flex: "0 0 auto"}}>
                          <img
                            src={res.img}
                            alt={"img"}
                            style={
                              (selected1 === index ||
                                state.radioF === res.id) &&
                              !empty
                                ? {
                                    width: 80,
                                    height: 70,
                                    border: "3px solid #dc3d22",
                                  }
                                : {
                                    width: 80,
                                    height: 70,
                                    "&:hover": {
                                      border: "3px solid #dc3d22",
                                    },
                                  }
                            }
                          />
                        </div>
                        <div className="p-4" style={{flex: "1 1 auto"}}>
                          <Typography
                            sx={
                              (selected1 === index || state.radioF === res.id) &&
                              !empty ? {
                                color: "#dc3d22",
                              } : {}
                            }
                            variant={"h7"}>
                            {res.name}
                          </Typography>
                        </div>
                        <div style={{flex: "0 0 auto"}}>
                          <Radio checked={ (selected1 === index || state.radioF === res.id) && !empty } />
                        </div>
                      </div>
                    );
                  }
                })}
              
                <div className="col-start-2 col-span-6">
                  {!empty && stepState.accessories && (
                    <>
                      <FormGroup sx={{ mt: 6, display: "none" }}>
                        {stepState.accessories.map((res) => {
                          return (
                            <div key={res.id}>
                              {res.type === "checkbox" &&
                                res.id !== 16 &&
                                res.id !== 17 && (
                                  <Box
                                    sx={{
                                      display: res.sku.startsWith("ACCF02")
                                        ? "block"
                                        : "flex",
                                      flexDirection: res.sku.startsWith("ACCF02")
                                        ? "column"
                                        : "row",
                                      alignItems: res.sku.startsWith("ACCF02")
                                        ? "flex-start"
                                        : "center",
                                      justifyContent: res.sku.startsWith("ACCF02")
                                        ? "flex-start"
                                        : "center",
                                    }}>
                                    <CheckboxCustom
                                      finestre={state.radioF}
                                      selected={
                                        state.radioF === 12 &&
                                        stepState.accessories.ralIdPorta !== 9016
                                          ? 13
                                          : state.radioF === 1535 &&
                                            stepState.accessories.ralIdPorta !== 9016
                                            ? 0
                                            : res.selected
                                      }
                                      value={res.sku}
                                      name={res.sku}
                                      label={res.name}
                                      axiosConfig={axiosConfig}
                                      idAcc={res.id}
                                      quote={state.quoteId}
                                      disabled={
                                        ((state.radioF === 12 ||
                                          state.radioF === 1535) &&
                                          res.id === 13 &&
                                          stepState.accessories.ralIdPorta !== 9016) ||
                                        (state.radioF === 1535 &&
                                          res.id === 982 &&
                                          stepState.accessories.ralIdPorta !== 9016)
                                      }
                                    />
                                    {res.sku.startsWith("ACCF02") && (
                                      <>
                                        <Typography
                                          color={"primary"}
                                          sx={{
                                            lineHeight: 0,
                                            marginTop: 2,
                                          }}>
                                          Colore
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: 2,
                                          }}>
                                          <InputCustom
                                            name={"larghezza"}
                                            value={prova}
                                            placeholder={"Inserisci il codice"}
                                            onChange={(e) => {
                                              setProva(e.target.value);
                                              axios
                                                .post(
                                                  `${process.env.REACT_APP_API_URL}/get-accessories-notstandard`,
                                                  {
                                                    color: e.target.value,
                                                    base: "ACCF02",
                                                    quote_id: state.quoteId,
                                                    slug: location.pathname.substring(
                                                      1
                                                    ),
                                                  },
                                                  axiosConfig
                                                )
                                                .then((res) =>
                                                  setImg(res.data.data.img)
                                                )
                                                .catch(function (error) {
                                                  console.log(error);
                                                });
                                            }}
                                          />
                                          <img
                                            style={{
                                              width: 90,
                                              height: 70,
                                              marginTop: 12,
                                              padding: 2,
                                              marginLeft: 6,
                                            }}
                                            src={img ? img : box}
                                            alt={"imgLeft"}
                                          />
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                )}
                            </div>
                          );
                        })}
                      </FormGroup>

                      {state.selectQtaF && state.radioF && state.radioF > 0 ? (
                        <>
                          <Typography color={"primary"} sx={{ marginTop: 3 }}>
                            Selezionare il numero di finestre
                          </Typography>                          
                          <div className={"mt-3"}>
                            <TextField
                              select
                              fullWidth
                              label={"Quantità"}
                              name={"qty"}
                              placeholder={"Quantità"}
                              value={state.qtaF}
                              onChange={(event) => setQuantity(event.target.value)}>
                              {stepState.pos.map((res, index) => {
                                return <MenuItem value={res.number_item}>{res.number_item}</MenuItem>;
                              })}
                            </TextField>                            
                          </div>

                          {(imgModal || selected !== null) && (
                            <div
                              onClick={() => {
                                if (imgModal === "") {
                                  setNumberItem(stepState.accessories.pos[selected].number_item);
                                  setImgModal(stepState.accessories.pos[selected].img_modal);
                                  setIdPos(stepState.accessories.pos[selected].number_item);
                                  setDrop(true);
                                } else setDrop(true);
                              }}
                              className={
                                "flex flex-row justify-end items-center mt-4 cursor-pointer"
                              }>
                              {/* AT - 
                              LO DISATTIVIAMO UN ATTIMO 
                              <Typography variant={"h6"}>Posizione Finestre</Typography>
                              <IconButton>
                                <PictureInPictureIcon
                                  sx={{ width: 30, height: 30 }}
                                  color={"primary"}
                                />
                              </IconButton>
                              */}
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </div>

              <DialogCustom
                open={openDrop}
                handleClose={handleCloseDrop}
                title={"Posizionamento finestre"}
                fullScreen={false}
                content={
                  <DndProvider backend={HTML5Backend}>
                    <Container
                      numberItem={numberItem}
                      idAcc={state.radioF}
                      img={imgModal}
                      idPos={idPos}
                    />
                  </DndProvider>
                }
              />

              {/*<DialogScroll
                open={open}
                setOpen={setOpen}
                scroll={scroll}
                setScroll={setScroll}
                data={colors}
                setNameColor={setNameColor}
                axios_config={axiosConfig}
                color1={color1}
                endpoint={"save-accessories"}
                setColor1={setColor1}
                quote_id={state.quoteId}
                checkColor1={false}
              />*/}

              {"error_num_finestre" == stepState.error && state.radioF > 0 && (
                <div className={"grid grid-cols-8 mt-6 gap-4"}>
                  <div className="col-start-2 col-span-6">
                    <Alert severity="error">Selezionare il numero di finestre da aggiungere</Alert>
                  </div>
                </div>
              )}

              {"error_vincolo" == stepState.state && (
                <div className={"grid grid-cols-8 mt-6 gap-4"}>
                  <div className="col-start-2 col-span-6">
                    <Alert severity="warning">{stepState.error}</Alert>
                  </div>
                </div>
              )}

              <div className={"grid grid-cols-8 mt-6 gap-4"}>
                <div className="col-start-2 col-span-3">
                  <Button
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      py: 1,
                    }}
                    onClick={() => {
                      stepState.steps &&
                      stepState.steps.map((el, index) => {
                        `/${el.slug}` === location.pathname &&
                          navigate(
                            `/${stepState.steps[index - 1] && stepState.steps[index - 1].slug}`,
                            {
                              id: state.idPorta,
                            }
                          );
                      });
                    }}>
                    INDIETRO
                  </Button>
                </div>

                <div className="col-span-3">
                  {"save" == stepState.state ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      fullWidth
                      sx={{ color: "white", fontWeight: "bold", py: 1 }}
                      variant={"contained"}
                      onClick={() => saveStepState()}>
                      AVANTI
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </GridPreventivi>
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Finestrature;
