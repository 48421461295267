import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { makeStyles } from "@mui/styles";
import ArchiveIcon from "@mui/icons-material/Archive";
import AddIcon from "@mui/icons-material/Add";
import { ModalEdit } from "../../components/Modal/ModalEdit";
import AgentiForm from "../../components/Formik/agentiForm";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import useSWR from "swr";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ClientiForm from "../../components/Formik/clientiForm";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PaginationButton from "../../components/Paginazione/PaginationButton";
import { useNavigate } from "react-router-dom";

const Item = ({ name, lastname, id }) => {
  //const history = useHistory();
  const navigate = useNavigate();
  return (
    <Button
      onClick={() =>
        navigate("/configurations", {
          name: name,
          lastname: lastname,
          id: id,
          archiviato: true,
        })
      }
      startIcon={<FolderIcon color={"primary"} />}
      fullWidth
      sx={{
        backgroundColor: "#fff",
        color: "#262626",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#262626",
        },
        justifyContent: "flex-start",
      }}
      variant="contained"
      size="large"
    >
      <Typography
        sx={{ textTransform: "none" }}
        fontWeight={700}
        variant="h6"
        component="div"
      >
        {name}
      </Typography>
    </Button>
  );
};

const ClientiArchiviati = ({ count, dispatch }) => {
  //const history = useHistory();
  const navigate = useNavigate();
  const [select, setSelect] = useState("nome_asc");
  const [openAdd, setOpenAdd] = useState(false);
  const handleCloseAdd = () => setOpenAdd(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const handleClick = () => setOpen(true);
  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  const fetcher = (url) =>
    axios
      .request({
        method: "POST",
        url: url,
        headers: {
          Authorization: "Bearer " + count.user.token,
        },
        params: {
          id: count.user.id,
          select: select,
          page: page,
        },
      })
      .then((res) => res.data.data);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_API_URL}/customers/archive`, count.success, select, page],
    fetcher
  );

  useEffect(() => {
    count.success === true && handleClick();
    dispatch.count.setSuccess(false);
  }, [count.success]);

  if (error) {
    console.log("AT - clientiArchiviati");
    return <div>failed to load</div>;
  }

  if (!data)
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <LayoutPage>
        <TopBar />
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            mx: 4,
            my: 2,
          }}
          spacing={2}
        >
          <Grid item xs={12} md={8}>
            <Grid container sx={{ alignItems: "center" }}>
              <Typography
                fontWeight={700}
                variant="h4"
                // color={"#262626"}
                component="div"
              >
                Clienti archiviati
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
            <Button
              onClick={() => navigate("/clienti")}
              startIcon={<ArchiveIcon />}
              color={"secondary"}
              sx={{ color: "white", fontWeight: "bold" }}
              variant="contained"
              size="large"
            >
              CLIENTI ATTIVI
            </Button>
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
            <Button
              onClick={handleOpenAdd}
              startIcon={<AddIcon />}
              color={"primary"}
              sx={{ color: "white", fontWeight: "bold" }}
              variant="contained"
              size="large"
            >
              NUOVO CLIENTE
            </Button>
          </Grid>
        </Grid>
        {/*  MODAL ADD CLIENTE */}
        <ModalEdit
          title={"Nuovo cliente"}
          open={openAdd}
          handleClose={handleCloseAdd}
        >
          <ClientiForm handleClose={handleCloseAdd} />
        </ModalEdit>
        <Grid
          container
          sx={{
            flex: 1,
            justifyContent: "flex-end",
            textAlign: "right",
            // px: 4,
            py: 2,
          }}
        >
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <FormControl sx={{ width: "90%" }}>
              <InputLabel>Ordina per</InputLabel>
              <Select
                style={{ textAlign: "left" }}
                value={select}
                label="Ordina per"
                onChange={handleChange}
              >
                <MenuItem value={"nome_asc"}>
                  Nome
                  <IconButton disabled value={"nome_asc"}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </MenuItem>
                <MenuItem value={"nome_desc"}>
                  Nome
                  <IconButton disabled value={"nome_desc"}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </MenuItem>
                <MenuItem value={"last_insert_asc"}>
                  Ultimo inserito
                  <IconButton disabled value={"last_insert_asc"}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </MenuItem>
                <MenuItem value={"last_insert_desc"}>
                  Ultimo inserito
                  <IconButton disabled value={"last_insert_desc"}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "block", height: "70%", pb: 5, mx: 4 }}>
          <Grid container spacing={3} justifyContent="flex-start">
            {data.data.map((res, index) => {
              return (
                <Grid key={index} item xs={12} md={2}>
                  <Item name={res.name} lastname={res.surname} id={res.id} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </LayoutPage>
      <PaginationButton
        page={page}
        setPage={setPage}
        lastPage={data.last_page}
      />
    </>
  );
};

const mapState = (state) => ({
  count: state.count,
});

export default connect(mapState)(ClientiArchiviati);

const useStyles = makeStyles({
  button: {
    backgroundColor: "#fff",
    color: "#262626",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#262626",
    },
  },
});
