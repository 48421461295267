import { Vector2d } from "konva/lib/types";
import { Customer } from "../models/customer";
import { User } from "../models/user";
import { DRAW_FACTOR } from "./common_constants";

export function toDrawUnit(value: number): number {
    return value * DRAW_FACTOR;
}

export function toMillimeters(value: number): number {
    return value / DRAW_FACTOR;
}

export function getCurrentUser(storeBase: any): User {
    return storeBase.user as User;
}

export function getCurrentCustomer(storeBase: any): Customer | null {
    return storeBase.customer ? storeBase.customer as Customer : null;
}

export function getAxiosConfig(currentUser: User) {
    return {
        headers: {
            Authorization: "Bearer " + currentUser.token,
        },
    };
}

export function Vector2dToPointsArray(points: Vector2d[]): number[] {
    let res: number[] = [];
    points.forEach(p => {
        res.push(p.x);
        res.push(p.y);
    });
    return res;
}

export function distance(x1: number, y1: number, x2: number, y2: number) {
    let x = x1 - x2;
    let y = y1 - y2;

    return Math.sqrt(x*x + y*y);
}

export function midpoint(x1: number, y1: number, x2: number, y2: number): {x: number, y: number} {
    return {x: (x1 + x2) / 2, y: (y1 + y2) / 2};
}

export function numberCompare(a: number, b: number): number {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
}

export function error(error: any) {
    if (error.response.data && !error.response.data.success && error.response.data.message) {
        throw error.response.data.message;
    } else {
        throw error.response.statusText;
    }
}