import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreDispatch, useStoreSelector } from "../../../../../hooks/StoreHooks";
import { useEffect } from "react";
import { getCurrentUser } from "../../../../core/common_functions";
import { useConfigurationsService } from "../../../../services/configurations_service";
import { useDoorConfigurationBuilderActions } from "../../../../../store/reducers/door_configuration_builder";
import { CategoryCode } from "../../../../models/category";
import Loading from "../../../../../components/Loading/loading";
import { useOptionsService } from "../../../../services/options_service";
import { useColorsService } from "../../../../services/colors_service";

export const DoorConfigurationLoadPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatcher = useStoreDispatch();
    const { base } = useStoreSelector(store => store);
    const currentUser = getCurrentUser(base);
    const configurationService = useConfigurationsService();
    const optionsService = useOptionsService();
    const colorsService = useColorsService();
    const { setConfiguration } = useDoorConfigurationBuilderActions();

    const loadConfiguration = async (configurationId: number) => {
        try {
            let configuration = await configurationService.loadDoorConfiguration(currentUser, configurationId);
            let options = await optionsService.findByCategory(currentUser, configuration.categoryId);
            let colors = await colorsService.findByCategory(currentUser, configuration.categoryId);

            dispatcher(setConfiguration({configuration, options, colors}));

            if (configuration.isFinito) {
                navigate(`/door-configurations/${configuration.configurationId}/preventivo`);
            } else {
                if (configuration.categoryCode == CategoryCode.PortaSezionale) {
                    navigate(`/door-configurations/${configuration.configurationId}/dimensioni`);
                }
            }
        } catch (e) {
            console.log(e);
            // setLoading({isActive: false, error: e.message});
        }
    }

    useEffect(() => {
        if (!id || isNaN(Number(id))) {
            navigate("/configurations");
        } else {
            loadConfiguration(Number(id));
        }
    }, []);

    return (
        <Loading />
    );
}